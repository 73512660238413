.question{
    height: 60px;
    /* margin: 26.5px 138px 8px 139px; */
    padding: 11px 10px;
    border-radius: 15px;
    border: solid 1px var(--slash-accent-color);
    background-color: rgba(224, 209, 255, 0.7);
    flex-direction: row;
    display: flex;
    margin-bottom: 10px;
  }
  .ansdiv{
    flex-grow: 0;
    border-radius: 15px;
    box-shadow: 2px 2px 15px 0 rgba(166, 171, 189, 0.5), -1px -1px 13px 0 #fafbff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
  
  }
  .arrow{
    flex-direction: row;
    display: flex;

  }
  .questionText{
    /* fontFamily: "Mulish", fontSize: 15, fontWeight: "600", fontStyle: "normal", letterSpacing: 0, color: "#484148" */
   font-family: Inter;
   font-size: 15px;
   font-weight: 600;
   font-style: normal;
   letter-spacing: 0px;
   color: #484148;
  }
  .showMore{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #461c99;
    flex-direction: row;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
.subNavStyle1{
    font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  color: #461c99;

}
.faqScrool{
    /* width: '140%', overflow: 'scroll', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'  */
    overflow-x: scroll;
    width: 100%;
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    align-items: center;

}
.faqScrool::-webkit-scrollbar {
    display: none;
  }
.mybooking_main_div_style1{
    margin-top: 100px;
}

.mybooking_navbar_style1{
    border-bottom:1px solid #b8b8b8;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
   /* overflow: auto; */
    
}


.mybooking_navbarItem_style1{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mybooking_navbarItem_style_mobile_consumer{
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mybooking_navbarItem_active_style_mobile_consumer{
    width:180px;
    display: flex;
    margin-right:20;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #8c52ff;
    border-bottom:2px solid #8c52ff;
}
.mybooking_navbarItem_active_style1{
    width: 220px;
    display: flex;
    margin-right:20;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #8c52ff;
    border-bottom:2px solid #8c52ff;
}

ul.nav a:hover { color: #8c52ff !important; }

.mybooking_header_text_style1{
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1b1f;
}

.mybooking_navItem_active_style1{
    /* margin: 0px 0px 11px 0px; */
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    padding: 11px;
    /* border-bottom: 2px solid #8c52ff; */
}

.mybooking_navItem_inactive_style1{
    /* margin: 0px 0px 11px 0px; */
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
    padding: 11px;
}
.proDIV::-webkit-scrollbar {
    display: none;
  }


@media screen and (max-width: 1440px){
    .mybooking_navbar_style1{
        border-bottom:1px solid #b8b8b8;
        margin: 15px 0;
       overflow: auto;
        
    }
    .mybooking_navbarItem_style1{
        width: 220px;
        
    }
    
    .mybooking_navbarItem_active_style1{
        width: 220px;
        margin-right:20;
       
    }
  

}
@media screen and (max-width: 1240px){
    .mybooking_navbar_style1{
        border-bottom:1px solid #b8b8b8;
        margin: 15px 0;
       overflow: auto;
        
    }
    .mybooking_navbarItem_style1{
        width: 190px;
        
    }
    
    .mybooking_navbarItem_active_style1{
        width: 180px;
        margin-right:20;
       
    }
    .mybooking_navItem_inactive_style1{
        font-size: 14px;
    }
    .mybooking_navItem_active_style1{
        font-size: 14px;
    }

  

}
@media screen and (max-width: 1024px){
    .mybooking_navbar_style1{
        border-bottom:1px solid #b8b8b8;
        margin: 10px 0;
       overflow: auto;
        
    }
    .mybooking_navbarItem_style1{
        width: 150px;
        padding: 0px;
    }
    
    .mybooking_navbarItem_active_style1{
        width: 150px;
       padding: 0px;
       margin-right:20;
    }
    .mybooking_navItem_inactive_style1{
        font-size: 12px;
        padding: 0px;
    }
    .mybooking_navItem_active_style1{
        font-size: 12px;
    }

}


@media screen and (max-width: 768px){

    .mybooking_navbarItem_style1{
        width: 130px;
    }
    
    .mybooking_navbarItem_active_style1{
        width: 130px;
        margin-right:20;
    }
    .mybooking_navItem_inactive_style1{
        font-size: 10px;
    }
    .mybooking_navItem_active_style1{
        font-size: 10px;
    }
    .questionText{
       font-size: 15px;
       font-weight: 600;
      }
}

@media screen and (max-width: 425px){

    .mybooking_main_div_style1{
        margin-top: 0px;
    }
    .questionText{
        font-size: 12px;
        font-weight: 600;
       }
    .mybooking_navItem_active_style1{
        margin: 0px 0px 11px 0px;
        font-size: 16px;
        color: #8c52ff;
    }

    .mybooking_navItem_inactive_style1{
        margin: 0px 0px 11px 0px;
        font-size: 16px;
        color: #747474;
    }

    .mybooking_header_text_style1{
        font-size: 24px;
        color: #1c1b1f;
    }

    .mybooking_navbarItem_style1{
        width: 180px;
    }
    
    .mybooking_navbarItem_active_style1{
        width: 180px;
        margin-right:20;
    }
    .questionText{
        font-size: 12px;
        font-weight: 600;
       }
       
}

@media screen and (max-width: 375px){

    .mybooking_navbarItem_style1{
        width: 180px;
    }
    
    .mybooking_navbarItem_active_style1{
        width: 180px;
        margin-right:20;
    }
}

@media screen and (max-width: 320px){

    .mybooking_navItem_active_style1{
        margin: 0px 25px 11px 0px;
        font-size: 14px;
        color: #8c52ff;
    }

    .mybooking_navItem_inactive_style1{
        margin: 0px 25px 11px 0px;
        font-size: 14px;
        color: #747474;
    }
}