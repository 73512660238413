.whySlashNew_Div{
  padding-top:30px;
  height: 239px;
  background-color: rgba(224, 209, 255, 0.7);
  display:flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.whySlashNew_CardSv2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 191px;
  border-radius: 15px;
  border: solid 2px #fff;
  background-color: #d4bfff;
  /* background-color:rgba(216,197,252,0.6); */
}
.whySlashCard_RowSv2{
  margin-top: -130px;
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-items: center; */
}
.whySlashCard_ColSv2{
  margin-left:auto;
  margin-right: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whySlashCardText_sv2{
  margin-top: 10px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.whySlashNew_SubtextSv2{
  opacity: 0.6;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1f;
}
.WhySlashTextMain{
  opacity: 0.6;
  font-family: 'Inter';
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1f;
}
.WhySlashSubText_bm{
  margin-top: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9f9f9f;
}

@media screen and (max-width:768px){
  .whySlashNew_CardSv2{
    width: 200px;
    height: 170px;
  }
  .whySlashCard_RowSv2{
    margin-top: -105px;
  }
  .whySlashCardText_sv2{
    font-size: 14px;
  }
  .whySlashNew_SubtextSv2{
    font-size: 12px;
  }
}
/* Mobile View CSS Class Names */


.WhySlash_Div_MobileView {
  padding: 16px 15px 16px 15px;
  height: auto;
  background-color: rgba(224, 209, 255, 0.7);
}
.whySlashTextMain_Mobile{
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #505050;
}
.whySlashSub_Text_Mobile{
  margin-top:8px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9f9f9f;
}
.whySlashCard_Mobile{
  width: 85px;
  height: 74px;
  /* margin: 16px 8px 16px 24px; */
  border-radius: 10px;
  border: solid 2px #fff;
  background-color: #d4bfff;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Low-demand_Mobile {
  /* width: 209px;
  height: 15px;
  margin: 33px 53px 0 8px; */
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.book_service{
  margin-top:5px;
  opacity: 0.6;
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}

