.findoutWhatAreYou_Home{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border-radius: 15px; */
    /* border: solid 1px #8c52ff; */
    /* height:196px; */
    /* padding:20px; */
    /* background-color: rgba(224, 209, 255, 0.7); */
}
.findoutWhatAreYou_mainText{
    font-family: 'Inter';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.findoutWhatAreYou_subText {
    flex-grow: 0;
    margin-top: 8px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.inputTextStyle_Email_findOut{
    font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9f9f9f;
 }
 .goEmail_findOut{
    width: 88px;
    height: 36px;
    flex-grow: 0;
    position: absolute;
    display:flex;
    justify-content: center;
    align-items: center;
    top:4px;
    bottom: 0px;
    right: 5px;
    /* z-index: 100; */
    cursor: pointer;
    border-radius: 10px;
    background-color: #8c52ff;
 }
 .goText_findOut{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
 }
 @media screen and (max-width:768px){
    .findoutWhatAreYou_mainText {
        font-size: 16px;
    }
    .findoutWhatAreYou_subText {
        font-size: 12px;
    }
    /* .findoutWhatAreYou_Home{
        height:150px;
    } */
}
@media screen and (min-width:0px) and (max-width:760px){
    .findoutWhatAreYou_Home{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* height:150px; */
        /* padding:10px; */
    }
    .findoutWhatAreYou_mainText{
        font-size:16px ;
        text-align: center;
    }
    .findoutWhatAreYou_subText {
        font-size: 12px;
        text-align: center;
    }
}