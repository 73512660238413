.buttonStyle{
    /* border-radius: 10; */
    padding:0px;
    display: flex;
    justify-content:center;
    align-items:center;
    text-align: center;
    width:138;
    height:48px;
    margin-top:28px;
    cursor: pointer;
}
.textStyle{
    font-family: Inter;
    /* font-size: 16px; */
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

}