.edit_profile_web{
    /* width: 753px; */
    /* height: 348px; */
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: center; */
    /* align-items: center; */
    /* align-items: stretch; */
    /* gap: 10px; */
    /* margin: 43.5px 138px 14px@ 28px; */
    /* padding-left: 20px; */
    padding-top:20px;
    padding-right:20px;
    /* padding-bottom: 10px; */
}
.country_code_web{
    flex:0.5
}
.mobile_num_web{
    flex:2.5;
    position: relative;
    margin-left: 5px;
}
.content_profile_web{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    /* margin-bottom: 40px; */
}
.profile_image_style{
    width:150px;
    height:150px;
}
.inputName{
    /* margin-left:30px; */
    margin-top:20px;
    width:100%;
}
.mobile_input_width{
    width: 70%;
}
.mobile_code_input_width{
    width: 30%;
}
.input_name_web{
    width:100%;
}
.update_button_name{
    width:100%;
    background-color: #8c52ff !important;
    border-radius: 5.2px;
    font-family: Mulish;
    font-weight: 600;
    font-size: 20px;
}
.cross_update_mobile{
    width: 30px;
    height: 30px;
    flex-grow: 0;
    border-radius: 15px;
    /* margin: 0 0 28px 329px; */
    padding: 6px 5.3px 6px 6px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.update_mobile_text{
    font-family: 'Inter';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.timerText{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
#nameInputID::placeholder {
    color: #8c52ff;
    opacity: 0.4;
}
.modal_main_web{
    height:500px;
    /* width:400px; */
    padding-bottom:20px;
}

/* .modal-content{
    width:400px;
} */

.update_mobile_desc{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #747474;
    margin-top:8px;
    margin-left:40px;
    margin-right:40px;
    margin-bottom: 30px;
    }
.sendVerification_web{
    background-color: #8c52ff !important;
    margin-bottom: 22px;
    font-size: 16px;
    width:100%;
    height:48px;
}
.resend_style_web{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.contdown-text-style{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
@media screen and (max-width: 1240px){

    .profile_image_style{
        width:130px;
        height:130px;
    }
    .inputName{
        /* margin-left:20px; */
        margin-top:20px;
        width:100%;
    }
    .content_profile_web{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        /* margin-bottom: 40px; */
    }
    .country_code_web{
        flex:0.8
    }
    .mobile_num_web{
        flex:2.2;
        position: relative;
        margin-left: 5px;
    }
    
}
@media screen and (max-width: 1024px){

    .profile_image_style{
        width:120px;
        height:120px;
    }
    .inputName{
        /* margin-left:20px; */
        margin-top:20px;
        width:100%;
    }
    .content_profile_web{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        /* margin-bottom: 40px; */
    }
    .country_code_web{
        flex:0.5
    }
    .mobile_num_web{
        flex:2.5;
        position: relative;
        margin-left: 5px;
    }
    
}
@media screen and (max-width: 768px){

    .profile_image_style{
        width:120px;
        height:120px;
    }
    .inputName{
        /* margin-left:20px; */
        margin-top:20px;
        width:100%;
    }
    .content_profile_web{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        /* margin-bottom: 40px; */
    }
    .country_code_web{
        flex:0.9
    }
    .mobile_num_web{
        flex:2.1;
        position: relative;
        margin-left: 5px;
    }
    
}


