.serviceDetails_View{
    flex-grow: 0;
  padding: 28px 15px 15px;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #eee;
  background-color: #fff;

}
.wishlistBg_ServiceDetails{
    width: 36px;
    height: 36px;
    flex-grow: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 29px 21px; */
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
}
.serviceName_ServiceDet{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.distanceText_serviceDet {
    flex-grow: 0;
    margin: 0 0 0 8px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.servicePrice_serviceDet{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5ba85a;
}
.addToCart_Btn_Service{
    width: 200px;
    height: 50px;
    flex-grow: 0;
    border-radius: 10px;
    background-color: #8c52ff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addToCart_Btn_Service_Text{
    flex-grow: 0;
  font-family:'Inter';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.descText_ServiceDet{
    flex-grow: 0;
  margin: 9px 0 0;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.serviceDetails_PriceView{
    margin-top: 20px;
    border-radius: 8px;
    border: solid 1px rgba(140, 82, 255, 0.4);
    background-color: #fff;
}
.linearGradient_ServiceId{
    height: 48px;
  flex-grow: 0;
  padding: 15px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-image: linear-gradient(86deg, #8c52ff 3%, #f7f2ff 98%)
}
.serviceId_Text{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.serviceId_ValueText{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}
.priceText_ServiceDet{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.totalPrice_ServiceDet{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.totalPriceValueText_ServiceDet{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #5ba85a;
}
.cancellationPolicy_View{
    border-radius: 8px;
    border: solid 1px #ff5d5d;
    background-color: #fff;
    padding:15px;  
    margin-top: 20px;
}
.cancellationDisclaimer_Text{
    font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.ratingAndReview_Box{
    flex-grow: 0;
    padding:15px;
    margin: 20px 0 20px 1px;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    border: solid 1px #eee;
    background-color: #fff;
}
.carousel_IndicatorStyle{
    position:absolute;
    width:20px;
    height:20px;
    bottom:0px;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
}
.carousel_IndicatorStyle .active{
    background-color: #fff;
}
.carousel_IndicatorStyle li{
    background-color:#a5d028;
}