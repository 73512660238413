.rating_main_div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    /* padding: 100px 88px; */
}

.rating_inside_div1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.rating_inside_smiley_div2{
    /* width: 100%; */
    width: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 28px 20px 20px;

}

.gradient {
    width: 220px;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;

}

.addphoto{
    /* width: 65
    height: 15, */
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #8c52ff;
    padding-left: 10px;
}

.textArea{
    width: 100%;
    height: 100px;
    border-radius: 8px;
    background-color: rgba(191, 160, 255, 0.2);
    border-style: solid;
    border-width: 1px;
    border-color: #a679ff;
    padding-left: 16px;
}

.rating_writeUs_input_field_style{
    display: flex;
    flex-direction: row;
    width: 450px;
    height: auto;
    padding: 20px;
}


.rating_feedback_list_div_style{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0;
    padding: 0 0;
    width: 450px;
    flex-wrap: wrap;
    
}

.rating_feedback_list_buttonStyle_style{
    padding:10px 25px;
    margin: 10px 16px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:35px;
    border-width: 1px;
    border-style: solid;
    
}

.rating_writeUs_button_field_style{
    display: flex;
    flex-direction: row;
    width: 450px;
    height: auto;
    padding: 20px;
}

.rating_submit_button_style{
    padding:0px;
    display: flex;
    justify-content:center;
    align-items:center;
    text-align: center;
    width:100%;
    height:45px;
    border-radius: 10px;
    cursor: pointer;
}

.rating_line_separator{
    width: 100%;
    height: 1px;
    /* transform: "rotate(-270deg)", */
    background-color: #b8b8b8;
    margin: 0 10px;
}

.arrowColor{
    padding-top:20px;
}

.title{
    /* width: 35,
    height: 35, */
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: 20;
    /* letterSpacing: -0.3, */
    text-align: center;
    color: #8c52ff;
}

.ratingSubText{
    /* width: 321,
    height: 20, */
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.3;
    text-align: center;
    color: #8c52ff;
    margin: 12px 0 0;
}

.rating{
    /* width: 287,
    height: 24, */
    font-family: Inter;
    font-size: 20;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #1c1b1f
}

.rating_smiley_image_div{
    width: 58px;
    height: 58px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    border-radius: 50px;
    padding: 0 8px;
    margin: 12px 0;
}

.rating_image_star_block_style{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 12px;
}

.imgText{
    /* width: 321,
    height: 20, */
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -0.3;
    text-align: center;
    color: rgb(247,191,244);
    margin: 10px 0
}

.content{
    /* width: 301,
    height: 19, */
    margin: 28px 0 20px;
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #1c1b1f;
}

@media screen and (max-width: 425px){

    .rating_inside_smiley_div2{
        /* width: 100%; */
        width: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 28px 20px 20px;
    
    }

    .rating_writeUs_input_field_style{
        display: flex;
        flex-direction: row;
        width: 350px;
        height: auto;
        padding: 20px;
    }
    
    
    .rating_feedback_list_div_style{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 16px 0;
        padding: 0 0;
        width: 350px;
        flex-wrap: wrap;
        
    }
    
    .rating_writeUs_button_field_style{
        display: flex;
        flex-direction: row;
        width: 350px;
        height: auto;
        padding: 20px;
    }

    .rating_smiley_image_div{
        width: 38px;
        height: 38px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: lightgray;
        border-radius: 50px;
        padding: 0 8px;
        margin: 12px 0;
    }

}

@media screen and (max-width: 375px){

    .rating_inside_smiley_div2{
        /* width: 100%; */
        width: 330px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 28px 0px 20px;
    
    }

    .rating_writeUs_input_field_style{
        display: flex;
        flex-direction: row;
        width: 330px;
        height: auto;
        padding: 20px;
    }
    
    
    .rating_feedback_list_div_style{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 16px 0;
        padding: 0 0;
        width: 330px;
        flex-wrap: wrap;
        
    }
    
    .rating_writeUs_button_field_style{
        display: flex;
        flex-direction: row;
        width: 330px;
        height: auto;
        padding: 20px;
    }

    .rating_smiley_image_div{
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: lightgray;
        border-radius: 50px;
        padding: 0 8px;
        margin: 12px 0;
    }

    .rating_image_star_block_style{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 6px;
        width: 100%;
    }

}

@media screen and (max-width: 320px){

    .rating_inside_smiley_div2{
        /* width: 100%; */
        width: 280px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 28px 0px 20px;
    
    }

    .rating_writeUs_input_field_style{
        display: flex;
        flex-direction: row;
        width: 280px;
        height: auto;
        padding: 20px;
    }
    
    
    .rating_feedback_list_div_style{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 16px 0;
        padding: 0 0;
        width: 280px;
        flex-wrap: wrap;
        
    }
    
    .rating_writeUs_button_field_style{
        display: flex;
        flex-direction: row;
        width: 280px;
        height: auto;
        padding: 20px;
    }

    .rating_smiley_image_div{
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: lightgray;
        border-radius: 50px;
        padding: 0 8px;
        margin: 12px 0;
    }

    .rating_image_star_block_style{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 6px;
        width: 100%;
    }

    .rating_feedback_list_buttonStyle_style{
        padding:10px 25px;
        margin: 10px 10px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        height:35px;
        border-width: 1px;
        border-style: solid;
        
    }

}