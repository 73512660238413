.booking_details_main_div{
    padding: 28px;
    /* width: 706px; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}



.booking_image_div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.booking_image_style{
    width:100%;
    border-radius: 5px;
}

.booking_serviceName_text_style{
    flex-grow: 0;
    margin: 0 0px 0 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #000;

}

.booking_proName_text_style{
    flex-grow: 0;
    margin: 1px 0px 0 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.booking_help_text_style{
    cursor: pointer;
    flex-grow: 0;
    margin: 2px 0 3px 8px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8c52ff;

}

.booking_header_text_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0;
}

.booking_pin_dateTime_text_div{
    width: 605px;
    height: 115px;
    flex-grow: 0;
    /* margin: 20px 44px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
    position: relative;
    top: -40px;
    left: 50px;
    padding: 20px 16px;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: #fff;
}

.booking_id_pin_head_text{
    flex-grow: 0;
    margin: 0 8px 0 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.booking_id_pin_desc_text{
    flex-grow: 0;
    margin: 0 0 0 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8c52ff;
}

.booking_date_time_desc_text{
    flex-grow: 0;
    margin: 1px 0 0 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.booking_qrcode-div{
    --b: 1px;   /* thickness of the border */
    --c: #747474;   /* color of the border */
    --w: 80px;  /* width of border */
    --r: 20px; /* radius */

    border: var(--b) solid #0000; /* space for the border */
    --_g: #0000 90deg,var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background:
        conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
        conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g)) 100% 0    / var(--_p),
        conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--_g)) 0    100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);

    width: 286px;
    height: 286px;
    display: flex;
    justify-content:center;
    align-items:center;
    padding: 30px;
    margin: 20px;
    /* padding: var(--b);     */
}

.booking_details_prices_div{
    width: 100%;
    height: 48px;
    flex-grow: 0;
    margin: 0 0 16px;
    padding: 15px 20px 15px 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-image: linear-gradient(86deg,#8c52ff 3%, #f7f2ff 98%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.booking_details_prices_each_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 20px;
}

.booking_details_prices_main_div{
    flex-grow: 0;
    margin: 28px 0 20px;
    padding: 1px 1px 20px;
    border-radius: 8px;
    border: solid 1px rgba(140, 82, 255, 0.4);
    background-color: #fff;
}

.booking_details_cancellation_div{
    width: 100%;
    flex-grow: 0;
    margin: 20px 0;
    padding: 16px 0;
    border-radius: 8px;
    border: solid 1px #ff5d5d;
    background-color: #fff;
}

.booking_details_address_div{
    flex-grow: 0;
    margin: 0 0;
    padding: 16px 20px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eee;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.booking_details_serviceid_text{
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.boking_details_each_name_text{
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.booking_details_your_price_text{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.booking_details_each_prices_text{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}

.booking_details_your_Price_details{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #5ba85a;
}

.booking_details_cancellation_Price_details{
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ff5d5d;
}

.refundInfoContainer{
    display: flex;
    flex-direction:row;
    align-items:flex-start;
    margin:0 20px; 
    padding:5px 0;
    width:'85%';
}

.buyStyle{
    font-family: Inter;
    font-size: 16px;
    /* fontWeight: "bold", */
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #553580;
}

.insidecontainer{
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    padding:2px 0;
}

.priceTextStyle{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: right;
    color: #000000
  }

.smallTextStyle{
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #747474;
    margin: 0 20px;
}

.Rating_modalbody_div_style{
    padding: 25px;
}


@media screen and (min-width: 760px){

    .booking_pin_dateTime_text_div{
        width: 92%;
        height: 115px;
        top: -40px;
        left: 20px;
        padding: 20px 16px;
    }

}

@media screen and (max-width: 760px){

    .booking_details_main_div{
        padding: 0px;
        /* width: 706px; */
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
    }

    .booking_pin_dateTime_text_div{
        width: 90%;
        height: 115px;
        top: -40px;
        left: 20px;
        padding: 20px 16px;
    }

}

@media screen and (max-width: 425px){

    .booking_id_pin_head_text{
        margin: 0 4px 0 0;
        font-size: 12px;
    }

    .booking_id_pin_desc_text{
        margin: 0 0 0 0px;
        font-size: 12px;
    }

    .booking_date_time_desc_text{
        margin: 1px 0 0 4px;
        font-size: 12px;
    }

    .booking_details_serviceid_text{
        font-size: 12px;
    }

    .booking_details_each_prices_text{
        font-size: 12px;
    }

    .boking_details_each_name_text{
        font-size: 12px;
    }

    .booking_details_your_Price_details{
        font-size: 14px;
    }

    .booking_details_your_price_text{
        font-size: 14px;
    }

    .booking_details_cancellation_Price_details{
        font-size: 12px;
    }

    .Rating_modalbody_div_style{
        padding: 20px;
    }

}

@media screen and (max-width: 375px){

    .booking_id_pin_head_text{
        margin: 0 4px 0 0;
        font-size: 10px;
    }

    .booking_id_pin_desc_text{
        margin: 0 0 0 0px;
        font-size: 10px;
    }

    .booking_date_time_desc_text{
        margin: 1px 0 0 4px;
        font-size: 10px;
    }

    .booking_details_serviceid_text{
        font-size: 10px;
    }

    .booking_details_each_prices_text{
        font-size: 10px;
    }

    .boking_details_each_name_text{
        font-size: 10px;
    }

    .booking_details_your_Price_details{
        font-size: 12px;
    }

    .booking_details_your_price_text{
        font-size: 10px;
    }

    .booking_details_cancellation_Price_details{
        font-size: 10px;
    }

    .Rating_modalbody_div_style{
        padding: 16px;
    }

}

@media screen and (max-width: 320px){

    .booking_id_pin_head_text{
        margin: 0 4px 0 0;
        font-size: 8px;
    }

    .booking_id_pin_desc_text{
        margin: 0 0 0 0px;
        font-size: 8px;
    }

    .booking_date_time_desc_text{
        margin: 1px 0 0 4px;
        font-size: 8px;
    }

    .booking_details_serviceid_text{
        font-size: 8px;
    }

    .booking_details_each_prices_text{
        font-size: 8px;
    }

    .boking_details_each_name_text{
        font-size: 8px;
    }

    .booking_details_your_Price_details{
        font-size: 10px;
    }

    .booking_details_your_price_text{
        font-size: 10px;
    }

    .booking_details_cancellation_Price_details{
        font-size: 8px;
    }

    .Rating_modalbody_div_style{
        padding: 12px;
    }

}
