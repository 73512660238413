.MainReusable_Container_Home{
    padding: 30px 129px 25px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.horizontal_LineSv2{
    width: 35%;
    height: 1px;
    flex-grow: 0;
    opacity: 0.6;
    background-color: #b8b8b8;
}
.categoryCardWidth{
    display:inline-block;
    margin:15px 20px 15px 20px;
    justify-content: center;
}
.Whats-on-your-mind {
    width: 30%;
    opacity: 0.6;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1b1f;
    margin-top: -15px;
}
.Professionals-nearby {
 
    opacity: 0.6;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c1b1f;
}
.NearByPro_BSubtext {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
  }
  .See-by-map {
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8c52ff;
    cursor: pointer;
}
.bigHorZLine{
    width: '100%';
    height: 1px;
    margin-top: 12px;
    opacity: 0.6;
    background-color: #b8b8b8;
}
  .locationIconStyle_landing{
    width:20px;
    height:20px;
}
.carousel_container_nearbypros{
    height: auto;
    width: 100%;
    padding-top: 50px;
}
.arrowLandSv2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0px;
}
.carousel_arrowLandingSv2{
    height: 20px;
    width: 20px;
}
.arrowLandRightSv2{
    /* padding: 10px; */
    cursor:pointer;
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position:absolute;
    right:-15px;
    bottom:130px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  .disable_arrowLandRightSv2{
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position:absolute;
    right:-15px;
    bottom:130px;
    justify-content: center;
    align-items: center;
    background-color: #fff;    
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}
  .arrowLandLeftSv2{
    cursor: pointer;
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position:absolute;
    left:-15px;
    bottom:130px;
    justify-content: center;
    align-items: center;
    background-color: #fff;    
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}
.disable_arrowLandLeftSv2{
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position:absolute;
    left:-15px;
    bottom:130px;
    justify-content: center;
    align-items: center;
    background-color: #fff;    
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}
.copyRight_Text{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.searchAndText_Banner_Sv2{
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    bottom:30%;
    left:10%;
    right:10%;
    z-index: 10;
}
.Carousel_Text_Banner_Sv2{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 0.3px;
    text-align: center;
    color: #fff;
}
.subText_Carousel_Sv2{
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.08px;
    text-align: center;
    color: #fff;
}
.location-search-input-sv2{
    width:280px;
    height:48px;
    border-radius: 10px;
    background-color: #fff;
    padding: 0 30px 0 30px;
    font-family: 'Inter';
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
    border-right-width: 0;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
  /* margin-right:10px; */
}
.inputSearchCatSerPro_Style{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left-width: 0;
    width:360px;
    height: 48px;
    flex-grow: 0;
    padding: 0 50px 0 50px;
    /* margin-left:10px; */
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
    /* border-radius: 10px; */
}
.verticalline_sv2{
    width: 1px;
    height: 30px;
    border-left: 1px solid rgba(116,116,116,0.4);
    /* margin: 0 11.5px 0 15.5px; */
    transform: rotate(-360deg);
    position: absolute;
    left: 5px;
    top:9px;
    bottom: 9px;
}
.dropDownCardWidth_sv2{
    width:300px;
}
/* Search box related classes */
.searchDropDownOuterBox{
    /* position:absolute; */
    border-radius: 3px;
    background-color: #ffffff;
    border: 0.3px  solid "#eee9e9";
}
.imageCatStyle{
    width: 46px;
    height: 46px;
    flex-grow: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 16px 0 0; */
    /* padding: 10px 13.6px 11px 13px; */
    border-radius: 10px;
    border: 1px solid #fff;
    /* background-color: #ffb884; */
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}
.imageProStyle{
    width: 46px;
    height: 46px;
    /* margin: 9px 16px 19px 20px; */
    border-radius: 7px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    border: 1px solid #fff;
}
.searchDropDowntextStyle{
    flex-grow: 0;
  margin: 0 0 2px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.searchDropDownSubtextStyle{
    flex-grow: 0;
  /* margin: 2px 50px 0 0; */
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.CurrentLocation_Modal_Style{
    position:absolute;
    /* height:200px; */
    width:300px;
    z-index:2000;
    border-radius:10px;
    padding:10px 10px 20px 10px;
    background-color: '#fff';
    top:55px;
    overflow: hidden;
}
.currentLoc_MainText{
    flex-grow: 0;
    /* margin: 0 98px 8px 16px; */
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ea4335;  
}
.currentLoc_subText{
    flex-grow: 0;
  /* margin: 5px 54px 15.8px 16px; */
  font-family: 'Inter';
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9f9f9f;
}
.markerTextStyle{
    font-family: "Inter";
    font-size: 8px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #747474;
}
.selectLocation_Text{
    font-family: 'Inter';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.crossIcon_ViewPro_Home{
    border-radius: 50px;
    width: 26px;
    height: 26px;
    flex-grow: 0;
    /* padding:4px; */
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.enterText_Address{
    font-family: 'Inter';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: left;
  color: #000;
}
.saveAdreesAs_Text{
    font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.saveAdd_Btn_Home{
    border-radius: 10px;
    background-color: #8c52ff !important;
    height: 48px;
    width:100%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.saveAdd_Confm_Text{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
.nearByProMapText{
    font-family: 'Inter';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.homePage_Banner_Img{
    opacity: 0.5;
    transition: opacity 500ms 0ms ease-in-out;
    width:100%;
    height:600px;
}
.offerBanner_Home{
    position:absolute;
    z-index:100px;
    top:-89px;
    left:10%;
    right:10%;
}
.offer_Bottom_Home{
    padding-top: 100px;
}
/* --------- */
/* Mobile related classes */
.addressLine1_TextHome{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.currentLocationIcon_View{
    position:absolute;
    bottom: 170px;
    right: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50px;;
    /* margin: 217px 0 0 119px; */
    /* padding: 9px; */
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
}
.my-inputPlaceHolder::placeholder {
    color: #8c52ff;
    /* opacity: 0.4; */
}
.subText_Blw_AddressLine1{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.location-search-input-sv2-mobile{
    height: 48px;
    /* margin: 20px 0; */
    padding: 9px 12px 9px 35px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 0.5px rgba(140, 82, 255, 0.6);
    background-color: #fff;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
}
.inputSearchCatSerPro_Style_Mobile{
    width:370px;
    height: 48px;
    flex-grow: 0;
    padding: 0 30px 0 30px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
    border-radius: 10px;
}
.suggestion_Text{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.recentLocation_Text{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c1b1f;
}
.offerBanner_Home_Mob{
    position:absolute;
    z-index:100px;
    /* top:-32px; */
    top:-30px;
    left:5%;
    right:5%;
}
/* ---- */

@media screen and (max-width:1600px){
    .MainReusable_Container_Home{
        padding: 30px 129px 50px 129px;
    }
    .offer_Bottom_Home{
        padding-top: 100px;
    }
    .offerBanner_Home{
        top:-97px;
    }
}

@media screen and (max-width:1440px){
    .MainReusable_Container_Home{
        padding: 30px 129px 25px 129px;
    } 
    .offer_Bottom_Home{
        padding-top: 100px;
    }
    .offerBanner_Home{
        top:-89px;
    }
}
@media screen and (max-width:1240px){
    .MainReusable_Container_Home{
        padding: 30px 110px 25px 110px;
    }
    .Carousel_Text_Banner_Sv2{
        font-size: 50px;
    }
    .subText_Carousel_Sv2{
        font-size: 16px;
    }
    .categoryCardWidth{
        margin:10px;
    }
    .offerBanner_Home{
        position:absolute;
        z-index:100px;
        top:-77px;
        left:10%;
        right:10%;
    }
    .offer_Bottom_Home{
        padding-top: 100px;
    }
}
@media screen and (max-width:1024px){
    .MainReusable_Container_Home{
        padding: 30px 90px 25px 90px;
    }
    .Carousel_Text_Banner_Sv2{
        font-size: 50px;
    }
    .subText_Carousel_Sv2{
        font-size: 16px;
    }
    .categoryCardWidth{
        margin:10px;
    }
    .offerBanner_Home{
        top:-63px;
    }
    .offer_Bottom_Home{
        padding-top: 100px;
    }
}
@media screen and (max-width:768px){
    .MainReusable_Container_Home{
        padding: 16px 50px 16px 50px;
    }
    .location-search-input-sv2{
        width:250px;
        font-size: 10px;
    }
    .inputSearchCatSerPro_Style{
        width:330px;
        font-size: 10px;
    }
    .Carousel_Text_Banner_Sv2{
        font-size: 40px;
    }
    .subText_Carousel_Sv2{
        font-size: 16px;
    }
    .categoryCardWidth{
        margin:15px 22px 15px 22px;
    }
    .homePage_Banner_Img{
        width:100%;
        height:500px;
    }
    .searchAndText_Banner_Sv2{
        position: absolute;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
        bottom:15%;
        left:10%;
        right:10%;
    }
    .horizontal_LineSv2{
        width:40%;
    }
    .Whats-on-your-mind{
        width:30%;
        font-size: 16px;
        font-weight: 600;
        margin-top: -10px;
    }
    .offerBanner_Home{
        top:-47px;
    }
    .offer_Bottom_Home{
        padding-top: 60px;
    }
}
@media screen and (min-width:0px) and (max-width:760px){
    .arrowLandSv2{
        display: none;
    }
    .MainReusable_Container_Home{
        padding: 16px 15px 16px 15px;
    }
    .horizontal_LineSv2{
        width:25%;
    }
    .Whats-on-your-mind{
        width:50%;
        font-size: 16px;
        font-weight: 600;
        margin-top: -10px;
    }
    .categoryCardWidth{
        display:inline-block;
        margin:1px;
        justify-content: center;
    }
    .See-by-map {
        font-size: 10px;
    }
    .locationIconStyle_landing{
        width:16px;
        height:16px;
    }
    .carousel_container_nearbypros{
        height: auto;
        width: 100%;
        padding-top: 30px 0px 0px 0px;
    }
    .Professionals-nearby {
        font-size: 16px;
    }
    .NearByPro_BSubtext {
        font-size: 12px;
    }
    .copyRight_Text{
      font-size: 12px;
    }
    .Carousel_Text_Banner_Sv2{
        font-size: 25px;
    }
    .subText_Carousel_Sv2{
        font-size: 10px;
    }
    .currentLoc_MainText{
        font-size: 16px;  
    }
    .homePage_Banner_Img{
        width:100%;
        height:490px;
    }
}
@media screen and (max-width:375px){
    .categoryCardWidth{
        display:inline-block;
        margin:1px;
        justify-content: center;
    }
    .location-search-input-sv2-mobile{
        width:340px;
        font-size: 10px;
    }
    .inputSearchCatSerPro_Style_Mobile{
        width:340px;
        font-size: 10px;
    }
    .offerBanner_Home_Mob{
        position:absolute;
        z-index:100px;
        top:-27px;
        left:5%;
        right:5%;
    }
}
@media screen and (max-width:360px){
    .categoryCardWidth{
        display:inline-block;
        margin:1px;
        justify-content: center;
    }
    .location-search-input-sv2-mobile{
        width:300px;
        font-size: 10px;
    }
    .inputSearchCatSerPro_Style_Mobile{
        width:330px;
        font-size: 10px;
    }
    .horizontal_LineSv2{
        width:20%;
    }
    .Whats-on-your-mind{
        width:60%;
        font-size: 16px;
        font-weight: 600;
        margin-top: -10px;
    }
    .offerBanner_Home_Mob{
        top:-26px;
    }
}
@media screen and (max-width:320px){
    .location-search-input-sv2-mobile{
        width:300px;
        font-size: 10px;
    }
    .inputSearchCatSerPro_Style_Mobile{
        width:300px;
        font-size: 10px;
    }
    .offerBanner_Home_Mob{
        top:-23px;
    }
}

