.Refer_main_div_style{
    /* width: 753px; */
    width: 100%;
    /* height: 727px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    /* gap: 10px; */
    /* margin: 43.5px 138px 0 28px; */
    margin: 0 0 0 0;
    padding:36px 36px;
    border-radius: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.Refer_inner_div_style{
    /* width: 681px; */
    width: 100%;
    height: 187px;
    flex-grow: 0;
    margin: 0 0 0px;
    padding: 0 98px 0 24px;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-image: linear-gradient(85deg, #461c99 -4%, #8c52ff 40%, #ff5d5d 137%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-end;

}

.Refer_inner_image_div1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 13px 0 13px;
    height: 100%;
}

.Refer_inner_image_div2{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.Refer_reedem_div_style{
    width: 100%;
    height: 51px;
    margin: 28px 0 0;
    padding: 13px 16px;
    border-radius: 10px;
    background-image: linear-gradient(89deg, #461c99 -5%, #8c52ff 39%, #ff5d5d 138%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Refer_redeem_text_style{
    flex-grow: 0;
    margin: 3px 0 2px 10px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.Refer_header_text_style{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;

}

.Refer_other_text_style{
    margin: 8px 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.Your-referral-code {
    /* margin: 28px 0 16px 0; */
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #461c99;
  }

  .Refer_input_code_style {
    min-width: 356px;
    height: 48px;
    flex-grow: 0;
    margin: 16px 0;
    padding: 14.1px 20px 13.7px;
    border-radius: 10px;
    border: dashed 1px #8c52ff;
    background-color: rgba(224, 209, 255, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Refer_image_1{
    width: 90%;
  }

  .Refer_image_2{
    width: 100%;
  }

  .Refer_image_3{
    width: 120%;
  }

  .Refer_ellipse_style {
    width: 36px;
    height: 36px;
    flex-grow: 0;
    /* margin: 16px 12px 14px 0; */
    margin: 0 12px 0 0;
    /* padding: 9.6px 14.4px 8.4px; */
    border: solid 1px #eee;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }

  .Refer_veritical_line_style{
    width: 1px;
    height: 22px;
    flex-grow: 0;
    margin: 0 29.5px 0 17.5px;
    /* transform: rotate(-180deg); */
    background-color: #e8e8e8;

  }

  .Refer_share_button_style {
    width: 122px;
    height: 48px;
    flex-grow: 0;
    margin: 0 16px 0 0;
    /* padding: 8px 31px; */
    border-radius: 10px;
    background-color: #8c52ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }

  .Refer_input_button_style{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Refer_button_text_style{
    margin: 6px 0 0;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .Refer_referal_code_text_div{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .Refer_invite_friend_text_div{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top:25px;
  }

  .Refer_referal_code{
    font-family: Inter;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #461c99;
  }

  @media screen and (max-width:768px) {

    .Refer_input_button_style{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

  }

  @media screen and (max-width:425px) {

    .Refer_main_div_style{
        padding: 100px 20px 36px;
    }
    
    .Refer_inner_div_style{
        /* width: 681px; */
        width: 100%;
        height: 150px;
        margin: 0px;
        padding: 0 8px;
    }

    .Refer_inner_image_div1{
        width: 170px;
    }

    .Refer_image_1{
        width: 90%;
      }
    
      .Refer_image_2{
        width: 100%;
      }
    
      .Refer_image_3{
        width: 100%;
      }

      .Refer_input_code_style {
        min-width: 380px;
      }

      .Refer_input_button_style{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      .Refer_referal_code_text_div{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .Refer_invite_friend_text_div{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:25px;
      }

  }

  @media screen and (max-width:375px) {
    
    .Refer_inner_div_style{
        /* width: 681px; */
        width: 100%;
        height: 130px;
        margin: 0px;
        padding: 0 8px;
    }

    .Refer_input_code_style {
        min-width: 335px;
      }

  }

  @media screen and (max-width:320px) {
    
    .Refer_inner_div_style{
        /* width: 681px; */
        width: 100%;
        height: 110px;
        margin: 0px;
        padding: 0 8px;
    }

    .Refer_input_code_style {
        min-width: 280px;
      }

  }