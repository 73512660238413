.modalStyle {
    /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
    position: absolute;
    right: 0px;
    top: -32px;
    border-radius: 0px;
    width: 34%;
    height: 100%;


}
.modalDiv{
    height:800px
}
.errorBox{
    border-radius: 4.2px;
    background-color: #ffe4c5;
    margin-top: 10px;
    padding:10px;
    height: 50px;
   
}
.resendButton{
    /* justifyContent: 'center',
     alignItems: 'center', display: 'flex',
     flexDirection: 'row',
      position: 'absolute', bottom: 200,
       left: '20%',
        right: '20%'  */
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 20%;
        right: 20%;
        bottom: 200px;
}

.loginText{
    text-decoration: underline;
    color: #8c52ff;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   
}
.messageText{
 justify-content: center;
 flex-direction: row;
 display: flex;
}
.logintextDiv{
    flex-direction: row;
    justify-content: center;
    display: flex;
    cursor: pointer;
    padding-bottom: 50px;
}
.code4{
    height: 40px;
    width: 80px;
    margin-right: 5px;
}
.hello {
    /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
    padding-top: 90px;
    text-align: center;
    font-size: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 15px;

}
.hello1 {
    /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
    /* padding-top: 90px; */
    text-align: center;
    font-size: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 15px;

}
.subtext{
    /* textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' */
    text-align: center;
    color: #747474;
    font-size: 14px;
    font-family: Inter;
    padding-left: 75px;
    padding-right: 75px;
}
.subtextloginUS{
    /* textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' */
    text-align: center;
    color: #747474;
    font-size: 14px;
    font-family: Inter;
    /* padding-left: 75px; */
    /* padding-right: 75px; */
}
.inputdiv{
    /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22 */
    margin-left:75px;
    margin-right: 75px;
    margin-bottom: 22px;
    margin-top: 44px;
    flex-direction: row;
    display: flex;
}
.inputdivUS{
    /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22 */
    margin-left:75px;
    margin-right: 75px;
    margin-bottom: 22px;
    margin-top: 44px;
    
}
.referText{
    /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
    text-align: right;
    color: #747474;
    font-size: 16px;
    font-family: Inter;
    /* margin-right: 75px; */
    margin-left: 245px;
    margin-bottom: 22px;
    color: #8c52ff;
    font-weight: 600;
}
.passwordText{
    /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
    font-size: 16px;
    font-family: Inter;
    /* margin-right: 75px; */
    margin-left: 185px;
    padding-top: 10px;
    /* margin-bottom:12px; */
    color: #8c52ff;
    font-weight: 600;
    margin-top: 20px;
}

.sendButton{
    /* marginLeft: 75, marginRight: 75, backgroundColor: "#8c52ff", marginBottom: 22, fontSize: 16  */
    margin-left: 75px;
    padding-left: 80px;
    padding-right: 90px;
    background-color: #8c52ff !important;
    margin-bottom: 22px;
    font-size: 16px;
    margin-top: 10px;
    flex-direction: row;
    display: flex;

    

}
.sendButtonUs{
    background-color: #8c52ff;
    margin-top: 22px;
    font-size: 16px;
    padding-left: 145px;
    padding-right: 150px;
    margin-bottom: 22px;

}
.updatePassword{
    background-color: #8c52ff;
    margin-top: 22px;
    font-size: 16px;
    padding-left: 142px;
    padding-right: 142px;
    margin-bottom: 22px;

}
.sendmailOTP{
    background-color: #8c52ff;
    margin-top: 22px;
    font-size: 16px;
    padding-left: 129px;
    padding-right: 129px;
    margin-bottom: 22px;

}
.sendButtonUsMobile{
    background-color: #8c52ff;
    margin-top: 22px;
    font-size: 16px;
    padding-left: 85px;
    padding-right: 85px;
    margin-bottom: 22px;

}
.acctext{
    padding-top:90px ;
    text-align: center;
    font-size: 14px;
    font-family: Inter;
    margin-bottom:60px ;
    /* paddingTop: 90, textAlign: 'center', fontSize: 14, fontFamily: 'Inter', marginBottom: 46 */
}
.check1{
    margin-left: 90px;
    margin-bottom: 100px;
}
.check2{
    margin-left: 90px;
    margin-bottom: 10px;

    
}
.checkWidth2{
width: 290;
}
.checkWidth1{
    width: 280;
}
/* otpmaodel */

.otpModla{
    /* position: "absolute", right: 70, top: 32, borderRadius: 0, width: '24%' */
    position: absolute;
    right: 70px;
    top: 32px;
    border-radius: 0px;
    width: 24%;
}
.refediv{
    /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22; */
    margin-left:65px;
    margin-right: 65px;
    margin-top: 20px;
    margin-bottom: 22px;
}
.referimg{
    width:80px;height:80px;margin-left:50px
}
.referlabel{
    /* marginLeft:30,marginTop:10 */
    margin-left: 40px;
    margin-top: 10px;

}
.timeText{
    /* textAlign: 'center', color: '#000', fontSize: 16, fontWeight: '500', fontFamily: 'Inter', marginTop: 44 */
    text-align: center;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-family: Inter;
    margin-top: 40px;
}
.otpdiv{
    /* marginLeft: 'auto', marginRight: 'auto', marginTop: 35, marginBottom: 44, textAlign: 'center' */
    margin-left: auto;
    margin-right: auto;
    margin-top: 95px;
    margin-bottom: 44px;
    text-align: center;
}
.otpinput{
    width:150%
}
.verifybtn{
    /* marginLeft: 75, marginRight: 75, backgroundColor: "#8c52ff", marginBottom: 22, fontSize: 16  */
    margin-left: 75px;
    margin-right: 75px;
    margin-bottom: 22px;
    font-size: 16px;
}
.bottemText{
    /* paddingTop: 233, textAlign: 'center', fontSize: 14, fontFamily: 'Inter', marginBottom: 46 */
    padding-top: 180px;
    text-align: center;
    font-size: 14px;
    font-family: Inter;
    margin-bottom: 140px;
}
.crossdiv{
    /* flexDirection:'row',justifyContent:'start',marginLeft:20,ma */
    flex-direction: row;
    justify-content: start;
    margin-left: 30px;
    margin-top: 30px;

}
@media screen and (max-width:1400px) {
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 142px;
        padding-right: 142px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 130px;
        padding-right: 131px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 185px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 85px;
        padding-right: 85px;
        margin-bottom: 22px;
    
    }

    .subtext{
        /* textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' */
        text-align: center;
        color: #747474;
        font-size: 14px;
        font-family: Inter;
        padding-left: 75px;
        padding-right: 75px;
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 130px;
        padding-right: 134px;
        margin-bottom: 22px;
    
    }
    .modalDiv{
        height:780px
    }
    .check1{
        margin-left: 105px;
        margin-bottom: 100px;
    }
    .check2{
        margin-left: 105px;
        margin-bottom: 10px;
    
        
    }
}
@media screen and (max-width:1240px) {
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 108px;
        padding-right: 108px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 97px;
        padding-right: 97px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 125px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 52px;
        padding-right: 52px;
        margin-bottom: 22px;
    
    }
    .otpModla{
        /* position: "absolute", right: 70, top: 32, borderRadius: 0, width: '24%' */
        position: absolute;
        right: 35px;
        top: 32px;
        border-radius: 0px;
        width: 28%;
    }
    
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 34%;
    
    
    }
    
    .hello {
        /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
        padding-top: 90px;
        text-align: center;
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 15px;
    
    }
    .subtext{
        text-align: center;
        color: #747474;
        font-size: 14px;
       
    }
    .inputdiv{
        margin-left:55px;
        margin-right: 55px;
        margin-bottom: 22px;
        margin-top: 44px;
    }
    .referText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        text-align: right;
        color: #747474;
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 205px;
        margin-bottom: 22px;
        color: #8c52ff;
        font-weight: 600;
    }
    .sendButton{
        margin-left: 55px;
        padding-left: 75px;
        padding-right: 75px;
        background-color: #8c52ff;
        margin-bottom: 22px;
        font-size: 16px;
        margin-top: 10px;
        flex-direction: row;
        display: flex;
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 115px;
        padding-right: 115px;
        margin-bottom: 22px;
    
    }
    .acctext{
        padding-top:90px ;
        text-align: center;
        font-size: 14px;
 
        margin-bottom:46px ;
    }
    .check1{
        margin-left: 55px;
    }
    .check2{
        margin-left: 55px;
        margin-bottom: 10px;
   
    
}
.modalDiv{
    height:750px
}
}
@media screen and (max-width:1024px) {
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 123px;
        padding-right: 123px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 112px;
        padding-right: 111px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 155px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 67px;
        padding-right: 67px;
        margin-bottom: 22px;
    
    }
    .otpModla{
        /* position: "absolute", right: 70, top: 32, borderRadius: 0, width: '24%' */
        position: absolute;
        right: 45px;
        top: 32px;
        border-radius: 0px;
        width: 35%;
    }
    /* .modalDiv{
        height:7px
    } */
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 44%;
    
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 128px;
        padding-right: 128px;
        margin-bottom: 22px;
    
    }
    
    .hello {
        /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
        padding-top: 90px;
        text-align: center;
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 15px;
    
    }
    .subtext{
        text-align: center;
        color: #747474;
        font-size: 14px;
       
    }
    .inputdiv{
        margin-left:55px;
        margin-right: 55px;
        margin-bottom: 22px;
        margin-top: 44px;
    }
    .referText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        text-align: right;
        color: #747474;
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 235px;
        margin-bottom: 22px;
        color: #8c52ff;
        font-weight: 600;
    }
    .sendButton{
        margin-left: 55px;
        padding-left: 90px;
        padding-right: 90px;
        background-color: #8c52ff;
        margin-bottom: 22px;
        font-size: 16px;
        margin-top: 10px;
        flex-direction: row;
        display: flex;
    
    }
    .acctext{
        padding-top:90px ;
        text-align: center;
        font-size: 14px;
 
        margin-bottom:46px ;
    }
    .check1{
        margin-left: 55px;
    }
    .check2{
        margin-left: 55px;
        margin-bottom: 10px;
    
        
    
}
}

@media screen and (max-width:768px) {
    .resendButton{
        /* justifyContent: 'center',
         alignItems: 'center', display: 'flex',
         flexDirection: 'row',
          position: 'absolute', bottom: 200,
           left: '20%',
            right: '20%'  */
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            position: absolute;
            left: 10%;
            right: 10%;
            bottom: 100px;
    }
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 129px;
        padding-right: 129px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 118px;
        padding-right: 119px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 165px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 73px;
        padding-right: 73px;
        margin-bottom: 22px;
    
    }
    .otpModla{
        /* position: "absolute", right: 70, top: 32, borderRadius: 0, width: '24%' */
        position: absolute;
        right: 20px;
        top: 32px;
        border-radius: 0px;
        width: 45%;
    }
    /* .modalDiv{
        height:700px
    } */
    .inputdivUS{
        /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22 */
        margin-left:35px;
        margin-right: 35px;
        margin-bottom: 22px;
        margin-top: 44px;
        
    }
    .bottemText{
        /* paddingTop: 233, textAlign: 'center', fontSize: 14, fontFamily: 'Inter', marginBottom: 46 */
        padding-top: 180px;
        text-align: center;
        font-size: 14px;
        font-family: Inter;
        margin-bottom: 0px;
    }
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 50%;
    
    
    }
    .subtextloginUS{
        /* textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' */
        /* text-align: center; */
        color: #747474;
        font-size: 14px;
        font-family: Inter;
        
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 136px;
        padding-right: 136px;
        margin-bottom: 22px;
    
    }
    .refediv{
        /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22; */
        margin-left:85px;
        margin-right: 65px;
        margin-top: 20px;
        margin-bottom: 22px;
    }
    .referlabel{
        /* marginLeft:30,marginTop:10 */
        margin-left: 30px;
        margin-top: 10px;
    
    }
    
    .hello {
        /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
        padding-top: 50px;
        text-align: center;
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 15px;
    
    }
    .subtext{
        text-align: center;
        color: #747474;
        font-size: 14px;
       
    }
    .inputdiv{
        margin-left:45px;
        margin-right: 45px;
        margin-bottom: 22px;
        margin-top: 44px;
    }
    .referText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        text-align: right;
        color: #747474;
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 175px;
        margin-bottom: 22px;
        color: #8c52ff;
        font-weight: 600;
    }
    .sendButton{
        margin-left: 45px;
        padding-left: 65px;
        padding-right: 65px;
        background-color: #8c52ff;
        margin-bottom: 22px;
        font-size: 16px;
        margin-top: 10px;
        flex-direction: row;
        display: flex;
    
    }
    .acctext{
        padding-top:50px ;
        text-align: center;
        font-size: 14px;
 
        margin-bottom:46px ;
    }
    .check1{
        margin-left: 45px;
    }
    .check2{
        margin-left: 45px;
        margin-bottom: 10px;
    
        
    
}
}
@media screen and (max-width:425px) {
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 140px;
        padding-right: 140px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 130px;
        padding-right: 130px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 190px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 86px;
        padding-right: 86px;
        margin-bottom: 22px;
    
    }
    .refediv{
        /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22; */
        margin-left:65px;
        margin-right: 65px;
        margin-top: 14px;
        margin-bottom: 22px;
    }
    .modalDiv{
        height:600px
    }
    .bottemText{
        /* paddingTop: 233, textAlign: 'center', fontSize: 14, fontFamily: 'Inter', marginBottom: 46 */
        padding-top: 120px;
        text-align: center;
        font-size: 14px;
        font-family: Inter;
        margin-bottom: 30px;
    }
    .referimg{
        width:70px;height:70px;margin-left:50px
    }
    .referlabel{
        /* marginLeft:30,marginTop:10 */
        margin-left: 20px;
        margin-top: 10px;
    
    }
    .otpModla{
        /* position: "absolute", right: 70, top: 32, borderRadius: 0, width: '24%' */
        position: absolute;
        right: 30px;
        top: 32px;
        border-radius: 0px;
        width: 75%;
    }
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 96%;
    
    
    }
    .otpdiv{
        /* marginLeft: 'auto', marginRight: 'auto', marginTop: 35, marginBottom: 44, textAlign: 'center' */
        margin-left: 76px;
        margin-right: auto;
        margin-top: 95px;
        margin-bottom: 44px;
        text-align: center;
    }
    
    .hello {
        /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
        padding-top: 50px;
        text-align: center;
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 15px;
    
    }
    .subtext{
        text-align: center;
        color: #747474;
        font-size: 14px;
       
    }
    .inputdiv{
        margin-left:45px;
        margin-right: 45px;
        margin-bottom: 22px;
        margin-top: 44px;
    }
    .referText{
        text-align: right;
        font-size: 16px;
        margin-right: 55px;
        margin-bottom: 22px;

    }
    .sendButton{
        margin-left: 45px;
        /* margin-right: 5px; */
        margin-bottom: 22px;
        font-size: 16px;
        padding-left: 75px;
        padding-right: 75px;
    
    }
    .acctext{
        padding-top:50px ;
        text-align: center;
        font-size: 14px;
 
        margin-bottom:46px ;
    }
    .check1{
        margin-left: 45px;
    }
    .check2{
        margin-left: 45px;
        margin-bottom: 10px;
    }
    .referText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        text-align: right;
        color: #747474;
        font-size: 16px;
        font-family: Inter;
        margin-right: 15px;
        margin-left: 195px;
        margin-bottom: 22px;
        color: #8c52ff;
        font-weight: 600;
    }
    .sendButton{
        margin-left: 45px;
        padding-left: 75px;
        padding-right:75px;
        background-color: #8c52ff;
        margin-bottom: 22px;
        font-size: 16px;
        margin-top: 10px;
        flex-direction: row;
        display: flex;
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 145px;
        padding-right: 149px;
        margin-bottom: 22px;
    
    }
}
@media screen and (max-width:375px) {
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 137px;
        padding-right: 137px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 126px;
        padding-right: 126px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 185px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 82px;
        padding-right: 82px;
        margin-bottom: 22px;
    
    }
    .otpdiv{
        /* marginLeft: 'auto', marginRight: 'auto', marginTop: 35, marginBottom: 44, textAlign: 'center' */
        margin-left: 56px;
        margin-right: auto;
        margin-top: 95px;
        margin-bottom: 44px;
        text-align: center;
    }
    .inputdiv{
        /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22 */
        margin-left:25px;
        margin-right: 25px;
        margin-bottom: 22px;
        margin-top: 44px;
        flex-direction: row;
        display: flex;
    }
    .refediv{
        /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22; */
        margin-left:65px;
        margin-right: 65px;
        margin-top: 20px;
        margin-bottom: 22px;
    }
    .inputdivUS{
        /* marginLeft: 75, marginRight: 75, marginTop: 44, marginBottom: 22 */
        margin-left:15px;
        margin-right: 15px;
        margin-bottom: 22px;
        margin-top: 44px;
        
    }
    .referimg{
        width:70px;height:70px;margin-left:50px
    }
    .referlabel{
        /* marginLeft:30,marginTop:10 */
        margin-left: 20px;
        margin-top: 10px;
        font-size: 16px;
    
    }
    .otpModla{
        /* position: "absolute", right: 70, top: 32, borderRadius: 0, width: '24%' */

        right: 30px;
        top: 32px;
        border-radius: 0px;
        width: 80%;
    }
    .referText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        text-align: right;
        color: #747474;
        font-size: 16px;
        font-family: Inter;
        margin-right: 15px;
        margin-left: 165px;
        margin-bottom: 22px;
        color: #8c52ff;
        font-weight: 600;
    }
    .sendButton{
        margin-left: 25px;
        padding-left: 85px;
        padding-right:85px;
        background-color: #8c52ff;
        margin-bottom: 12px;
        font-size: 13px;
        margin-top: 10px;

        margin-right: 0px;
     
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 143px;
        padding-right: 143px;
        margin-bottom: 22px;
    
    }
    .subtext{
        /* textAlign: 'center', color: '#747474', fontSize: 14, fontFamily: 'Inter' */
        text-align: center;
        color: #747474;
        font-size: 14px;
        font-family: Inter;
        padding-left: 5px;
        padding-right: 5px;
    }
    .check1{
        margin-left: 30px;
        margin-bottom: 100px;
    }
    .check2{
        margin-left: 30px;
        margin-bottom: 10px;
    
        
    }
}
@media screen and (max-width:360px) {
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 129px;
        padding-right: 129px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 119px;
        padding-right: 119px;
        margin-bottom: 22px;
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 137px;
        padding-right: 138px;
        margin-bottom: 22px;
    
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 74px;
        padding-right: 74px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 170px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
}
@media screen and (max-width:325px) {
    .check1{
        margin-left: 30px;
        margin-bottom: 70px;
    }
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 112px;
        padding-right: 113px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 102px;
        padding-right: 102px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 135px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 58px;
        padding-right: 58px;
        margin-bottom: 22px;
    
    }
    
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 90%;
    
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 120px;
        padding-right: 120px;
        margin-bottom: 22px;
    
    }
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 96%;
        /* height: 100% */
    
    
    }
    
    .hello {
        /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
        padding-top: 50px;
        text-align: center;
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 15px;
    
    }
    .subtext{
        text-align: center;
        color: #747474;
        font-size: 14px;
       
    }
    .inputdiv{
        margin-left:15px;
        margin-right: 15px;
        margin-bottom: 22px;
        margin-top: 44px;
    }
    .referText{
        text-align: right;
        font-size: 16px;
        margin-right: 5px;
        margin-bottom: 22px;
        margin-left: 125px;

    }
    .sendButton{
        margin-bottom: 22px;
        font-size: 16px;
        padding-left: 60px;
        padding-right: 55px;
        margin-left: 15px;
    
    }
    .acctext{
        padding-top:50px ;
        text-align: center;
        font-size: 14px;
 
        margin-bottom:46px ;
    }
    .check1{
        margin-left: 15px;
    }
    .check2{
        margin-left: 15px;
        margin-bottom: 10px;
    
        
    
}


.otpdiv{
    /* marginLeft: 'auto', marginRight: 'auto', marginTop: 35, marginBottom: 44, textAlign: 'center' */
    margin-left: 30px;
    margin-right: auto;
    margin-top: 95px;
    margin-bottom: 44px;
    text-align: center;
}
.logintextDiv{
    flex-direction: row;
    justify-content: center;
    display: flex;
    cursor: pointer;
    padding-bottom: 0px;
}
}
@media screen and (max-width:320px) {
    .check1{
        margin-left: 30px;
        margin-bottom: 70px;
    }
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 110px;
        padding-right: 110px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 100px;
        padding-right: 100px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 125px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 58px;
        padding-right: 58px;
        margin-bottom: 22px;
    
    }
    
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 90%;
    
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 118px;
        padding-right: 117px;
        margin-bottom: 22px;
    
    }
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 96%;
        /* height: 100% */
    
    
    }
    
    .hello {
        /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
        padding-top: 50px;
        text-align: center;
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 15px;
    
    }
    .subtext{
        text-align: center;
        color: #747474;
        font-size: 14px;
       
    }
    .inputdiv{
        margin-left:15px;
        margin-right: 15px;
        margin-bottom: 22px;
        margin-top: 44px;
    }
    .referText{
        text-align: right;
        font-size: 16px;
        margin-right: 5px;
        margin-bottom: 22px;
        margin-left: 125px;
    }
    .sendButton{
        margin-bottom: 22px;
        font-size: 16px;
        padding-left: 60px;
        padding-right: 55px;
        margin-left: 15px;
    
    }
    .acctext{
        padding-top:50px ;
        text-align: center;
        font-size: 14px;
 
        margin-bottom:46px ;
    }
    .check1{
        margin-left: 15px;
    }
    .check2{
        margin-left: 15px;
        margin-bottom: 10px;
    
        
    
}
.otpdiv{
    /* marginLeft: 'auto', marginRight: 'auto', marginTop: 35, marginBottom: 44, textAlign: 'center' */
    margin-left: 30px;
    margin-right: auto;
    margin-top: 95px;
    margin-bottom: 44px;
    text-align: center;
}
.logintextDiv{
    flex-direction: row;
    justify-content: center;
    display: flex;
    cursor: pointer;
    padding-bottom: 0px;
}
}
@media screen and (max-width:300px) {
    .resendButton{
        /* justifyContent: 'center',
         alignItems: 'center', display: 'flex',
         flexDirection: 'row',
          position: 'absolute', bottom: 200,
           left: '20%',
            right: '20%'  */
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            position: absolute;
            left: 5%;
            right: 5%;
            bottom: 100px;
    }
    .otpdiv{
        /* marginLeft: 'auto', marginRight: 'auto', marginTop: 35, marginBottom: 44, textAlign: 'center' */
        margin-left: 20px;
        margin-right: auto;
        margin-top: 95px;
        margin-bottom: 44px;
        text-align: center;
    }
    .check1{
        margin-left: 30px;
        margin-bottom: 70px;
    }
    .updatePassword{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 110px;
        padding-right: 110px;
        margin-bottom: 22px;
    
    }
    .sendmailOTP{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 90px;
        padding-right: 90px;
        margin-bottom: 22px;
    
    }
    .passwordText{
        /* textAlign: 'right', color: '#747474', fontSize: 16, fontFamily: 'Inter', marginRight: 75, marginBottom: 22, color: "#8c52ff", fontWeight: '600' */
        font-size: 16px;
        font-family: Inter;
        /* margin-right: 75px; */
        margin-left: 110px;
        padding-top: 10px;
        /* margin-bottom:12px; */
        color: #8c52ff;
        font-weight: 600;
        margin-top: 20px;
    }
    .sendButtonUsMobile{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 46px;
        padding-right: 46px;
        margin-bottom: 22px;
    
    }
    
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 90%;
    
    
    }
    .sendButtonUs{
        background-color: #8c52ff;
        margin-top: 22px;
        font-size: 16px;
        padding-left: 108px;
        padding-right: 108px;
        margin-bottom: 22px;
    
    }
    .modalStyle {
        /* position: "absolute", right: 0, top: -32, borderRadius: 0, width: '34%' */
        position: absolute;
        right: 0px;
        top: -32px;
        border-radius: 0px;
        width: 96%;
        /* height: 100% */
    
    
    }
    
    .hello {
        /* paddingTop: 90, textAlign: 'center', fontSize: 20, fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 'bold', marginBottom: 15 */
        padding-top: 50px;
        text-align: center;
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 15px;
    
    }
    .subtext{
        text-align: center;
        color: #747474;
        font-size: 14px;
       
    }
    .inputdiv{
        margin-left:15px;
        margin-right: 15px;
        margin-bottom: 22px;
        margin-top: 44px;
    }
    .referText{
        text-align: right;
        font-size: 16px;
        margin-right: 5px;
        margin-bottom: 22px;
        margin-left: 125px;
    }
    .sendButton{
        margin-bottom: 22px;
        font-size: 16px;
        padding-left: 46px;
        padding-right: 46px;
        margin-left: 15px;
    
    }
    .acctext{
        padding-top:50px ;
        text-align: center;
        font-size: 14px;
 
        margin-bottom:46px ;
    }
    .check1{
        margin-left: 15px;
    }
    .check2{
        margin-left: 15px;
        margin-bottom: 10px;
    
        
    
}
.logintextDiv{
    flex-direction: row;
    justify-content: center;
    display: flex;
    cursor: pointer;
    padding-bottom: 0px;
}
}