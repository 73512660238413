.downloadOurApp_MainTextHome{
    font-family: 'Inter';
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.downloadOurApp_subText{
    margin-top:12px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: initial;
    color: #747474;
}
.downloadOurApp_fromText{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.downloadOurApp_googlePlay{
    width:140px;
    padding-right: 10px;
    /* padding-top:10px; */
}
.downloadOurApp_appStore{
    width:140px;
    padding-left: 10px;
    /* padding-top:5px; */
}
.downloadNow_MobImage{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    /* margin: 120px 26px 120px 72px; */
    /* padding: 10px; */
    object-fit: contain;
    border-radius: 24px;
    background-color: #e8e8e8;
    width:300px;
    height:300px;
}
.imagStyle_downloadNow{
    width:300px;
    height:300px;
}
@media screen and (max-width:768px){
    .downloadOurApp_googlePlay{
        width:120px;
    }
    .downloadOurApp_appStore{
        width:120px;
    }
    .imagStyle_downloadNow{
        width:250px;
        height:250px;
    }
    .downloadNow_MobImage{
        width:250px;
        height:250px;
    }
}
@media screen and (min-width:0px) and (max-width:760px){
    .downloadOurApp_MainTextHome{
        font-size: 24px;
    }
    .downloadOurApp_subText{
        text-align: center;
        font-size: 14px;
    }
    .downloadOurApp_googlePlay{
        width:140px;
        padding-top:5px;
        padding-right: 10px;
    }
    .downloadOurApp_appStore{
        width:140px;
        padding-top:5px;
        padding-left: 10px;
    }
    /* .imagStyle_downloadNow{
        width:280px;
        height:200px;
    } */
    /* .downloadNow_MobImage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        border-radius: 24px;
        background-color: #e8e8e8;
        width:280px;
        height:200px;
    } */
}