.proCard_Sv2{
    width: 350px;
    height: 250px;
    /* display:flex; */
    /* justify-content:center; */
    /* margin: 44px 27px 42px; */
    padding: 10px 10px 8px 10px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    border: solid 1px #eee;
    background-color: #fff;
    cursor:pointer;
}
.cardImg_style_sv2{
    width: auto;
    height: 160px;
    /* margin: 0 0 8px; */
    /* padding: 12px; */
    border-radius: 20px;
}
.addressText_sv2{
    /* width: '70%'; */
    font-family: 'Inter';
    font-size: 12px;
    font-weight: normal;
    /* font-stretch: normal; */
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
    flex-wrap: wrap;

}
.proName_sv2{
    /* margin: 8px 90.4px 4px 0; */
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  
}
.noOfServices_sv2{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #451c95;
}
.distanceText_style{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* text-align: left; */
    color: #747474;
}
.rating_ProCardView {
    width: 62px;
    height: 35px;
    flex-grow: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    /* margin: 11px 14px 0 8px; */
    /* padding: 8px 13px 9px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 1px #ececec;
    background-color: #fff;
  }
  .serviceNameText_InCart{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  }
