.circle {
    width: 47px;
    height: 83px;
    background-color: #fff
}

.line_coupon_card {
  width: 2px;
  height: 198.5px;
  flex-grow: 0;
  margin: 0 12px 0 12px;
  /* transform: rotate(-180deg); */
  /* background-color: #fff; */
  border-width: 0.8;
  border-color: #ffffff;
  border-style: dashed;
}

.imagestyle {
    width: 100%;
    height: 100%;
    /* margin: 30 */
}

.imagestyle_coloured_div {
  width: 100px;
  height: 100px;
  /* margin: 30 */
}

.imageDivStyle{
    width:auto; 
    height:auto; 
    /* margin-left:36px; 
    margin-right:22px;  */
    border-radius:100px;
    /* padding:2px;  */
    background-color:#ffffff
}

.imageDivStyle_coloured{
  width:auto; 
  height:auto; 
  /* margin-left:36px; 
  margin-right:22px;  */
  border-radius:50px;
  /* padding:2px;  */
  background-color:#ffffff
}

.coupon_card_mid_div{
  width: 100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding: 16px;
}

.coupon_card_mid_coloured_div{
  width: 100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding: 27.9px 120px;
}

.off{
  font-family: PlayfairDisplay;
  font-size: 64px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.uptoText{
  font-family: PlayfairDisplay;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.coupom_card_off_details{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.coupom_card_code_div{
  width: 231px;
  height: 46.9px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #fff;
}

.code{
    flex-grow: 0;
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff
  }

.code_coloured{
  font-family: Inter;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fea046;
}

.coloured_validity{
  
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}

.validity_div{

  display: flex;
  flex-direction: row;
  margin: 28px 60px 0 0;
  justify-content: flex-start;
  align-items: center;
}

.date{
    /* height: 20; */
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    color: #ffffff
  }
.divColor {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
  }
.labelStyle1 {
    width: 200px;
    height: 19px;
    /* fontFamily: "Inter"; */
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #fff;
      /* marginVertical:15 */
    }
.c {
    border-radius: 8px;
    background-color: #D8C5FC;
    width: 100%;
    height: 213px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    /* width: 656px; */
    /* height: 253px; */
    flex-grow: 0;
    margin: 0 0 20px;
    cursor: pointer;
    /* padding: 27.9px 170px 26.6px 68px; */
    /* transform: rotate(-180deg); */
    /* border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(112deg, #8c52ff 2%, rgba(140, 82, 255, 0) 102%); */
    /* border-image-slice: 1; */
    background-image: linear-gradient(to right, #8c52ff 0%, #d8c5fc 100%);
    }

.coloured_c {
    border-radius: 8px;
    /* background-color: #D8C5FC; */
    width: 100%;
    height: 253px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    /* width: 656px; */
    /* height: 253px; */
    flex-grow: 0;
    margin: 0 0 20px;
    /* padding: 27.9px 170px 26.6px 68px; */
    /* transform: rotate(-180deg); */
    /* border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(112deg, #8c52ff 2%, rgba(140, 82, 255, 0) 102%); */
    /* border-image-slice: 1; */
    /* background-image: linear-gradient(to right, #8c52ff 0%, #d8c5fc 100%); */
}

.c1 {
    overflow: hidden;
    width: 47px;
    height: 83px;
    position: relative;
    left: 0px;
    /* top: 65; */
    /* bottom: 62; */
    border-top-right-radius: 180px;
    border-bottom-right-radius: 180px;
    background-color: transparent
  
    }
.c2{
    overflow: hidden;
    width: 47px;
    height: 83px;
    position: relative;
    left: 0px;
    /* top: 65; */
    /* bottom: 62; */
    border-top-left-radius: 180px;
    border-bottom-left-radius: 180px;
    background-color: transparent;
  }

  .discount_card_mainViewContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 10px;
    margin-bottom: 20px;

  }

  .discount_card_discount{
    font-family: Inter;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

  }

  .discount_card_tittle{
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

  }


  @media screen and (max-width: 1024px){

    .coupon_card_mid_div{
      padding: 16px;
    }
    
    .coupon_card_mid_coloured_div{
      padding: 27.9px 80px;
    }

  }

  @media screen and (max-width: 768px){

    .coupon_card_mid_div{
      padding: 16px;
    }
    
    .coupon_card_mid_coloured_div{
      padding: 17.9px 50px;
    }

    .line_coupon_card {
      height: 198.5px;
      margin: 0 28px 0 28px;
    }

    .off{
      font-size: 35px;
    }

    .uptoText{
      font-size: 12px;
    }

    .code{
      font-size: 14px;
    }
  
    .code_coloured{
      font-size: 14px;
    }
    
    .coloured_validity{
      font-size: 10px;
    }

    .validity_div{
      margin: 28px 60px 0 0;
    }

    .coupom_card_code_div{
      width: 122px;
      height: 25px;
    }

  }

  @media screen and (max-width: 425px){

    .coupon_card_mid_div{
      padding: 12px;
    }
    
    .coupon_card_mid_coloured_div{
      padding: 10px 35px;
    }

    .c {
      width: 100%;
      height: 150px;
      }
  
    .coloured_c {
        width: 100%;
        height: 150px;
    }

    .circle {
      width: 37px;
      height: 63px;
      background-color: #fff
  }

    .c1 {
      width: 37px;
      height: 63px;
      }

    .c2{
        width: 37px;
        height: 63px;
      }

    .imagestyle_coloured_div {
      width: 80px;
      height: 80px;
      /* margin: 30 */
    }

    .line_coupon_card {
      height: 122px;
      margin: 0 20px 0 20px;
    }

    .off{
      font-size: 35px;
    }

    .uptoText{
      font-size: 12px;
    }

    .code{
      font-size: 14px;
    }
  
    .code_coloured{
      font-size: 14px;
    }
    
    .coloured_validity{
      font-size: 10px;
    }

    .validity_div{
      margin: 18px 20px 0 0;
    }

    .coupom_card_code_div{
      width: 122px;
      height: 25px;
    }

  }

  @media screen and (max-width: 320px){
    .circle {
      width: 27px;
      height: 43px;
      background-color: #fff
  }

  .c {
    width: 100%;
    height: 120px;
    }

    .coloured_c {
      width: 100%;
      height: 120px;
  }

    .c1 {
      width: 27px;
      height: 43px;
      }

    .c2{
        width: 27px;
        height: 43px;
      }

      .line_coupon_card {
        height: 92px;
        margin: 0 20px 0 20px;
      }

      .coupon_card_mid_div{
        padding: 10px 10px;
      }
      
      .coupon_card_mid_coloured_div{
        padding: 10px 25px;
      }

      .imagestyle_coloured_div {
        width: 60px;
        height: 60px;
        /* margin: 30 */
      }

      .off{
        font-size: 25px;
      }
  
      .uptoText{
        font-size: 10px;
      }
  
      .code{
        font-size: 12px;
      }
    
      .code_coloured{
        font-size: 12px;
      }
      
      .coloured_validity{
        font-size: 8px;
      }
  }
  