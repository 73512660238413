.mainContainer{
    background-color:#f1f1f1;
     padding:100px 138px;
     /* margin-top: 100px; */
     /* height: 800px; */
     /* overflow: hidden; */
}
.buttonDivStyle{
    background-color: #ffffff;
    box-shadow: 0px 4px rgba(0, 0, 0, 0.2);
    /* shadow-radius: 50, */
    /* shadowOpacity: 1, */
    border-radius:10px;
    padding:28px;
    width:100%;
    flex:1;
    justify-content:flex-end; 
    position:absolute;
    bottom:0
}

.col_main_div_left{
    display: flex;
}

.sericesDivStyle{
    background-color:#fff;
    /* box-shadow: 0px 2px rgba(103, 52, 204, 0.15); */
    border-radius:10px;
    /* padding:20px; */
    /* margin-left:10px; */
    /* margin-right:10px; */
}

.serviceName1{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #747474;
    padding:0px 0px;
    margin: 22px 0 0;
  }

.cancelDisclaimerDesText{
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #747474;
    /* paddingHorizontal:8 */
  }

.servicePrice{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    /* color: #8c52ff; */
  }

.grand_total_price_style{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #5ba85a;
}

.selectDateTime {
    font-family: Inter;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    /* color: #8c52ff; */
    color: #ffffff;
  }

.professionalName {
    width: 158px;
    height: 19px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #000000
  }
  .textDivStyle{
    padding:16px;
    background-color:#ffffff;
    margin-left:10px; 
    margin-right:10px;
    border-radius:10px;
    flex-direction:row
  }

  .textDivStyle1{
    width: 530px;
    padding:28px;
    margin: 14px 0px;
    background-color:#ffffff;
    /* margin-left:10; 
    margin-right:10; */
    border-radius:10px;
    flex-direction:column;
  }

  .inlineViews{
    width:100%;
    display: flex;
    flex-direction:row;
    align-items:center;
  }

  .inlineViews2{
    width: 100%;
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
  }

  .service_name_price_div{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    margin:0 5px 0;
    padding: 0 0 0 10px;
  }

  .deleteButton{
    border-radius:8px; 
    width:24px;
    height:24px;
    background-color:#ffffff; 
    align-items:center; 
    justify-content:center; 
    /* shadowColor: "rgba(103; 52; 204; 0.15)"; 
    shadowOffset: {
        width: 0;
        height: 1
      }
    shadowRadius: 8;
    shadowOpacity: 1; */
  }

  .addServiceTextStyle{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #8c52ff;
    padding: 0px 8px
  }

  .ApplyCouponTextStyle{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #ffffff;
    padding:0px 8px
  }
  .ApplyCouponDivStyle{
    /* border-radius:10px; */
    padding:16px;
    background: linear-gradient(to right,  #461c99, #8c52ff, #ff5d5d);
    margin:0px 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    cursor: pointer;
  }

  .ApplyButtonDivStyle{
    padding:0px;
    margin: 14px 0px;
    width: 530px;
    background-color:#ffffff;
    /* margin:10px 0px; */
    border-radius:10px;
    display: flex;
    flex-direction:column;
    justify-content:flex-start
  }


  .HeaderText{
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1b1f;
  }

  .HearderSeparator{

    width: 100%;
    height: 1px;
    margin: 0px;
    /* margin: 15.5px 138px 27.5px; */
    transform: rotate(-360deg);
    opacity: 0.6;
    background-color: #b8b8b8;

  }

.HeaderDivStyle{
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    /* background-color: #f1f1f1;  */
    z-index: 10;
    padding-top:20px; 
    padding-bottom:0px;
    border-bottom-color:rgba(116, 116, 116, 0.4);
    border-bottom-width:1px;
    /* margin-top: 100px; */
}

.CheckoutDetailsText{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: rgba(140, 82, 255, 0.85)
}

.CheckoutDetailsHeaderText{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #000000;
}

.CheckoutDetailsPriceText{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: right;
    color: #000000;
}

.modalContainerStyle{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 110px; */
    border-radius: 10px;
    background-color: #ffffff;
    margin:0;
    padding:0px;
}

.CrossContainer_main_div{
    width: 100%;
    height: auto;
    padding: 20px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items:center;
    cursor:pointer;
}

.CrossContainer_coupon_main_div{
    width: 100%;
    height: auto;
    padding: 20px 20px 8px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
}

.RemoveButtonStyle{
    width: 100%;
    height: 35px;
    margin: 0 0 0 10px;
    border-radius: 6px;
    background-color: rgba(229, 228, 228, 0.4);
    border-style: solid;
    border-width: 1;
    border-color: #eeeeee;
    display: flex;
    justify-content:center;
    align-items:center
}
.MoveButtonStyle{
    width: 100%;
    height: 35px;
    margin: 0 10px 0 0;
    border-radius: 6px;
    background-color: #5ba85a;
    display: flex;
    justify-content:center;
    align-items:center
}

.modalHeadingText{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #000000
}

.modal_align_middle{
    border-radius: 20px;
    /* top:30%; */
}

.ButtonMoveText{
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff
}
.ButtonRemoveText{
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #ff5d5d
}

.CancellationHeader{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #9b0909
}
.CancellationChargeText{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #747474
}
.CancellationDisclaimerContainer{
    width: 530px;
    border-radius: 10px;
    padding:28px;
    margin-top: 14px;
    /* margin-bottom: 100px; */
    /* margin-left:10; 
    margin-right:10; */
    background-color: #ffffff;
    border-style: solid;
    border-width: 1;
    border-color: #eeeeee
}
.AddressLineHeader{
    font-family: Inter;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #000000
}
.AddressLineText{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #747474
}
.FlatSavedText{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #5ba85a
}
.ModalContainer{
    flex: 1;
    border-radius: 15px;
    margin-top: (height / 2)-100;
    background-color: rgb(255,255,255);
    /* shadowColor: "rgba(0, 0, 0, 0.2)";
    shadowOffset: {
        width: 0;
        height: 4
    }
    shadowRadius: 50;
    shadowOpacity: 1; */
    height: (height / 2) + 300
}
.cancellationDisclaimerHeader{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #000000
  }
.cancelDisModalCloseIcon{
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    /* shadowColor: "rgba(103, 52, 204, 0.1)";
    shadowOffset: {
        width: 0;
        height: 2
    }
    shadowRadius: 10;
    shadowOpacity: 1; */
    border-radius:10px;
    align-items:center;
    justify-content:center
}

.proceed_to_Pay_amount{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5ba85a;
}

.modalCouponAppliedText{
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #000000
}

.modalCouponSavedText{
    font-family: Inter;
    font-size: 16;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #000000
}
.modalWOText{
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #8c52ff
}

.loginRegCardDiv{
    width: 530px;
    min-height: 213px;
    margin: 44px 0px 16px;
    /* margin: 28px; */
    padding: 28px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: #fff;
}

.loginRegCardDivLeft{
    width: 530px;
    height: 213px;
    flex-grow: 0;
    /* margin: 44px 60px 41px 138px; */
    margin: 44px 0px 16px;
    padding: 44px 65px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: #fff;
}
.loginRegCardDivLeftHome{
    width: 530px;
    height: 280px;
    flex-grow: 0;
    /* margin: 44px 60px 41px 138px; */
    margin: 44px 0px 16px;
    padding: 24px 45px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: #fff;
}

.loginRegCardDivLeft_mobile{

    width: 530px;
    height: 213px;
    flex-grow: 0;
    /* margin: 44px 60px 41px 138px; */
    margin: 44px 0px 16px;
    padding: 44px 65px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: #fff;
    display: none;

}

.loginRegCardDivLeft_mobile_login{

    width: 530px;
    height: 213px;
    flex-grow: 0;
    /* margin: 44px 60px 41px 138px; */
    margin: 44px 0px 16px;
    padding: 44px 65px;
    border-radius: 20px;
    /* box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15); */
    background-color: #fff;
    display: none;

}

.loginRegCardDivLeft_address_update{
    width: 530px;
    height: auto;
    flex-grow: 0;
    /* margin: 44px 60px 41px 138px; */
    margin: 44px 0px 16px;
    padding: 44px 65px;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: #fff;
}

.loginRegCardDivLeft_address_update_mobile{
    width: 100%;
    height: auto;
    flex-grow: 0;
    /* margin: 44px 60px 41px 138px; */
    /* margin: 44px 0px 16px; */
    margin: 0 0;
    /* padding: 44px 65px; */
    padding: 0 0;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: #fff;
}

.cmpltText{
    font-size: 14px;
    font-weight:bold;
    font-style:normal;
    letter-spacing: 0.32;
    text-align:left;
    color:#000000;
    font-family:'Inter';
}
.changeBtn{
    height: 30px;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    border-color: #8c52ff;
    justify-content:center;
    padding:0 6px 0 6px;
    align-items:center;
    cursor:pointer;
}
.changeBtnText{
    font-family: "Inter";
    font-size: 14px;
    font-weight: "600";
    font-style: "normal";
    line-height: 2.0px;
    letter-spacing: -0.3px;
    text-align: left;
    color: "#8c52ff";
}

.horizontalLine{
    width: 100%;
    height: 0.5px;
    border-radius: 5px;
    background-color: rgba(116, 116, 116, 0.4);
    resize: contain;
    align-items:center;
}

.addValueText{
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #747474;
    font-family: Inter;
}

.smallCardStyle{
    width: 66px;
    height: 25px;
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    border-color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content:center;
}

.smallCardSelectedStyle {
    width: 66px;
    height: 25px;
    border-radius: 7px;
    background-color: rgba(216, 197, 252, 0.6);
    border-style: solid;
    border-width: 1px;
    border-color: #8c52ff;
    display: flex;
    align-items: center;
    justify-content:center;
}

.smallCardText {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
}

.inputFieldStyle {
    width: 100%;
    height: 43px;
    padding: 10px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #d9d9d9;
    /* background-color: rgb(255,255,255); */
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #9f9f9f;
}

.errorBox{
    border-radius: 4.2;
    background-color: #ffe4c5;
}

.errorText{
    font-family: Inter;
    font-size: 12.5px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #f3972d;
}

.progressCircle{
    height:27px;
    width:27px; 
    border-radius:15px;
    background-color:#a679ff;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}

.progressCircleSmall{
    height:15px;
    width:15px;
    border-radius:10px;
    background-color:#ffffff;
}

.progressCircleDiv{
    display: flex; 
    flex-direction: row;
    padding: 28px 10px 0px 10px;
    justify-content: flex-start;
    align-items:center;
}

.progressCircleDivText{
    display: flex; 
    flex-direction: row;
    padding: 5px 0px 0px 0px;
    justify-content: flex-start;
    align-items:center;
}

.progressLine{
    width: 40%;
    height:3px;
}

.progressLineText{
    width: 25%;
    height:0px;
}

.slotSelectionButton{
    border-width:1px;
    border-color:#8c52ff;
    background-color: #8c52ff;
    padding: 12px 12px; 
    border-radius:5px;
    margin-right:0;
    min-width:120px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollableColumn{
    height: 100%;
    display:flex; 
    flex-direction:column;
    align-items:flex-end; 
    overflow:auto

}

.loginCardText_style{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #9f9f9f;
}

.ProcessBartextsStyle{
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #461c99;

}

.deleteIcon_style{
    width: auto;
}

.in_cart_service_details{
    width: 100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    margin-bottom: 10px;
}

.cancelDisServiceName{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-style:normal;
    letter-spacing: 0;
    text-align: left;
    color: #505050;
  }


@media screen and (max-width: 1240px){

    .loginRegCardDiv{
        width: 460px;
        min-height: 173px;
        margin: 44px 0px 16px;
        padding: 24px;
    }
    
    .loginRegCardDivLeft{
        width: 460px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 24px;
    }
    .loginRegCardDivLeftHome{
        width: 460px;
        height: 280px;
        margin: 44px 0px 16px;
        padding: 20px;
    }

    .progressLineText{
        width: 20%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 460px;
    }

    .textDivStyle1{
        width: 460px;
        padding:28px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 460px;
        padding:28px;
        margin-top: 14px;
        /* margin-bottom: 100px; */
    }
    
}

@media screen and (max-width: 1024px){

    .mainContainer{
         padding:100px 85px;
    }

    .loginRegCardDiv{
        width: 410px;
        min-height: 173px;
        margin: 44px 0px 16px;
        /* margin: 28px; */
        padding: 16px;
    }
    
    .loginRegCardDivLeft{
        width: 410px;
        height: 173px;
        /* margin: 44px 60px 41px 138px; */
        margin: 44px 0px 16px;
        padding: 16px;
    }
    .loginRegCardDivLeftHome{
        width: 410px;
        height: 260px;
        margin: 44px 0px 16px;
        padding:16px;
    }

    .progressLineText{
        width: 19%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 410px;
    }

    .textDivStyle1{
        width: 410px;
        padding:16px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 410px;
        padding:16px;
        margin-top: 14px;
        /* margin-bottom: 100px; */
    }

    .loginCardText_style{
        font-size: 15px;
    }

    .ProcessBartextsStyle{
        font-size: 14px;
    }
    
}

@media screen and (max-width: 768px){

    .mainContainer{
        padding:100px 44px;
    }

    .loginRegCardDiv{
        width: 330px;
        min-height: 173px;
        margin: 44px 0px 16px;
        /* margin: 28px; */
        padding: 16px;
    }

    .loginRegCardDivLeft_address_update{
        width: 330px;
        height: auto;
        flex-grow: 0;
        /* margin: 44px 60px 41px 138px; */
        margin: 44px 0px 16px;
        padding: 16px;
        border-radius: 20px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        background-color: #fff;
    }
   
    .loginRegCardDivLeft{
        width: 330px;
        height: 173px;
        /* margin: 44px 60px 41px 138px; */
        margin: 44px 0px 16px;
        padding: 16px;
    }
    .loginRegCardDivLeftHome{
        width: 330px;
        height: 250px;
        margin: 44px 0px 16px;
        padding: 16px;
    }
    .progressLineText{
        width: 17%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 330px;
    }

    .textDivStyle1{
        width: 330px;
        padding:16px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 330px;
        padding:16px;
        margin-top: 14px;
        /* margin-bottom: 100px; */
    }

    .loginCardText_style{
        font-size: 12px;
    }

    .ProcessBartextsStyle{
        font-size: 12px;
    }

    .ApplyCouponTextStyle{
        font-size: 14px;
      }
        
}

@media (min-width: 425px) and (max-width: 500px){

    .mainContainer{
        padding:65px 20px;
        /* margin-top: 50px */
    }
    /* .modal_align_middle{
        top:40%;
    } */

    .loginRegCardDiv{
        width: 100%;
        min-height: 173px;
        /* margin: 44px 0px 16px; */
        margin: 24px 0px 16px;
        /* margin: 28px; */
        padding: 16px;
    }
   
    .loginRegCardDivLeft{
        display: none;
    }
    .loginRegCardDivLeftHome{
        display: none;
    }
    .loginRegCardDivLeft_mobile{

        width: 100%;
        height: auto;
        flex-grow: 0;
        /* margin: 44px 60px 41px 138px; */
        /* margin: 44px 0px 16px; */
        margin: 0;
        padding: 20px;
        /* padding: 0 0; */
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        /* position: absolute;
        bottom: 0; */
    
    }

    .loginRegCardDivLeft_mobile_login{

        width: 100%;
        height: auto;
        flex-grow: 0;
        /* margin: 44px 60px 41px 138px; */
        margin: 0;
        padding:20px 20px ;
        /* border-radius: 20px; */
        /* box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15); */
        /* background-color: #fff; */
        display: flex;
        flex-direction: column;
    
    }

    .col_main_div_left{
        display: none;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 100%;
    }

    .textDivStyle1{
        width: 100%;
        padding:16px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 100%;
        padding:16px;
        margin-top: 14px;
        /* margin-bottom: 50px; */
    }

    .slotSelectionButton{
        margin-right:0px;
        min-width:120px;
        text-align: center;
        padding: 12px 8px;
    }

    .HeaderText{
        font-family: Inter;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1c1b1f;
      }

      .HeaderDivStyle{
        display: none;
    }
    
}

@media screen and (max-width: 425px){

    .mainContainer{
        padding:65px 20px;
        /* margin-top: 50px */
    }
    /* .modal_align_middle{
        top:40%;
    } */

    .loginRegCardDiv{
        width: 100%;
        min-height: 173px;
        /* margin: 44px 0px 16px; */
        margin: 24px 0px 16px;
        /* margin: 28px; */
        padding: 16px;
    }
   
    .loginRegCardDivLeft{
        display: none;
    }
    .loginRegCardDivLeftHome{
        display: none;
    }
    .loginRegCardDivLeft_mobile{

        width: 100%;
        height: auto;
        flex-grow: 0;
        /* margin: 44px 60px 41px 138px; */
        /* margin: 44px 0px 16px; */
        margin: 0;
        padding: 20px;
        /* padding: 0 0; */
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        /* position: absolute;
        bottom: 0; */
    
    }

    .loginRegCardDivLeft_mobile_login{

        width: 100%;
        height: auto;
        flex-grow: 0;
        /* margin: 44px 60px 41px 138px; */
        margin: 0;
        padding:20px 20px ;
        /* border-radius: 20px; */
        /* box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15); */
        /* background-color: #fff; */
        display: flex;
        flex-direction: column;
    
    }

    .col_main_div_left{
        display: none;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 100%;
    }

    .textDivStyle1{
        width: 100%;
        padding:16px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 100%;
        padding:16px;
        margin-top: 14px;
        /* margin-bottom: 50px; */
    }

    .slotSelectionButton{
        margin-right:0px;
        min-width:120px;
        text-align: center;
        padding: 12px 8px;
    }

    .HeaderText{
        font-family: Inter;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1c1b1f;
      }

      .HeaderDivStyle{
        display: none;
    }
    
}

@media screen and (max-width: 375px){

    .mainContainer{
        padding:65px 16px;
        /* margin-top: 50px */
    }

    .slotSelectionButton{
        margin-right:0px;
        min-width:120px;
        text-align: center;
    }

    .service_name_price_div{
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        margin-left:5px;
        padding: 0 0 0 5px;
      }

      .serviceName1{
        font-size: 10px;
     }

    .selectDateTime {
        font-size: 8px;
    }
    
}

@media screen and (max-width: 320px){

    .mainContainer{
        padding:65px 16px;
    }

    .loginRegCardDiv{
        width: 100%;
        min-height: 173px;
        margin: 44px 0px 16px;
        /* margin: 28px; */
        padding: 12px;
    }
   
    .loginRegCardDivLeft{
        width: 100%;
        height: 173px;
        /* margin: 44px 60px 41px 138px; */
        margin: 44px 0px 16px;
        padding: 12px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 100%;
    }

    .textDivStyle1{
        width: 100%;
        padding:12px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 100%;
        padding:12px;
        margin-top: 14px;
        /* margin-bottom: 100px; */
    }

    .inlineViews{
        width:100px;
      }

    .serviceName1{
       font-size: 10px;
    }

    .slotSelectionButton{
        padding:3px; 
        border-radius:5px;
        margin: 0px;
        /* margin-right:2px; */
        min-width:80px;
    }

    .deleteIcon_style{
        width: 25px;
    }

    .selectDateTime {
        font-size: 8px;
      }
    
}



/* @media screen and (max-width: 1360px){

    .loginRegCardDiv{
        width: 480px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 24px;
    }
    
    .loginRegCardDivLeft{
        width: 480px;
        height: 173px;
        flex-grow: 0;
        margin: 44px 0px 16px;
        padding: 24px 47px;
    }

    .progressLineText{
        width: 21%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        padding:0px;
        margin: 14px 0px;
        width: 480px;
    }

    .textDivStyle1{
        width: 480px;
        padding:28px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 480px;
        padding:28px;
        margin-top: 14px;
        margin-bottom: 100px;
    }
    
} */



/* @media screen and (max-width: 1195px){

    .mainContainer{
        padding:0px 85px;
    }

    .loginRegCardDiv{
        width: 440px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 24px;
    }
    
    .loginRegCardDivLeft{
        width: 440px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 24px;
    }

    .progressLineText{
        width: 20%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 440px;
    }

    .textDivStyle1{
        width: 440px;
        padding:28px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 440px;
        padding:28px;
        margin-top: 14px;
        margin-bottom: 100px;
    }
    
} */

/* @media screen and (max-width: 1160px){

    .loginRegCardDiv{
        width: 410px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 16px;
    }
    
    .loginRegCardDivLeft{
        width: 410px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 16px;
    }

    .progressLineText{
        width: 20%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 410px;
    }

    .textDivStyle1{
        width: 410px;
        padding:16px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 410px;
        padding:16px;
        margin-top: 14px;
        margin-bottom: 100px;
    }
    
} */

/* @media screen and (max-width: 1080px){

    .loginRegCardDiv{
        width: 410px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 16px;
    }
    
    .loginRegCardDivLeft{
        width: 410px;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 16px;
    }

    .progressLineText{
        width: 20%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: 410px;
    }

    .textDivStyle1{
        width: 410px;
        padding:16px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: 410px;
        padding:16px;
        margin-top: 14px;
        margin-bottom: 100px;
    }
    
} */

/* @media screen and (max-width: 1000px){

    .mainContainer{
         padding:0px 85px;
    }

    .loginRegCardDiv{
        width: auto;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 16px;
    }
    
    .loginRegCardDivLeft{
        width: auto;
        height: 173px;
        margin: 44px 0px 16px;
        padding: 16px;
    }

    .progressLineText{
        width: 20%;
        height:0px;
    }

    .ApplyButtonDivStyle{
        margin: 14px 0px;
        width: auto;
    }

    .textDivStyle1{
        width: auto;
        padding:16px;
        margin: 14px 0px;
    }

    .CancellationDisclaimerContainer{
        width: auto;
        padding:16px;
        margin-top: 14px;
        margin-bottom: 100px;
    }
    
} */

