.RefundStatusButton_DivStyle{
    /* border-radius:10px; */
    padding:16px;
    /* background: linear-gradient(to right,  #461c99, #8c52ff, #ff5d5d); */
    /* background-color: #5ba85a; */
    margin:0px 0px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    cursor: pointer;
    border:0px
}