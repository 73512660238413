.main_view_pro_card_mobile{
  width: 100%;
  height: 150px;
  flex-grow: 0;
  /* margin: 0 0 20px; */
  /* padding: 20px 20px 16px 19.9px; */
  border-radius: 10px;
  border: solid 1px #b8b8b8;
  /* background-image: linear-gradient(to right, #451c95 -14%, rgba(148, 101, 240, 0) 73%); */

  }
  .main_view_pro_card_mobile_Img{
    width: 100%;
    height: 150px;
    border-radius: 10px;
  }
  .mer_name_css_mobile{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .address_mobile_style{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .distance_icon_mobile{
    width: 10.5px;
    height: 9.5px;
    /* margin: 0.3px 1.2px 0 2.2px; */
    /* border: solid 0.1px #fff; */
    /* background-color: #fff; */
  }
  .distance_text_mobile{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .noOfService_mobile{
    min-width: 138px;
    /* height: 35px; */
    flex-grow: 0;
    /* padding: 9px 30px 8.5px 29px; */
    opacity: 0.65;
    border-radius: 18px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    padding:8px;
  
  }
  .serviceNameText_ProCard{
    flex-grow: 0;
  opacity: 0.9;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #461c99;

  }