.testimonial_main-div{
    /* padding-top:119px; */
    padding-top:60px;
    padding-bottom: 60px;
    /* padding-bottom: 147px; */
    display:flex;
    flex-direction:column;
    align-items:center;
    background-color:#f5f4ff;
    text-align: center;
    /* border:1px solid black */
}
.testMony_Div{
    padding-top:30px;
    height: 239px;
    background-color: rgba(224, 209, 255, 0.7);
    display:flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
.testimonial_main-div-merchant{
    /* padding-top:119px; */
    padding-top:80px;
    padding-bottom: 80px;
    /* padding-bottom: 147px; */
    display:flex;
    flex-direction:column;
    align-items:center;
    background-color:#fff;
    text-align: center
}
.arrowLand_NewStyle4{
    display: none;
    flex-direction: row;
    justify-content: space-between;
    padding:0px;
  }
  .arrowLand_NewStylePro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0px;
  }
  
  .disable_arrowLeft4{
    margin: 15px;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    position:absolute;
    left:-15px;
    bottom:150px;
    justify-content: center;
    align-items: center;
    background-color: #fff;    
    opacity: 1;
   
  }
  .testmonyMain{
    opacity: 0.6;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    align-items: center;
    color: #1c1b1f;
    margin-bottom: 20px;
   
  }
  .arrow_cardNewLeft4{
    /* padding: 10px; */
    margin: 15px;
    width: 45px;
    height:45px;
    border-radius: 50px;
    position:absolute;
    left:-15px;
    bottom:150px;
    justify-content: center;
    align-items: center;
  }
  .react-carousel_arrowLandPage4{
    /* padding: 2px; */
    height: 20px;
    width: 20px;
  }
  .disable_arrowRight4{
    margin: 15px;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    position:absolute;
    right:-15px;
    bottom:150px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    opacity: 1;
    
  }
  .arrow_cardNewRight4{
    /* padding: 10px; */
    margin: 15px;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    position:absolute;
    right:-15px;
    bottom:150px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
.div_screen_view1-merchant{
    /* margin-top: 40px; */
    margin-bottom: 10px;
    height: 650px;
    background-color:#fff;
    /* display:flex;
    flex-direction:column;
    padding-bottom: 30px; */
}

.div_screen_view1{
    /* margin-top: 40px; */
    margin-bottom: 10px;
    height: 650px;
    background-color:#f5f4ff;
    /* border: 1px solid red; */
    /* display:flex;
    flex-direction:column;
    padding-bottom: 30px; */
}
.row_view-1{
    /* margin-left: 200px;
    margin-right: 200px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: left;
}

.row_view-2{
    /* margin-left: 200px;
    margin-right: 200px; */
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    /* padding-top: 30px;
    padding-bottom:30px; */
    padding-left: 0px;
    padding-right: 0px;
    /* border: 1px solid green; */
    /* text-align: left; */
}

.card_view-testimonials{
    width:280px;
    height:380px;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    /* margin: 30px; */
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    inline-size: auto;
    word-wrap:break-word;
    box-shadow: 8px 10px 15px 3px rgba(25, 7, 52, 0.05);
    border:0px;
    margin-bottom: 20px;
}
.card_view-testimonials_New{
  
    width: 295px;
  /* height: 200px; */
  flex-grow: 0;
  margin: 48px 6px 153px 72px;
  padding: 12px 20px;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  border: solid 1px #3d42ab;
  background-color: #fff;

}

.image_card{
    padding:5px;
    width: 35%;
    height: 25%;
}

.card_img_view1{
    border-radius: 8px;
    width: 110px;
    height: 110px;
}
.testimonial_text-card{
    /* padding-top: 30px;
    width: 100%; */
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content:center;
   
    /* border: 1px solid green; */

    
}
.Test_Div{
    padding-top:30px;
    height: 239px;
    background-color: rgba(224, 209, 255, 0.7);
    display:flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
.head_text1{
    text-align: initial;
    font-family: Mulish;
    font-weight: bold;
    font-size: 32px;
    color:rgb(38,22,64);
    padding-left: 5px;
}
.info_text1{
    text-align: initial;
    font-family: Mulish;
    /* font-weight: bold; */
    font-size: 16px;
    color:rgb(38,22,64);
    padding-left: 2px;
    padding-top: 5px;
}

.card_title1 {
    font-family: Mulish;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a0c2e;
  }

.card_text-testimonials{
    font-family: Mulish;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a0c2e;
  }

.Dual-Coat_consumer{
    position: absolute;
    left: -100px;
    bottom: 400px;
}

.Dual-Coat_consumer-rev{
    position: absolute;
    transform: rotateY(180deg);
    right: -60px;
    bottom: -20px;
}

.Dual-Coat_merchant{
    position: absolute;
    left: -35px;
    bottom: 390px;
}

.Dual-Coat_merchant-rev{
    position: absolute;
    transform: rotateY(180deg);
    right: -160px;
    bottom: 60px;
}

.carousel_container-testimonials{
    /* padding-top: 100px; */
    padding:108px 30px 10px 30px;
    height: auto;
    width: 100%;
    /* border: 1px solid rgb(105, 102, 102); */
}

.carousel-button-group-testimonials{
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    bottom: 500px;
    /* border: 1px solid black; */
}
/* Mobile */
.testimonial_main-div-merchant_MRmobile{
    padding-top:60px;
    padding-bottom: 0px;
    display:flex;
    flex-direction:column;
    align-items:center;
    background-color:#fff;
    text-align: center
}
.testimonial_text-card_MRmobile{
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    position: absolute;
    left: 10px;
    top: 0;
    padding-left: 7px;
}
.info_text1_MRmobile{
    text-align: initial;
    font-family: Mulish;
    /* font-weight: bold; */
    font-size: 18px;
    color:rgb(38,22,64);
    padding-left: 2px;
    padding-top: 16px;
}
.row_view-2_MRmobile{
    padding-left: 0px;
    padding-right: 0px;
}

.head_text1_MRmobile{
    font-size: 26px;
    color:rgb(38,22,64);
    padding-left: 0px;
}
.info_text1_MRmobile{
    font-size: 13px;
}

.testimonial_text-card_MRmobile{
    left: 1px;
    top: 0;
    padding-left: 7px;
    
}

.carousel-button-group-testimonials_MRmobile{
    right: 0;
    bottom: 435px;
    display: flex;
    flex-direction: row;
    position: absolute;
}

.card_img_view1_MRmobile{
    border-radius: 8px;
    width: 110px;
    height: 110px;
    margin-bottom: 20px;
}

.card_view-testimonials_MRmobile{
    border-radius: 8px;
    text-align: center;
    align-items: center;
    width:300px;
    height:380px;
    padding-top: 40px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
    box-shadow: 8px 10px 15px 3px rgba(25, 7, 52, 0.05);
    border:0px;
    inline-size: auto;
    word-wrap:break-word;
}
.carousel_container-testimonials_MRmobile{
    padding-top: 20px;
    padding-bottom:0px;
    padding-left: 8px;
    padding-right: 8px;
    height: 480px;
    width: 100%;
}
.card_title1_MRmobile {
    font-family: Mulish;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a0c2e;
  }
  
.card_text-testimonials_MRmobile{
    font-family: Mulish;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a0c2e;
  }
.Dual-Coat_merchant_MRmobile{
    width:0px;
}

.Dual-Coat_merchant-rev_MRmobile{
    width:0px;
}
.subTextTestimonial{
    /* width: 255px; */
    height: 70px;
    flex-grow: 0;
    margin: 2px 0 12px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: left;
    color: #000;
}
.proName{
    flex-grow: 0;
    align-items: flex-start;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000;
}
.serviceNameDiv{
    /* width: 85px; */
    height: 16px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 4px 10px 7.5px 0;
    padding: 2px 4px;
    border-radius: 4px;
    border: solid 0.5px #5ba85a;
}
.serviceName{
    flex-grow: 0;
  font-family: Inter;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1c1b1f;
}
.lcDiv{
     width: 35px;
    height: 35px; 
    flex-grow: 0;
    margin: 0 12px 3px 0;
    padding: 5px 6px;
    background-color:#8c52ff;
    border-radius: 50%;
  margin-top: 8px;
}
.lcText{
    flex-grow: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding-bottom: 5px;
  }
.loraText{
    flex-grow: 0;
    /* margin: 0 19px 19px 12px; */
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c1b1f;
}
.verifiedCustomer{
    flex-grow: 0;
    /* margin: 13px 0 0 12px; */
    font-family: Inter;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #4caf50;
}
/* ................. */

@media screen and (max-width: 1600px){
    .Dual-Coat_consumer{
        position: absolute;
        left: -100px;
        bottom: 255px;
    }
    .arrowLand_NewStyle4{
        display: none;
    }

    .card_view-testimonials_New{
        width: 295px;
            /* height: 200px; */
            flex-grow: 0;
            margin: 48px 6px 153px 82px;
            padding: 12px 20px;
            border-radius: 16px;
            box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
            border: solid 1px #3d42ab;
            background-color: #fff;
    }

    .Dual-Coat_merchant{
        position: absolute;
        left: -90px;
        bottom: 260px;
    }
    
    .Dual-Coat_merchant-rev{
        position: absolute;
        transform: rotateY(180deg);
        right: -70px;
        bottom: -20px;
    }
    .subTextTestimonial{
        /* width: 255px; */
        height: 70px;
        flex-grow: 0;
        /* margin: 2px 0 12px; */
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        text-align: left;
        color: #000;
    }
}

@media screen and (max-width: 1500px){

    .card_view-testimonials_New{
        width: 295px;
        /* height: 200px; */
        flex-grow: 0;
        margin: 48px 6px 153px 52px;
        padding: 12px 20px;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        border: solid 1px #3d42ab;
        background-color: #fff;
      
    }
    .subTextTestimonial{
     
        flex-grow: 0;
         height: 70px;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        text-align: left;
        color: #000;
    }
}

@media screen and (max-width: 1440px){
    


    .Dual-Coat_consumer{
        width:250px;
        left: -100px;
        top: 80px;
    }
    
    .Dual-Coat_consumer-rev{
        width:250px;
        right: -100px;
        bottom: -15px;
    }

    .Dual-Coat_merchant{
        width:250px;
        left: -100px;
        top: 80px;
    }
    
    .Dual-Coat_merchant-rev{
        width:250px;
        right: -90px;
        bottom: -10px;
    }
    .arrowLand_NewStyle4{
        display: none;
    }

    .card_view-testimonials_New{
        width: 295px;
        /* height: 200px; */
        flex-grow: 0;
        margin: 48px 6px 153px 45px;
        padding: 12px 20px;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        border: solid 1px #3d42ab;
        background-color: #fff;
      
    }
}

@media screen and (max-width: 1240px){
    


    .Dual-Coat_consumer{
        width:250px;
        left: -100px;
        top: 80px;
    }
    
    .Dual-Coat_consumer-rev{
        width:250px;
        right: -100px;
        bottom: -10px;
    }

    .Dual-Coat_merchant{
        width:250px;
        left: -100px;
        top: 100px;
    }
    
    .Dual-Coat_merchant-rev{
        width:250px;
        right: -100px;
        bottom: -10px;
    }

    .row_view-2{
        /* padding-top: 10px; */
        /* padding-bottom:10px; */
        padding-left: 2px;
        padding-right: 2px;
    }

    .card_img_view1{
        border-radius: 8px;
        /* width: 138px;
        height: 138px; */
        /* margin: 0 96px 32px 97px; */
        /* object-fit: contain; */
    }

    .card_view-testimonials{
        /* width:374px;
        height:464px; */
        /* border-radius: 8px;
        text-align: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px; */
        /* margin: 30px; */
        /* margin-top: 70px;
        margin-left: 20px;
        margin-right: 20px;
        inline-size: auto;
        word-wrap:break-word;
        box-shadow: -10px 20px 30px 5px rgba(25, 7, 52, 0.05);
        border:0px; */

        /* margin: 100px 24px 46px 114px;
        padding: 40px 22px 71px 21px; */
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 8px 10px 15px 3px rgba(25, 7, 52, 0.05);
        background-color: #fff;
    }
    .arrowLand_NewStyle4{
        display: none;
    }

    .card_view-testimonials_New{
  
        width: 295px;
      /* height: 200px; */
      flex-grow: 0;
      margin: 48px 6px 153px 20px;
      padding: 12px 20px;
      border-radius: 16px;
      box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
      border: solid 1px #3d42ab;
      background-color: #fff;
    
    }
    .arrow_cardNewLeft4{
        /* padding: 10px; */
        margin: 15px;
        width: 45px;
        height:45px;
        border-radius: 50px;
        position:absolute;
        left:-15px;
        bottom:150px;
        justify-content: center;
        align-items: center;
      }
}

@media screen and (max-width: 1024px){
    
    .arrowLand_NewStyle4{
        display:flex;
        /* margin-bottom: -50px; */

    }
    .disable_arrowLeft4{
        margin: 15px;
        width: 45px;
        height: 45px;
        border-radius: 50px;
        position:absolute;
        left:-15px;
        bottom:240px;
        justify-content: center;
        align-items: center;
        background-color: #fff;    
        opacity: 1;
       
      }
    .arrow_cardNewLeft4{
        /* padding: 10px; */
        margin: 15px;
        width: 45px;
        height:45px;
        border-radius: 50px;
        position:absolute;
        left:-15px;
        bottom:240px;
        justify-content: center;
        align-items: center;
      }
      .disable_arrowRight4{
        margin: 15px;
        width: 45px;
        height: 45px;
        border-radius: 50px;
        position:absolute;
        right:-15px;
        bottom:240px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        opacity: 1;
        
      }
      .arrow_cardNewRight4{
        /* padding: 10px; */
        margin: 15px;
        width: 45px;
        height: 45px;
        border-radius: 50px;
        position:absolute;
        right:-15px;
        bottom:240px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
    .carousel-button-group-testimonials{
        right: 0;
        bottom: 350px;
        /* border: 1px solid black; */
    }

    .card_img_view1{
        border-radius: 8px;
        /* width: 120px;
        height: 150px;
        margin-bottom: 20px; */
    }

    .card_view-testimonials{
        
        padding-top: 30px;
        padding-bottom: 10px;
    }

    .Dual-Coat_consumer{
        width:250px;
        left: -100px;
        top: 80px;
    }
    
    .Dual-Coat_consumer-rev{
        width:250px;
        right: -100px;
        bottom: 0px;
    }

    .Dual-Coat_merchant{
        width:250px;
        left: -100px;
        top: 100px;
    }
    
    .Dual-Coat_merchant-rev{
        width:250px;
        right: -100px;
        bottom: 0px;
    }

    .row_view-2{
        /* padding-top: 10px; */
        /* padding-bottom:10px; */
        padding-left: 0px;
        padding-right: 0px;
    }

    .testimonial_text-card{
        padding-left: 5px;
        /* border: 1px solid green; */
    
        
    }
    .card_view-testimonials_New{
        /* width: 315px; */
        margin: 48px 6px 153px 25px;
    }
   

}

@media screen and (max-width: 768px){
    .testmonyMain{
        font-size: 18px;
        margin-bottom: 20px;
      }
    .disable_arrowLeft4{
        margin: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        position:absolute;
        left:-15px;
        bottom:230px;
        justify-content: center;
        align-items: center;
        background-color: #fff;    
        opacity: 1;
       
      }
      
      .arrow_cardNewLeft4{
        /* padding: 10px; */
        margin: 15px;
        width: 40px;
        height:40px;
        border-radius: 50px;
        position:absolute;
        left:-15px;
        bottom:230px;
        justify-content: center;
        align-items: center;
      }
      
      .disable_arrowRight4{
        margin: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        position:absolute;
        right:-15px;
        bottom:230px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        opacity: 1;
        
      }
      .arrow_cardNewRight4{
        /* padding: 10px; */
        margin: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        position:absolute;
        right:-15px;
        bottom:230px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
    .row_view-2{
        /* padding-top: 0px; */
        /* padding-bottom:0px; */
        padding-left: 4px;
        padding-right: 4px;
    }
    .arrowLand_NewStyle4{
        display:flex;
    }

    .head_text1{
        font-size: 32px;
        color:rgb(38,22,64);
        padding-left: 0px;
    }
    .info_text1{
        font-size: 16px;
    }

    .carousel-button-group-testimonials{
        right: 0;
        bottom: 450px;
        /* border: 1px solid black; */
    }

    .card_img_view1{
        border-radius: 8px;
        width: 120px;
        height: 120px;
        margin-bottom: 40px;
    }

    .testimonial_text-card{
        left: 0px;
        top: 0;
        padding-left: 7px;
        /* border: 1px solid black; */
        
    }

    .card_view-testimonials{
        width:500px;
        height:400px;
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        /* margin: 30px; */
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 0px;
        /* border: 1px solid black; */
    }
    .card_view-testimonials_New{
        width: 295px;
        /* height: 200px; */
        flex-grow: 0;
        margin: 48px 6px 153px 20px;
        padding: 12px 20px;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        border: solid 1px #3d42ab;
        background-color: #fff;
      
    }

    .carousel_container-testimonials{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 500px;
        width: 100%;
        /* border: 1px solid blue; */
    }

    .Dual-Coat_consumer{
        width:200px;
        left: -80px;
        top: 60px;
    }
    
    .Dual-Coat_consumer-rev{
        width:200px;
        right: -80px;
        bottom: 60px;
    }

    .Dual-Coat_merchant{
        width:200px;
        left: -85px;
        top: 80px;
    }
    
    .Dual-Coat_merchant-rev{
        width:200px;
        right: -80px;
        bottom: 50px;
    }
   

}

@media (min-width: 770px) and (max-width: 900px){

    .card_view-testimonials{
        width:400px;
        height:400px;
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        /* margin: 30px; */
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 0px;
        /* border: 1px solid black; */
    }
    .Dual-Coat_consumer{
        width:200px;
        left: -40px;
        top: 120px;
    }
    
    .Dual-Coat_consumer-rev{
        width:200px;
        left: 355px;
        bottom: 65px;
    }
}

@media screen and (max-width: 670px){

    .card_view-testimonials{
        width:400px;
        height:400px;
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        /* margin: 30px; */
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        /* border: 1px solid black; */
    }

    .Dual-Coat_consumer{
        width:200px;
        left: -20px;
        top: 140px;
        display: none;
    }
    
    .Dual-Coat_consumer-rev{
        width:200px;
        left: 360px;
        bottom: 70px;
        display: none;

    }

    .Dual-Coat_merchant{
        position: absolute;
        left: 20px;
        bottom: 430px;
        display: none;

    }
    
    .Dual-Coat_merchant-rev{
        position: absolute;
        right: -110px;
        bottom: 80px;
        display: none;

    }
}

@media screen and (max-width: 571px){}

@media screen and (max-width: 448px){

    .testimonial_main-div{
        padding-top:40px;
        padding-bottom: 40px;
    }

    .row_view-2{
        /* padding-top: 0px; */
        /* padding-bottom:0px; */
        padding-left: 0px;
        padding-right: 0px;
        /* border: 1px solid black; */
    }
    /* .card_view-testimonials_New{
        margin-left: 10px;
    } */

    .head_text1{
        font-size: 26px;
        color:rgb(38,22,64);
        padding-left: 0px;
    }
    .info_text1{
        padding-top: 3px;
        font-size: 12px;
    }

    .testimonial_text-card{
        left: 0px;
        top: 0;
        padding-left: 5px;
        /* border: 1px solid black; */
        
    }

    .carousel-button-group-testimonials{
        right: 0;
        bottom: 435px;
        /* border: 1px solid black; */
    }

    .card_img_view1{
        border-radius: 8px;
        width: 110px;
        height: 110px;
        margin-bottom: 20px;
    }

    .card_view-testimonials{
        width:350px;
        height:380px;
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        /* margin: 30px; */
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        /* border: 1px solid black; */
    }
        .card_view-testimonials_New {
            width: 295px;
            /* height: 200px; */
            flex-grow: 0;
            margin: 48px 6px 153px 60px;
            padding: 12px 20px;
            border-radius: 16px;
            box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
            border: solid 1px #3d42ab;
            background-color: #fff;
    
        }
        .testmonyMain{
            font-size: 20px;
            margin-bottom: 20px;
           
          }
    .carousel_container-testimonials{
        padding-right:20px;
        padding-left:20px;
        padding-top:20px;
        padding-bottom:0px;
        height: 480px;
        width: 100%;
        /* border: 1px solid blue; */
    }

    .Dual-Coat_consumer{
        width:0px;
    }
    
    .Dual-Coat_consumer-rev{
        width:0px;
    }

    .Dual-Coat_merchant{
        width:0px;
    }
    
    .Dual-Coat_merchant-rev{
        width:0px;
    }

}

@media screen and (max-width: 375px){
    .row_view-2{
        /* padding-top: 0px; */
        /* padding-bottom:0px; */
        padding-left: 0px;
        padding-right: 0px;
    }

    .head_text1{
        font-size: 26px;
        color:rgb(38,22,64);
        padding-left: 0px;
    }
    .info_text1{
        font-size: 12px;
    }

    .testimonial_text-card{
        left: 1px;
        top: 0;
        padding-left: 7px;
        
    }

    .carousel-button-group-testimonials{
        right: 0;
        bottom: 435px;
        /* border: 1px solid black; */
    }

    .card_img_view1{
        border-radius: 8px;
        width: 110px;
        height: 110px;
        margin-bottom: 20px;
    }

    .card_view-testimonials{
        width:330px;
        height:380px;
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        /* margin: 30px; */
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        /* border: 1px solid black; */
    }
    .card_view-testimonials_New{
        width: 295px;
        /* height: 200px; */
        flex-grow: 0;
        margin: 48px 6px 153px 25px;
        padding: 12px 20px;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        border: solid 1px #3d42ab;
        background-color: #fff;
      
    }
    .carousel_container-testimonials{
        padding-top: 20px;
        padding-bottom:0px;
        padding-left: 8px;
        padding-right: 8px;
        height: 480px;
        width: 100%;
        /* border: 1px solid blue; */
    }

    .Dual-Coat_consumer{
        width:0px;
    }
    
    .Dual-Coat_consumer-rev{
        width:0px;
    }
    .Dual-Coat_merchant{
        width:0px;
    }
    
    .Dual-Coat_merchant-rev{
        width:0px;
    }

}
@media screen and (max-width:360px){
    .testimonial_main-div-merchant_MRmobile{
        padding-top:60px;
        padding-bottom: 0px;
        display:flex;
        flex-direction:column;
        align-items:center;
        background-color:#fff;
        text-align: center
    }
    .testimonial_text-card_MRmobile{
        display: flex;
        flex-direction: column;
        justify-content:space-around;
        position: absolute;
        left: 10px;
        top: 0;
        padding-left: 7px;
    }
    .info_text1_MRmobile{
        text-align: initial;
        font-family: Mulish;
        /* font-weight: bold; */
        font-size: 18px;
        color:rgb(38,22,64);
        padding-left: 2px;
        padding-top: 16px;
    }
    .row_view-2_MRmobile{
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .head_text1_MRmobile{
        font-size: 26px;
        color:rgb(38,22,64);
        padding-left: 0px;
    }
    .info_text1_MRmobile{
        font-size: 10px;
    }
    
    .testimonial_text-card_MRmobile{
        left: 1px;
        top: 0;
        padding-left: 7px;
        
    }
    
    .carousel-button-group-testimonials_MRmobile{
        right: 0;
        bottom: 435px;
        display: flex;
        flex-direction: row;
        position: absolute;
    }
    
    .card_img_view1_MRmobile{
        border-radius: 8px;
        width: 110px;
        height: 110px;
        margin-bottom: 20px;
    }
    
    .card_view-testimonials_MRmobile{
        border-radius: 8px;
        text-align: center;
        align-items: center;
        width:330px;
        height:380px;
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        box-shadow: 8px 10px 15px 3px rgba(25, 7, 52, 0.05);
        border:0px;
        inline-size: auto;
        word-wrap:break-word;
    }
    .carousel_container-testimonials_MRmobile{
        padding-top: 20px;
        padding-bottom:0px;
        padding-left: 8px;
        padding-right: 8px;
        height: 480px;
        width: 100%;
    }
    .card_title1_MRmobile {
        font-family: Mulish;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1a0c2e;
      }
      
    .card_text-testimonials_MRmobile{
        font-family: Mulish;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1a0c2e;
      }
    .Dual-Coat_merchant_MRmobile{
        width:0px;
    }
    
    .Dual-Coat_merchant-rev_MRmobile{
        width:0px;
    }
    .card_view-testimonials_New{
        width: 295px;
        /* height: 200px; */
        flex-grow: 0;
        margin: 48px 6px 153px 20px;
        padding: 12px 20px;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        border: solid 1px #3d42ab;
        background-color: #fff;
      
    }
}
@media screen and (max-width: 320px){
    .row_view-2{
        /* padding-top: 0px; */
        /* padding-bottom:0px; */
        padding-left: 5px;
        padding-right: 5px;
    }

    .head_text1{
        font-size: 26px;
        color:rgb(38,22,64);
        padding-left: 0px;
    }
    .info_text1{
        font-size: 12px;
    }

    .testimonial_text-card{
        left: -5px;
        top: 0;
        padding-left: 7px;
        
    }

    .carousel-button-group-testimonials{
        right: 0;
        bottom: 408px;
        /* border: 1px solid black; */
    }

    .card_img_view1{
        border-radius: 8px;
        width: 110px;
        height: 110px;
        margin-bottom: 15px;
    }

    .card_view-testimonials{
        width:270px;
        height:380px;
        padding-top: 30px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
        /* margin: 30px; */
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        /* border: 1px solid black; */
    }

    .carousel_container-testimonials{
        padding-top: 20px;
        padding-bottom: 0px;
        padding-right: 5px;
        padding-left: 5px;
        height: 450px;
        width: 100%;
        
        /* border: 1px solid blue; */
    }

    .Dual-Coat_consumer{
        width:0px;
    }
    
    .Dual-Coat_consumer-rev{
        width:0px;
    }
    .Dual-Coat_merchant{
        width:0px;
    }
    
    .Dual-Coat_merchant-rev{
        width:0px;
    }
    .card_view-testimonials_New{
        width: 280px;
        /* height: 200px; */
        flex-grow: 0;
        margin: 48px 16px 153px 8px;
        padding: 12px 20px;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        border: solid 1px #3d42ab;
        background-color: #fff;
      
      
    }

    .testmonyMain{
        font-size: 16px;
        margin-bottom: 20px;
       
      }

}
@media screen and (max-width: 300px){
    .card_view-testimonials_New{
        width: 255px;
        /* height: 200px; */
        flex-grow: 0;
        margin: 48px 16px 153px 8px;
        padding: 12px 20px;
        border-radius: 16px;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        border: solid 1px #3d42ab;
        background-color: #fff;
      
      
    }
}
@media screen and (min-width:0px) and (max-width:760px){
  .arrowLand_NewStyle4{
    display:none;

  }
  
}
