.service_details_banner_mobile{
   padding-left:0px;
   padding-right:0px;
   position: relative;
 }
.outlay_share_mobile{
    height: 26px;
    width: 26px;
    flex-grow: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 0 0 24px; */
    padding: 6px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
}
.imgStylesv2_servicedetail{
    width:100%;
    opacity: 0.5;
    transition: opacity 500ms 0ms ease-in-out;
    /* padding-left: 0px;
    padding-right: 0px; */
    height:350px;
}
.sub-div-first_mobile{
    flex-grow: 0;
    /* margin: 0 0 28px; */
    /* padding: 28px 20px 20px; */
    border-radius: 15px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eee;
    background-color: #fff;
}
.service_desc_sv2{
    /* margin-left: 20px;
    margin-right:20px; */
    margin-top:20px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    border: solid 1px #eee;
    background-color: #fff;
}
.sevice_name_mobile{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.service_price_mobile{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5ba85a;
}
.service_desc_mobile{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.service_id_mobile{
    /* margin: 0 269.5px 0 0; */
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.prid_mobile{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}
.reg_price_mobile{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
  
}
.pro_price_mobile{
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(140, 82, 255, 0.85);
}
.total_price_mobile{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  
}
.addToCart_Btn_Service_details{
    width: 100%;
    height: 50px;
    flex-grow: 0;
    border-radius: 10px;
    background-color: #8c52ff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addToCart_Btn_Service_details_Text{
    flex-grow: 0;
    font-family:'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
.cancel_box_mobile{
    /* height: 94px; */
    margin-top: 20px;
    padding-top: 12px ;
    padding-bottom: 12px;
    border-radius: 8px;
    padding-left:12px;
    padding-right:12px;
    border: solid 1px #ff5d5d;
    background-color: #fff;
    /* margin-left:20px;
    margin-right:20px; */
}
.cancel_fee_mobile{
    /* margin: 15px 0 0 206px; */
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ff5d5d;
}
.carousel-indicators_Landing_details {
    /* position: absolute; */
    bottom: 0;
    /* width: 100%; */
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;

}


.carousel-indicators_Landing_details li{
    background-color:#a5d028;
    
    }

.carousel-indicators_Landing_details .active{
    background-color:#ecf9c9;
    }
/* Service details deep linking page class names for web */
.ratingView_serDetDeepLink{
    width: 78px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 1px #ececec;
    background-color: #fff;
}
.verticalLine_serDetDeepLink{
    width: 1px;
    height: 500px;
    background-color: #e8e8e8;
    margin-left:20px;
}
.serviceDetView_serDetDeepLink{
    border-radius: 8px;
    border: solid 1px rgba(140, 82, 255, 0.4);
    background-color: #fff;
}
.serviceIdView_serDetDeepLink{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(86deg, #8C52FF 3%, #F7F2FF 98%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:16px;
}
.serviceId_TextDeep{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}
.serviceId_TextHeader{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.prices_serDetDeepLink{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.priceValues_serDetDeepLink{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}
.proPrice_serDetDeepLink{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: rgba(140, 82, 255, 0.85);
}
.proPriceValue_serDetDeepLink{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}
.totalPrice_serDetDeepLink{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.totalPriceValue_serDetDeepLink{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #5ba85a;
}
.cancellationPolicy_serDetDeepLink{
    padding: 16px 20px 20px;
    border-radius: 8px;
    border: solid 1px #ff5d5d;
    background-color: #fff;
}
.cancellationDisclaimer_serDetDeepLink{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.shareIconView_serDetDeepLink{
    height:36px;
    width:36px;
    flex-grow: 0;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 1px #eee;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}
.ratingText_serDetDeepLink{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.addToCart_Btn_serDetDeepLink{
    width: 200px;
    height: 50px;
    flex-grow: 0;
    border-radius: 10px;
    background-color: #8c52ff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addToCart_Btn_serDetDeepLinkText{
    flex-grow: 0;
    font-family:'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
.serviceNameText_serDetDeepLink{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
@media screen and (min-width:0px) and (max-width:768px){
    .addToCart_Btn_serDetDeepLink{
        width: 150px;
        height: 50px;
    }
    .addToCart_Btn_serDetDeepLinkText{
       font-size: 12px;
    }
}
