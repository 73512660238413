.profileName_mobile{
    flex-grow: 0;
  /* margin: 4px 0 0 18px; */
  font-family: 'Inter';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8c52ff;
  text-decoration: underline;
  cursor: pointer;
  
}
.profile_name_mobile{
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color:#8c52ff;
}
.email_mobile{
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.profileOptions_mobile{
    /* width: 25%; */
  /* height: 427px; */
  margin: 43.5px 28px 35px 0px;
  padding: 7px 6px 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
  border: solid 1px #eee;
  background-color: #fff;
}
.optionDiv_mobile{
  /* flexDirection:'row',display:'flex',justifyContent:'flex-start',padding:15,borderRadius:8 */
flex-direction: row;
display: flex;
justify-content: flex-start;
padding-top: 20px;
padding-left: 10px;
padding-bottom: 10px;
padding-right: 10px;
border-radius: 8px;
cursor: pointer;
}
.optionText_mobile{
  flex-grow: 0;
  margin: 2px 0 1px 14px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}
.logoutDiv_mobile{
  /* flexDirection:'row',display:'flex',justifyContent:'flex-start',padding:15,borderRadius:8 */
flex-direction: row;
display: flex;
justify-content: flex-start;
padding-top: 10px;
padding-left: 10px;
padding-bottom: 15px;
padding-right: 10px;
border-radius: 8px;
cursor: pointer;
}
.backgroudDiv_mobile{
  /* flexDirection:'row',display:'flex',justifyContent:'flex-start',padding:15,borderRadius:8 */
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;

}
.proactionDIv_mobile{
  width: 1366px;
  height: 1131px;
  flex-grow: 0;
  padding: 0 0 68px;
  background-color: #f1f1f1;
}
.profileActionDiv_mobile{
  width: 60%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;
border-radius: 15px;
box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
border: solid 1px #eee;
background-color: #fff;
}
@media screen and (max-width:1440px) {

.profileActionDiv_mobile{
  width: 75%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;
}
}
@media screen and (max-width:1240px) {
  .profileOptions_mobile{
    width: 55%;
  margin: 43.5px 28px 35px 0px;
  padding: 7px 6px 10px;
  
}
}
@media screen and (max-width:1024px) {
  .profileOptions_mobile{
    width: 30%;
  margin: 43.5px 28px 35px 0px;
  padding: 7px 6px 10px;
  
}
.profileActionDiv_mobile{
  width: 60%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;
}
}
@media screen and (max-width:786px) {

.profileOptions_mobile{
  width: 40%;
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;

}
.profileActionDiv_mobile{
  width: 60%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;
}
}