.ratingText{
    font-family: "Inter";
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #000000
}
.ratings{
    font-family: "Inter";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    text-align: center;
    color: #9f9f9f
}
.rate5{
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #747474
}
.ratingNums{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0;
    text-align: right;
    color: #9f9f9f
}
.rateAndReviewtext{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    font-style: "normal";
    letter-spacing: 0;
    text-align: "left";
    color: "#1c1b1f"
}
.verticalLine{
    width: 1;
    height: 130;
    background-color: "#e8e8e8";
    margin-left:20px;
}
@media screen and (min-width:0px) and (max-width:760px){
    .ratingText{
        font-size: 24px;
    }
}