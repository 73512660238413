.nearbyProCardImg1 {
  width: 186px;
  height: 155px;
  /* margin: 44px 45px 16px 20px; */
  /* padding: 16px 0 0; */
  border-radius: 10px;
  opacity: 0.5;
  transition: opacity 500ms 0ms ease-in-out;
}

.contentDiv{
  /* flexDirection: 'column', display: 'flex', marginTop: 30, marginLeft: 20,width:600 */
  flex-direction: column;
  display: flex;
  margin-top: 30px;
  margin-left: 20px;
  width: 600px;
}
.offerCopyDiv{
  /* flexDirection:'row',
  display:'flex',
  justifyContent:'flex-end',
  marginRight:-180,
  marginBottom:20,
  marginTop:-50 */
  flex-direction:row;
  display: flex;
  justify-content: flex-end;
  margin-right: -180px;
  margin-bottom: 20px;
  margin-top: -50;


}
.serviceMargin {
  margin-left: 30px;
}

.arrowOfferSv2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
}

.carousel_arrowOfferSv2 {
  height: 20px;
  width: 20px;
}

.arrowOfferRightSv2 {
  /* padding: 10px; */
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  right: -15px;
  bottom: 200px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

.disable_arrowOfferRightSv2 {
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  right: -15px;
  bottom: 170px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.5;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

.arrowOfferLeftSv2 {
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  left: -15px;
  bottom: 170px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

.disable_arrowOfferLeftSv2 {
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  left: -15px;
  bottom: 170px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.5;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

/* for service card */

.carousel_arrowOffer2Sv2 {
  height: 20px;
  width: 20px;
}

.arrowOffer2RightSv2 {
  /* padding: 10px; */
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  right: -15px;
  bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

.disable_arrowOffer2RightSv2 {
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  right: -20px;
  bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.5;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

.arrowOffer2LeftSv2 {
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  left: -20px;
  bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

.disable_arrowOffer2LeftSv2 {
  margin: 15px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  position: absolute;
  left: -20px;
  bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.5;
  box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}

.carousel_container_pros {
  height: 280;
  width: 100%;

}

.carousel_container_service1 {
  height: auto;
  width: 100%;
  /* padding-top: 50px; */
  /* margin-left: -20px; */
}

.line1_textCard {
  /* height: 1,
  opacity: 0.6,
  backgroundColor: "#b8b8b8",
  marginVertical: 5,
  marginRight: 20 */
  width: 600px;
  height: 1px;
  opacity: 0.6px;
  background-color: hsl(0, 83%, 37%);
  width: 400px;
}

.prosubtext {
  padding-left: 30px;
  color: #8c52ff;
  padding-right: 20px;
  /* margin-top:-10px; */
  /* margin-bottom: 10px; */
  /* position: absolute;
  top:-20 */
}

.nearbyProCard1 {
  width: 186px;
  height: 155px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: linear-gradient();
}

.ratingText_SCard1 {
  flex-grow: 0;
  /* margin: 2px 1px 1px 0; */
  font-family: Inter;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.rating_ProCardView1 {
  width: 50px;
  height: 35px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 11px 14px 0 8px; */
  /* padding: 8px 13px 9px; */
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
  border: solid 1px #ececec;
  background-color: #fff;
}

.wishlistProCardBg1 {
  /* position: absolute;
  right:10px;
  top:10px; */
  width: 28px;
  height: 28px;
  flex-grow: 0;
  /* display:flex; */
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
  background-color: #fff;
}

.primaryTextStyle1 {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  flex-wrap: wrap;
  /* max-width: 100px; */
}

.secondaryTextStyle1 {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;

  color: #ffffff
}

.offerGradinet {
  background: linear-gradient(#8c52ff, rgba(140, 82, 255, 0));
  /* margin-top: 95px; */
  padding-top: 60px;
}

.offerimg {
  /* width: 940.9px; */
  height: 259.8px;
  margin: 39px 0.1px 27.2px 241px;
  object-fit: contain;

}

.header {
  margin-left: 30px;
  margin-bottom: 20px;
  /* margin-top: 15px; */
}

.offerbySlash {
  margin: 0 20px 44px;
  border-radius: 20px;
  border: solid 1px;
  border-color: #8c52ff;
}

.textCopy {
  flex-grow: 0;
  font-family: Inter;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #461c99;
}

.testSty {
  margin: 44px 72px 8px 0;
  opacity: 0.6;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}

.offersSubtext {
  margin: 8px 84px 0 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9f9f9f;
}

.textTerm {
  margin-top: 10px;
  font-family: Inter;

  font-weight: 700;
  font-size: 17px;
  font-style: normal;
  color: #461c99;
  text-align: left;

}

.text2 {
  margin-top: 10px;
  font-family: Inter;
  font-weight: 300;
  font-size: 17px;
  font-style: normal;
  color: #461c99;
  text-align: left;
}

.btnborder {
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 0;
  border-radius: 10px;
  border: solid 1.5px;
  border-color: #461c99;
  cursor: pointer;
}

.btntext {
  align-self: center;
  flex-grow: 0;
  font-family: Inter;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #461c99;
  margin: 50px;

}

.copyText {

 
  margin-top: 10px;
  margin-left: 10px;
 
  margin-right: 10px;
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #461c99;
}

.copydiv {
  flex-direction: row;
  justify-content: left;
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
@media screen and (max-width:1600px) {
  .imgSize {
    width: 300px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 230px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 300.7px;
    /* height: 155.7px; */
    /* position: absolute; */
    margin-left: 0px;
    margin-top: 98px;
    border-bottom-left-radius: 20px;


  }
  .offerimg {
    width: 940.9px;
    height: 259.8px;
    margin: 39px 0.1px -27.2px 290px;

  }
}

@media screen and (max-width:1440px) {
  .arrowOffer2Sv2 {
    display: flex;
  }
  .offerCopyDiv{
    /* flexDirection:'row',
    display:'flex',
    justifyContent:'flex-end',
    marginRight:-180,
    marginBottom:20,
    marginTop:-50 */
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right: -180px;
    margin-bottom: 20px;
    margin-top: -55px;
  
  
  }
  .arrowOfferRightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -15px;
    bottom: 150px;
  }

  .disable_arrowOfferRightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -15px;
    bottom: 150px;
  }

  .arrowOfferLeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -15px;
    bottom: 150px;
  }

  .disable_arrowOfferLeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -15px;
    bottom: 150px;
  }

  .offerimg {
    width: 940.9px;
    height: 259.8px;
    margin: 39px 0.1px -27.2px 240px;

  }

  .arrowOffer2RightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2RightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
  
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  .offerbySlash {
    /* margin: 0 20px 44px; */
    border-radius: 20px;
  }

  .textCopy {
    font-size: 30px;

  }

  .testSty {
    margin: 44px 72px 8px 0;
    font-size: 16px;

  }

  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 14px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 17px;

  }

  .text2 {
    margin-top: 10px;
    font-weight: 300;
    font-size: 17px;

  }

  .btnborder {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btntext {
    align-self: center;
    font-size: 17px;
    margin: 50px;

  }

  .copyText {
    flex-grow: 0;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 24px;
  }

  .copydiv {
    margin-bottom: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .imgSize {
    width: 300px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 230px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 300.7px;
    /* height: 155.7px; */
    /* position: absolute; */
    margin-left: 0px;
    margin-top: 98px;
    border-bottom-left-radius: 20px;


  }

}

@media screen and (max-width:1240px) {
  .imgSize {
    width: 300px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 240px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 300.7px;
    /* height: 155.7px; */
    margin-left: 0px;
    margin-top: 105px;
    border-bottom-left-radius: 20px;


  }
  .offerCopyDiv{
  
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right: -20px;
    margin-bottom: 20px;
    margin-top: -55px;
  
  
  }

  .arrowOfferRightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -15px;
    bottom: 180px;
  }

  .disable_arrowOfferRightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -15px;
    bottom: 180px;
  }

  .arrowOfferLeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -15px;
    bottom: 180px;
  }

  .disable_arrowOfferLeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -15px;
    bottom: 180px;
  }

  .prosubtext {
    padding-left: 40px;
    color: #8c52ff;
    margin-top: 10px;
    /* position: absolute;
    top:-20 */
  }

  .offerbySlash {
    /* margin: 0 20px 44px; */
    border-radius: 20px;
  }
  .arrowOffer2RightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2RightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
  
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  .textCopy {
    font-size: 30px;

  }

  .testSty {
    margin: 44px 72px 8px 0;
    font-size: 16px;

  }

  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 14px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 17px;

  }

  .text2 {
    margin-top: 10px;
    font-weight: 300;
    font-size: 17px;

  }

  .btnborder {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btntext {
    align-self: center;
    font-size: 17px;
    margin: 50px;

  }

  .copyText {
    flex-grow: 0;
    margin-top: 10px;
    margin-left: 100px;
    margin-right: 100px;
    font-size: 24px;
  }

  .copydiv {
    margin-bottom: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .offerimg {
    width: 940.9px;
    height: 259.8px;
    margin: 39px 0.1px -27.2px 160px;

  }

  .carousel_container_pros {
    height: auto;
    width: 100%;
    /* margin-left: 20px; */
  }

}

@media screen and (max-width:1024px) {
  .offerCopyDiv{
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: -35px;
  }
  .arrowOffer2Sv2 {
    display: flex;
  }

  .arrowOffer2RightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -5px;
    bottom: 40px;

  }

  .disable_arrowOffer2RightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -5px;
    bottom: 40px
  }

  .arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -10px;
    bottom: 40px;
  }

  .disable_arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    /* right: -10; */
    left: -10px;
    bottom: 40px;

  }

  .prosubtext {
    padding-left: 20px;
    color: #8c52ff;
    margin-top: 10px;
    /* position: absolute;
    top:-20 */
  }
  .arrowOffer2RightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2RightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
  
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  .arrowOfferRightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -15px;
    bottom: 180px;
  }

  .disable_arrowOfferRightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -15px;
    bottom: 180px;
  }

  .arrowOfferLeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -15px;
    bottom: 180px;
  }

  .disable_arrowOfferLeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -15px;
    bottom: 180px;
  }

  .offerimg {

    width: 800px;
    height: 259.8px;
    margin: 39px 0.1px -27.2px 120px;
  }

  .offerbySlash {
    /* margin: 0 20px 44px; */
    border-radius: 20px;
  }

  .textCopy {
    font-size: 30px;

  }

  .testSty {
    margin: 44px 72px 8px 0;
    font-size: 16px;

  }

  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 14px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 17px;

  }

  .text2 {
    margin-top: 10px;
    font-weight: 300;
    font-size: 17px;

  }

  .btnborder {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btntext {
    align-self: center;
    font-size: 17px;
    margin: 25px;

  }

  .copyText {
    flex-grow: 0;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 24px;
  }

  .copydiv {
    margin-bottom: 0px;
    margin-right: 00px;
    margin-top: 10px;
  }

  .imgSize {
    width: 300px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 230px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 300.7px;
    /* height: 155.7px; */
    margin-left: 0px;
    margin-top: 90px;
    border-bottom-left-radius: 20px;


  }

  .carousel_container_pros {
    height: auto;
    width: 100%;
    /* margin-left: 10px; */
  }
}

@media screen and (max-width:786px) {
  .offerCopyDiv{
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: -38px;
  }
  .serviceMargin {
    margin-left: 10px;
  }

  .header {
    margin-left: 10px;
    margin-bottom: 20px;
    /* margin-top: 15px; */
  }

  .arrowOffer2RightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -15px;
    bottom: 40px;

  }

  .disable_arrowOffer2RightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    right: -20px;
    bottom:40px;
  }

  .arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -10px;
    bottom: 40px;
  }

  .disable_arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    left: -10px;
    bottom: 40px;

  }

  .prosubtext {
    padding-left: 18px;
    color: #8c52ff;
    margin-top: 10px;
    /* position: absolute;
    top:-20 */
  }
  .arrowOffer2RightSv2 {
    /* padding: 10px; */
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2RightSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  
  .disable_arrowOffer2LeftSv2 {
    margin: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    position: absolute;
    left: -15px;
    bottom: 40px;
  
    opacity: 0.5;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
  }
  .offerimg {

    width: 600px;
    height: 159.8px;
    margin: 39px 0.1px -15.2px 90px;
  }

  .offerbySlash {
    margin: 0 20px 44px 0px;
    border-radius: 20px;
  }

  .textCopy {
    font-size: 18px;

  }

  .testSty {
    margin: 44px 72px 8px 0;
    font-size: 14px;

  }

  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 14px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 17px;

  }

  .text2 {
    margin-top: 10px;
    /* font-weight: 300; */
    font-size: 14px;

  }

  .btnborder {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btntext {
    align-self: center;
    font-size: 17px;
    margin: 15px;

  }

  .copyText {
    flex-grow: 0;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 15px;
    font-size: 16px;
  }

  .copydiv {
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top: 10px;
  }

  .imgSize {
    width: 300px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 160px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 300.7px;
    /* height: 155.7px; */
    margin-left: 0px;
    margin-top: 40px;
    border-bottom-left-radius: 20px;


  }

  .carousel_container_pros {
    height: auto;
    width: 100%;
    /* margin-left: 0px; */
  }

}

@media screen and (min-width:320px) and (max-width:480px) {
 
    .carousel_container_service1 {
      height: auto;
      width: 100%;
      /* padding-top: 50px; */
      margin-left:-23px;
    }
    
    .offerCopyDiv{
      flex-direction:row;
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
      margin-bottom: 20px;
      margin-top: -28px;
     
    }
    .carousel_container_pros {
      height: auto;
      width: 100%;
      margin-left: -20px;
    }
    .prosubtext {
      padding-left: 30px;
      color: #8c52ff;
      margin-top: 0px;
      /* position: absolute;
      top:-20 */
    }
  
    .offerGradinet {
      margin-top: 0px;
    }
  
    .offerimg {
  
      width: 100%;
      height: 184.8px;
      margin: 50px 0px -10px 0px;
  
    }
  
    .offerbySlash {
      margin: 0 0px 4px 0px;
      border-radius: 20px;
      border: solid 1px;
      border-color: #8c52ff;
    }
  
    .imgSize {
      width: 123.3px;
      /* height: 20.7px; */
      flex-grow: 0;
      /* margin: 2px 6.7px 0 0; */
      object-fit: contain;
      margin-top: 180px;
      border-bottom-left-radius: 20px;
    }
  
    .imgSize1 {
      width: 123.7px;
      /* height: 155.7px; */
      margin-left: 0px;
      margin-top: 75px;
      border-bottom-left-radius: 20px;
  
    }
  
    .textCopy {
      font-size: 12px;
  
    }
  
    .testSty {
      margin: 44px 72px 8px 0;
      font-size: 12px;
  
    }
  
    .offersSubtext {
      margin: 8px 84px 0 0;
      font-size: 12px;
  
    }
  
    .textTerm {
      margin-top: 10px;
      font-size: 12px;
  
    }
  
    .text2 {
      margin-top: 10px;
      /* font-weight: 300; */
      font-size: 10px;
  
    }
  
    .btnborder {
      padding-top: 5px;
      padding-bottom: 5px;
      border-width: 1px;
    }
  
    .btntext {
      align-self: center;
      font-size: 12px;
  
  
    }
  
    .header {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  
    .copyText {
      flex-grow: 0;
      margin-top: 15px;
      margin-left: 5px;
      margin-right: 15px;
      font-size: 12px;
    }
  
    .copydiv {
      margin-bottom: 0px;
      margin-right: 0px;
      margin-top: 10px;
    }
  
    .serviceMargin {
      margin-left: 10px;
      margin-top: 10px;
    }
    .contentDiv{
      /* flexDirection: 'column', display: 'flex', marginTop: 30, marginLeft: 20,width:600 */
      flex-direction: column;
      display: flex;
      margin-top: 10px;
      margin-left: 0px;
      width: 600px;
      /* padding-left: 5px; */
    }
    
  
}

@media screen and (max-width:425px) {
  .carousel_container_service1 {
    height: auto;
    width: 100%;
    /* padding-top: 50px; */
    margin-left:-23px;
  }
  
  .offerCopyDiv{
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: -28px;
   
  }
  .carousel_container_pros {
    height: auto;
    width: 100%;
    margin-left: -20px;
  }
  .prosubtext {
    padding-left: 30px;
    color: #8c52ff;
    margin-top: 0px;
    /* position: absolute;
    top:-20 */
  }

  .offerGradinet {
    margin-top: 0px;
  }

  .offerimg {

    width: 100%;
    height: 184.8px;
    margin: 50px 0px -10px 0px;

  }

  .offerbySlash {
    margin: 0 0px 4px 0px;
    border-radius: 20px;
    border: solid 1px;
    border-color: #8c52ff;
  }

  .imgSize {
    width: 123.3px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 180px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 123.7px;
    /* height: 155.7px; */
    margin-left: 0px;
    margin-top: 75px;
    border-bottom-left-radius: 20px;

  }

  .textCopy {
    font-size: 12px;

  }

  .testSty {
    margin: 44px 72px 8px 0;
    font-size: 12px;

  }

  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 12px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 12px;

  }

  .text2 {
    margin-top: 10px;
    /* font-weight: 300; */
    font-size: 10px;

  }

  .btnborder {
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: 1px;
  }

  .btntext {
    align-self: center;
    font-size: 12px;


  }

  .header {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .copyText {
    flex-grow: 0;
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 15px;
    font-size: 12px;
  }

  .copydiv {
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top: 10px;
  }

  .serviceMargin {
    margin-left: 10px;
    margin-top: 10px;
  }
  .contentDiv{
    /* flexDirection: 'column', display: 'flex', marginTop: 30, marginLeft: 20,width:600 */
    flex-direction: column;
    display: flex;
    margin-top: 10px;
    margin-left: 0px;
    width: 600px;
    /* padding-left: 5px; */
  }
  
}

@media screen and (max-width:375px) {
  .contentDiv{
    /* flexDirection: 'column', display: 'flex', marginTop: 30, marginLeft: 20,width:600 */
    flex-direction: column;
    display: flex;
    margin-top: 10px;
    margin-left: 0px;
    width: 600px;
  }
  .offerCopyDiv{
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right:2px;
    margin-bottom: 20px;
    margin-top: -28px;
  }
  .serviceMargin {
    margin-left: 10px;
    margin-top: 10px;
  }

  .prosubtext {
    padding-left: 20px;
    color: #8c52ff;
    margin-top: 0px;
    /* position: absolute;
    top:-20 */
  }

  .offerGradinet {
    margin-top: 0px;
  }

  .offerimg {

    width: 100%;
    height: 180.8px;
    margin: 20px 0px -25px 0px;

  }

  .offerbySlash {
    margin: 0 0px 4px 0px;
    border-radius: 20px;
    border: solid 1px;
    border-color: #8c52ff;
  }


  .imgSize {
    width: 123.3px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 170px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 123.7px;
    /* height: 155.7px; */
    margin-left: 0px;
    margin-top: 70px;
    border-bottom-left-radius: 20px;


  }

  .textCopy {
    font-size: 10px;
    margin-top: 10px;

  }

  .testSty {
    margin: 44px 72px 8px 0;
    font-size: 12px;

  }

  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 12px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 12px;

  }

  .text2 {
    margin-top: 10px;
    /* font-weight: 300; */
    font-size: 8px;

  }

  .btnborder {
    padding-top: 0px;
    padding-bottom: -80px;
  }

  .btntext {
    align-self: center;
    font-size: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;


  }

  .header {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .copyText {
    flex-grow: 0;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 15px;
    font-size: 12px;
  }

  .copydiv {
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top: 10px;
  }
  .carousel_container_pros {
    height: auto;
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
  .carousel_container_service1 {
    height: auto;
    width: 100%;
    /* padding-top: 50px; */
    margin-left:-20px;
  }
}

@media screen and (max-width:325px) {
  .carousel_container_service1 {
    height: auto;
    width: 100%;
    /* padding-top: 50px; */
    margin-left: -30px;
  }
  .carousel_container_pros {
    height: auto;
    width: 100%;
    /* margin-left: -20px; */
    margin-top: 0px;
  }
  .contentDiv{
    /* flexDirection: 'column', display: 'flex', marginTop: 30, marginLeft: 20,width:600 */
    flex-direction: column;
    display: flex;
    margin-top: 10px;
    margin-left: 0px;
    width: 600px;
  }

  .offerCopyDiv{
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    margin-bottom: 10px;
    margin-top: -22px;
  }
  .offerGradinet {
    margin-top: 0px;
  }

  .imgSize {
    width: 123.3px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 160px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 115.7px;
    /* height: 155.7px; */
    margin-left: 0px;
    margin-top: 80px;
    border-bottom-left-radius: 20px;


  }

  .textCopy {
    font-size: 10px;

  }

  .testSty {
    margin: 10px 2px -10px 0;
    font-size: 12px;

  }
 
  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 12px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 12px;

  }

  .text2 {
    margin-top: 8px;
    /* font-weight: 300; */
    font-size: 8px;

  }

  .btnborder {
    padding-top: 0px;
    padding-bottom: 1px;
    border-radius: 8px;
  }

  .btntext {
    /* align-self: center; */
    font-size: 8px;
    margin-left:10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: -5px;

  }

  .header {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .copyText {
    flex-grow: 0;
    margin-top: 15px;
    margin-left: 2px;
    margin-right: 5px;
    font-size: 10px;
  }

  .copydiv {
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top:10px;
  }
  .offerimg {

    width: 100%;
    height: 150.8px;
    margin: 10px 0px -15px 0px;

  }

}
@media screen and (max-width:320px) {
  .proText{
    font-size: 14px;
    color: #000;
  }
    .carousel_container_service1 {
      height: auto;
      width: 100%;
      /* padding-top: 50px; */
      margin-left: -25px;
    }
    .carousel_container_pros {
      height: auto;
      width: 100%;
      margin-left: -12px;
      margin-top: 10px;
    }
    .contentDiv{
      /* flexDirection: 'column', display: 'flex', marginTop: 30, marginLeft: 20,width:600 */
      flex-direction: column;
      display: flex;
      margin-top: 10px;
      margin-left: 0px;
      width: 600px;
    }
  
    .offerCopyDiv{
      flex-direction:row;
      display: flex;
      justify-content: flex-end;
      margin-right: 5px;
      margin-bottom: 10px;
      margin-top: -28px;
    }
    .offerGradinet {
      margin-top: 0px;
    }
  
    .imgSize {
      width: 123.3px;
      /* height: 20.7px; */
      flex-grow: 0;
      /* margin: 2px 6.7px 0 0; */
      object-fit: contain;
      margin-top: 160px;
      border-bottom-left-radius: 20px;
    }
  
    .imgSize1 {
      width: 115.7px;
      /* height: 155.7px; */
      margin-left: 0px;
      margin-top: 70px;
      border-bottom-left-radius: 20px;
  
  
    }
  
    .textCopy {
      font-size: 10px;
  
    }
  
    .testSty {
      margin: 10px 2px -10px 0;
      font-size: 12px;
  
    }
   
    .offersSubtext {
      margin: 8px 84px 0 0;
      font-size: 12px;
  
    }
  
    .textTerm {
      margin-top: 10px;
      font-size: 12px;
  
    }
  
    .text2 {
      margin-top: 8px;
      /* font-weight: 300; */
      font-size: 8px;
  
    }
  
    .btnborder {
      padding-top: 0px;
      padding-bottom: 2px;
      border-radius: 8px;
    }
  
    .btntext {
      /* align-self: center; */
      font-size: 10px;
      margin-left:5px;
      margin-right: 5px;
      margin-bottom: 0px;
      margin-top: -10px;

   
  
    }
  
    .header {
      margin-left: 0px;
      margin-bottom: 5px;
    }
  
    .copyText {
      flex-grow: 0;
      margin-top: 10px;
      margin-left: 2px;
      margin-right: 5px;
      font-size: 8px;
    }
  
    .copydiv {
      margin-bottom:0px;
      margin-right: 0px;
      margin-top:10px;
    }
    .offerimg {
  
      width:100%;
      height: 150.8px;
      margin: 10px 0px -15px 0px;
  
    }
  
  }
  
@media screen and (max-width:300px) {
.proText{
  font-size: 14px;
  color: #000;
}
  .carousel_container_service1 {
    height: auto;
    width: 100%;
    /* padding-top: 50px; */
    /* margin-left: -45px; */
  }
  .carousel_container_pros {
    height: auto;
    width: 100%;
    /* margin-left: -20px; */
    margin-top: 10px;
  }
  .contentDiv{
    /* flexDirection: 'column', display: 'flex', marginTop: 30, marginLeft: 20,width:600 */
    flex-direction: column;
    display: flex;
    margin-top: 10px;
    margin-left: 0px;
    width: 600px;
  }

  .offerCopyDiv{
    flex-direction:row;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    margin-bottom: 10px;
    margin-top: -28px;
  }
  .offerGradinet {
    margin-top: 0px;
  }

  .imgSize {
    width: 123.3px;
    /* height: 20.7px; */
    flex-grow: 0;
    /* margin: 2px 6.7px 0 0; */
    object-fit: contain;
    margin-top: 160px;
    border-bottom-left-radius: 20px;
  }

  .imgSize1 {
    width: 115.7px;
    /* height: 155.7px; */
    margin-left: 0px;
    margin-top: 70px;
    border-bottom-left-radius: 20px;


  }

  .textCopy {
    font-size: 10px;

  }

  .testSty {
    margin: 10px 2px -10px 0;
    font-size: 12px;

  }
 
  .offersSubtext {
    margin: 8px 84px 0 0;
    font-size: 12px;

  }

  .textTerm {
    margin-top: 10px;
    font-size: 12px;

  }

  .text2 {
    margin-top: 8px;
    /* font-weight: 300; */
    font-size: 8px;

  }

  .btnborder {
    padding-top: 0px;
    padding-bottom: 2px;
    border-radius: 8px;
  }

  .btntext {
    /* align-self: center; */
    font-size: 8px;
    margin-left:2px;
    margin-right: 2px;
    margin-bottom: 5px;
    margin-top:0px;

  }

  .header {
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .copyText {
    flex-grow: 0;
    margin-top: 10px;
    margin-left: 2px;
    margin-right: 5px;
    font-size: 8px;
  }

  .copydiv {
    margin-bottom: 0px;
    margin-right: 0px;
    margin-top:10px;
  }
  .offerimg {

    width:100%;
    height: 150.8px;
    margin: 10px 0px -15px 0px;

  }

}

@media screen and (min-width:0px) and (max-width:760px) {
  .arrowOfferSv2 {
    display: none;
  }

  .arrowOffer2Sv2 {
    display: none;
  }

  .primaryTextStyle1 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    color: #ffffff;
    flex-wrap: wrap;

  }
}