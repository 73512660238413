.solid_buttonStyle{
    border-radius:10px;
    /* padding-vertical:18, */
    align-items:center;
    width:auto;
    height:48px;
    display: flex;
    justify-content:center;
    flex-direction:column;
    padding: 10px 18px;
    cursor: pointer;
}
.solid_textStyle{
    text-align: center;
    /* font-size:12px; */
    font-weight: "bold";
    font-style: "normal";
    letter-spacing: 0;
    font-family: Inter;
    /* font-size: 20px; */
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

@media screen and (max-width: 425px){
    .solid_buttonStyle{
        
        height:48px;
        
    }
}