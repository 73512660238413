.logoSlash_nav_Landing{

    width:70%;
    /* width: 168px; */
  /* height: 118px; */
    /* text-align: left; */
}

.dropdown-menu{
  position: initial !important;
  min-width: 7rem !important;
  text-align: center !important;
  padding: 0.1rem 0 !important;

}

.navbar_main{
    position:fixed;
    display:flex;
    justify-content:center;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    z-index:1000;
    background-color:#fff;
    padding:10px 10px 10px 129px;
    overflow: hidden;
}

.navbar_globe{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width:129px
}

.nav_logo_Landing{
    width:10%;
    margin: 0px;
    padding:5px 0px 0px 0px;
    text-align: left;
    border-right:1px solid #aca9c7

    
    /* border:1px solid black */
}
.nav_bar_content_Landing{
    text-decoration: none;
    padding-top: 2px;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    /* border:1px solid black */
}
.nav_button_Landing{
    /* width: 15%; */
    /* padding: 5px; */
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    right:9px;
}

.ResponsiveDownload{
    display: flex;
    position: absolute;
    margin-left: auto;
    margin-right: auto;

    /* padding: 0px 100px 10px 5px; */
    /* top:0; */
    right:40px;
  

}
.UsLabel{

    display: block;

}

.cross_div_Landing{
    /* padding-left: 0px; */
    align-self:center;
    margin-left: auto;
    margin-right: 10px;
}


.togglerNav-div_Landing{
    /* text-align:left; */
     width:100%;
     padding:5px;
     /* border:1px solid black */
    }

    .cross_Landing{

        height:25px;
        width:25px;
        right: 0px;
        top: 0px;
        display: block;
        align-self: flex-end;
    }
    


.navoptions_Landing{

    /* display: block; */
    /* float:left; */
    width: 60%;
    display:flex;
    flex-direction:row;
    /* justify-content:space-between; */
    /* margin-right:22px ; */
    text-decoration: none;
    /* border: 1px solid black; */


}

.subNavStyle2_Landing{
    background-color:#442b6a;
    color:#ffffff;
    padding:7px;
    border-radius:20px;
    text-align: center;
    width: auto;

    text-decoration:none;
    font-weight:600;
    font-size:16px;
    font-family: Montserrat;
    text-decoration: none;
}

.subNavStyle_Landing{
    color:#261640!important;
    /* padding:5px; */
    border-radius:3px;

    margin-right: 22px;

    text-decoration:none!important;
    font-weight:600;
    font-size:16px;
    font-family: Montserrat;
}

.mr-auto_Landing a{

    color:#261640;
    text-decoration: none;
}

 .subNavStyleHeader_Landing {
    color:#261640!important;
    padding:5px;
    border-radius:3px;

    text-decoration:none;
    font-weight:600;
    font-size:16px;
    font-family: Montserrat;
    text-decoration: none!important;
}

.text-div_modal_Landing{
    width: 530px;
    height: auto;
    flex-grow: 0;
    position:absolute;
    left:110px;
    top:240px;
    text-align:left;
    /* border: 1px solid black; */

}

.text-div-info_modal_Landing {
    margin-bottom: 20px;
    padding-left: 4px;
    width: 68%;
    
}

.text-div-available_modal_Landing{
    margin-top: 60px;
    margin-bottom: 5px;
    padding-left: 4px;
    width: 50%;
    /* border: 1px solid black; */
    text-align: center;
    
}

.logo_div_modal_Landing{
    background-color:#3f2764;
    width:600px;
    height:450px;
    border-radius:50%;
    position:absolute;
    top:-230px;
    left:-110px;
}

.main-text_modal_Landing {
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #261640;
}

.get-digital-real_Landing {
    flex-grow: 0;
    margin-top: 24px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #1a0c2e;
    text-overflow: inherit;
}

.logo_image_modal_Landing {
    position:absolute;
    left:160px;
    bottom:40px;
    width:50%;
    background-color:transparent;
}

.sendlink_input_div_Landing {
    margin-top: 60px;
    width: 85%;
}
.sendlink_input_div_SMS_Landing {
    margin-top: 20px;
    width: 85%;
}

.downloadAppImage_Nav_Landing {
    width: 115px;
    height: 35px;
}

.downloadImage-appstore_Nav_Landing{
    padding-right:15px
}
.downloadImage-googleplay_Nav_Landing{
    padding-left:15px
}
.downloadImage-div_Landing{
    display: flex;
    flex-direction: row;
    text-align: initial;
}
.modal_phone_image_Landing {
    width: 75%;
}

.modal_phone_image1_Landing {
    width: 75%;
    display: none;
}

.modal_main_div_Landing{
    position:relative;
    width:100%;
    height:100%;
    text-align:right;
    overflow:hidden;
}
.button_send_link_Landing{
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
    /* border: 1px rgb(68,43,106) solid; */
    background-color: rgb(68,43,106);
    /* background-color: #fff; */
}
.button_send_link_Landing:hover{
    background-color: rgb(68,43,106);
}
.button_send_link_Landing:focus{
    background-color: rgb(68,43,106);
}
.button_send_link_Landing:active{
    background-color: rgb(68,43,106);
}
.skip_download_Landing{
    top:20px;
    right:50px;
    position:absolute;
    cursor:pointer;
}

@media screen and (min-width:990px){

    .togglerNav-div_Landing{
        width:0%;
        display: none;
    }

    .cross_Landing{

        align-self: flex-end;


        display: none
    }

    .cross_div_Landing{
        display: none
    }

}


@media screen and (max-width:2560px){

    .ResponsiveDownload{
        display:none;
    }

    subNavStyle2_Landing{
        width: auto;
    }
}



@media screen and (max-width:1440px){

    .ResponsiveDownload{
        display:none;
    }
}

@media screen and (max-width:1320px){

    .ResponsiveDownload{
        display:none;
    }

    .subNavStyleHeader_Landing{
        /* padding:5px 0px 5px 0px; */
        font-weight:600;
        font-size:16px;
        text-decoration: none;
    }
    .nav_logo_Landing{
        width: 10%;
        margin: 0px;
        /* padding-top: 12px; */
        /* border:1px solid black */
    }
    /* .nav_bar_content_Landing{
        width: 25%;
        /* padding-top: 7px; */
        /* border:1px solid black */
    /* } */
    
    .navoptions_Landing{
    
        width: 60%;
        padding: 0px;
        /* border:1px solid black */
    
    }
}
.navlinkPadding{
    padding:0.5rem 0.8rem;

}
@media screen and (max-width:1290px){
    /* .subNavStyleHeader_Landing{
        /* padding:5px 0px 5px 0px; */
        /* font-weight:900;
        font-size:20px;
        text-decoration: none; */
    /* } */

    .ResponsiveDownload{
        display:none;
    }


    .navbar_main{
        padding:15px 0px 15px 50px;
    }
    .navbar_globe{
        width:50px
    }

    
    .nav_logo_Landing{
        width: 10%;
        margin: 0px;
        /* padding-top: 12px; */
        /* border:1px solid black */
    }
    /* .nav_bar_content_Landing{
        width: 25%;
        padding-top: 5px; */
        /* border:1px solid black */
    /* } */
    
    .navoptions_Landing{
    
        width: 60%;
        padding: 0px;
        /* border:1px solid black */
    
    }
}

@media screen and (max-width:1240px){

    .ResponsiveDownload{
        display:none;
    }


.logoSlash_nav_Landing{

    width:80%
}

.navbar_main{
    position:fixed;
    display:flex;
    justify-content:center;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    z-index:1000;
    background-color:#fff;
    padding:10px 0px 10px 110px;
    overflow: hidden;
}
.navbar_globe{
    width:110px
}

.subNavStyle2_Landing{
    background-color:#442b6a;
    color:#ffffff;
    padding:0px;
    /* border-radius:3px; */
    text-decoration:none;
    font-weight:600;
    font-size:16px;
    font-family: Montserrat;
    text-decoration: none;
    padding: 10px 5px 10px 5px;
}

.subNavStyle_Landing{
    font-weight:600;
    font-size:16px;
    /* padding:0px 0px 0px 0px; */
    /* padding-bottom: 5px; */
}

/* .subNavStyleHeader_Landing{ */
    /* padding:5px 0px 5px 0px;
    font-weight:900;
    font-size:20px;
    text-decoration: none; */
/* } */
.nav_logo_Landing{
    width: 10%;
    margin: 0px;
    /* padding-top: 12px; */
    /* border:1px solid black */
}
.nav_bar_content_Landing{
    width: 25%;
    /* padding-top: 5px; */
    /* border:1px solid black */
/* }

.navoptions_Landing{

    width: 65%;
    padding: 0px;
    /* border:1px solid black */

}
.navlinkPadding{
    padding: 8px;
}

}

@media screen and (max-width:1024px){


    .ResponsiveDownload{
        display:none;
    }

    .navbar_main{
        position:fixed;
        display:flex;
        justify-content:center;
        flex-direction:row;
        flex-wrap:wrap;
        width:100%;
        z-index:1000;
        background-color:#fff;
        padding:10px 0px 10px 90px;
        overflow: hidden;
    }

    .navbar_globe{
        width:90px
    }


.subNavStyle2_Landing{
    padding:10px 5px 10px 5px;
    font-weight:600;
    font-size:14px;
}

.subNavStyle_Landing{
    font-weight:600;
    font-size:14px;
    padding:5px 0px 5px 0px;
    margin-right: 0px;
}

 .subNavStyleHeader_Landing{
    padding:5px 0px 5px 0px;
    font-weight:900;
    font-size:15px;
    text-decoration: none;
}

.navlinkPadding{
    padding: 8px;
}

.logoSlash_nav_Landing{

    width:90%
}

.nav_logo_Landing{
    width: 10%;
    /* padding-top: 10px 8px 10px 8px; */
    margin: 0px;
    /* border:1px solid black */
}

.nav_bar_content_Landing{
    width: 25%;
    padding-top: 10px;
    /* border:1px solid black */
}

.navoptions_Landing{

    width: 83%;
    padding: 0px;
    /* border:1px solid black; */

}

.text-div_modal_Landing{
    width: 410px;
    height: auto;
    flex-grow: 0;
    position:absolute;
    left:65px;
    top:130px;
    text-align:left;
    /* border: 1px solid black; */

}

.text-div-info_modal_Landing {
    margin-bottom: 15px;
    padding-left: 4px;
    width: 100%;
    
}

.text-div-available_modal_Landing{
    margin-top: 40px;
    margin-bottom: 5px;
    padding-left: 4px;
    width: 68%;
    
}

.logo_div_modal_Landing{
    background-color:#3f2764;
    width:450px;
    height:250px;
    border-radius:50%;
    position:absolute;
    top:-130px;
    left:-100px;

}

.logo_image_modal_Landing {
    position:absolute;
    left:130px;
    bottom:25px;
    width:50%;
    background-color:transparent;
}

.main-text_modal_Landing {
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #261640;
}

.get-digital-real_Landing {
    flex-grow: 0;
    margin-top: 20px;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #1a0c2e;
    text-overflow: inherit;
}

.sendlink_input_div_Landing {
    margin-top: 40px;
    width: 80%;
}
.sendlink_input_div_SMS_Landing {
    width: 80%;
}

}


@media screen and (max-width:990px){



    .logoSlash_nav_Landing{

        width:100%
    }

    .navoptions_Landing{

        display: none;
        width: 0px;

    }
    .subNavStyle2_Landing{

       display: 'block';
    }
    .ResponsiveDownload{
        display: flex;
    }

    .navbar_globe{
        display: none;
    }

    .nav_bar_content_Landing{


        display: none;
        width: 0px;
    }

    .logoSlash_Landing{

        width:100%;

    }

    .nav_logo_Landing{
        width: 12%;
        margin: 0px;
        /* padding: 10px 8px 10px 8px */
        /* border:1px solid black */
    }

    .togglerNav-div_Landing{
        width:100%;
    }

    /* .subNavStyleHeader_Landing{
        padding:5px 0px 5px 0px;
        font-weight:900;
        font-size:16px;
        text-decoration: none;
    } */

    /* .nav_bar_content_Landing{
        width: 70%; */
        /* padding-top: 5px; */
        /* border:1px solid black */
    /* } */
    
    .navoptions_Landing{
    
        width: 20%;
        /* border:1px solid black */
    
    }

    .text-div_modal_Landing{
        width: 100%;
        height: auto;
        flex-grow: 0;
        position:relative;
        left:0;
        top:0;
        text-align:left;
        /* border: 1px solid black; */
        padding: 10px 40px 10px 40px;
    
    }
    
    .text-div-info_modal_Landing {
        margin-bottom: 15px;
        padding-left: 4px;
        width: 100%;
        
    }
    
    .text-div-available_modal_Landing{
        margin-top: 40px;
        margin-bottom: 5px;
        padding-left: 4px;
        width: 60%;
        
    }
    
    .logo_div_modal_Landing{
        background-color:#3f2764;
        width:400px;
        height:250px;
        border-radius:50%;

        position:relative;
        top:-130px;
        left:-100px;
    }
    
    .logo_image_modal_Landing {
        position:absolute;
        left:110px;
        bottom:25px;
        width:40%;
        background-color:transparent;
    }
    
    .main-text_modal_Landing {
        flex-grow: 0;
        font-family: Mulish;
        font-size: 20px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #261640;
    }
    
    .get-digital-real_Landing {
        flex-grow: 0;
        margin-top: 20px;
        font-family: Mulish;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #1a0c2e;
        text-overflow: inherit;
    }
    
    .sendlink_input_div_Landing {
        width: 100%;
    }

    .sendlink_input_div_SMS_Landing {
        width: 100%;
    }

    .modal_phone_image_Landing {
        display: none;
    }

    .modal_phone_image1_Landing {
        width: 100%;
        display: flex;
        /* border: 1px solid black; */
    }

    .modal_main_div_Landing{
        position:relative;
        width:100%;
        height:130px;
        text-align:right;
        overflow:hidden;
        /* border: 1px solid green; */
    }

}


@media screen and (max-width:768px){

    .navbar_main{
        position:fixed;
        display:flex;
        justify-content:center;
        flex-direction:row;
        flex-wrap:wrap;
        width:100%;
        z-index:1000;
        background-color:#fff;
        padding:10px 50px 10px 50px;
        overflow: hidden;
    }
    .UsLabel{

        display: none;
    
    }

    .nav_logo_Landing{
        width:15%;
        /* border:1px solid black */
    }

    .logoSlash_nav_Landing{

        width:90%
    }

    .cross_Landing{

        position: absolute;
        right:22px;
        top:18px
        /* padding: 5px; */
        /* margin-right: 25px; */
        /* display: flex; */
        /* border:1px solid black */

    }

    .nav_button_Landing{
        width: 7%;
        padding: 1px;
        margin-top: 3px;
        position: absolute;
        top: 10px;
        right: 10px;
        /* margin-left: 10px; */
        /* border:1px solid black */
    }
}

@media screen and (max-width:550px){

    html, body {
        overflow-x: hidden;
        }

        .logoSlash_nav_Landing{

            width:150%
        }
        .UsLabel{

            display: none;
        
        }
    

}

@media screen and (max-width:425px){

    .navbar_main{
        position:fixed;
        display:flex;
        justify-content:center;
        flex-direction:row;
        flex-wrap:wrap;
        width:100%;
        z-index:1000;
        background-color:#fff;
        padding:10px 10px 10px 10px;
        overflow: hidden;
    }
    .UsLabel{

        display: none;
    
    }


    .logoSlash_nav_Landing{

        width:85%
    }

    .cross_Landing{

        position: absolute;
        right:25px;
        top: 25px;

        height:20px;
        width:20px;
        /* padding: 5px; */
        /* margin-right: 25px; */
        /* display: flex; */
        /* border:1px solid black */

    }
        html, body {
        overflow-x: hidden;
        }
      

    .logoSlash_Landing{

        width:70%;
    }

    .togglerNav-div_Landing{
        width:100%;
    }

    .subNavStyle2_Landing{
        padding:10px 5px 10px 5px;
        font-weight:600;
        font-size:14px;
        width: 140px;
    }
    
    /* .subNavStyle_Landing{
        font-weight:600;
        font-size:14px;
        padding:5px 0px 5px 0px;
    } */
    
     /* .subNavStyleHeader_Landing{
        padding:5px 0px 5px 0px;
        font-weight:900;
        font-size:14px;
        text-decoration: none;
    } */
    .nav_logo_Landing{
        width: 25%;
        margin: 0px;
        padding: 5px 5px 5px 5px;
        border: 0px;
        /* border:1px solid black */
    }
    /* .nav_bar_content_Landing{
        width: 70%; */
        /* border:1px solid black */
    /* } */
    .nav_button_Landing{
        width: 12%;
        padding: 2px;
        margin-left: 0px;
        margin-right: 0px;
        /* border:1px solid black */
    }

    .cross_Landing{

        align-self: flex-end;


        height:20px;
        width:20px;
        /* padding: 5px; */
        /* margin-right: 25px; */
        /* display: flex; */
        /* border:1px solid black */

    }

    .cross_div_Landing{
        width: 15%;
        padding-left: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self:left;
        margin-left: 0px;
        margin-right: 0px;
        /* border:1px solid red; */
    }

    .main-text_modal_Landing {
        flex-grow: 0;
        font-family:Montserrat;
        font-size: 18px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #261640;
    }

    .text-div_modal_Landing{
        width: 100%;
        height: auto;
        flex-grow: 0;
        position:relative;
        left:0;
        top:0;
        text-align:left;
        /* border: 1px solid black; */
        padding: 10px 20px 10px 20px;
    
    }
    
    .skip_download_Landing{
        top:20px;
        right:25px;
        position:absolute;
        cursor:pointer;
    }
}

@media screen and (max-width:375px){

    .navbar_main{
        position:fixed;
        display:flex;
        justify-content:center;
        flex-direction:row;
        flex-wrap:wrap;
        width:100%;
        z-index:1000;
        background-color:#fff;
        padding:10px 10px 10px 10px;
        overflow: hidden;
    }
    .UsLabel{

        display: none;
    
    }


    .logoSlash_nav_Landing{

        width:100%
    }

    .cross_Landing{

        position: absolute;
        right:20px;
        top: 20px;
        /* left: auto; */

        height:20px;
        width:20px;
        /* padding: 5px; */
        /* margin-right: 25px; */
        /* display: flex; */
        /* border:1px solid black */

    }

    .navoptions_Landing{

        display: none;

    } 
    .logoSlash_Landing{

        width:50%;
    }

    .togglerNav-div_Landing{
        width:100%;
    }
    /* .subNavStyleHeader_Landing{
        padding:0px 0px 0px 0px;
        font-weight:900;
        font-size:12px;
        text-decoration: none;
    } */
    .nav_logo_Landing{
        width: 25%;
        margin: 0px;
        padding: 5px 5px 5px 5px;
        border:0px
        /* border:1px solid black */
    }
    .nav_bar_content_Landing{
        width: 50%;
        padding-top: 0px;
        /* border:1px solid black */
    }
    .nav_button_Landing{
        width: 12%;
        padding: 1px;
        margin-top: 3px;
        position: absolute;
        top: 10px;
        right: 10px;
        /* margin-left: 10px; */
        /* border:1px solid black */
    }

    .cross_div_Landing{
        width: 12%;
        padding-left: 25px; 
        display: flex;
        justify-content: center;
        align-items: center;
        align-self:left;
        margin-left: 0px;
        margin-right: 0px;
        /* background-color: red; */
        /* border:1px solid green; */
    }
    .skip_download_Landing{
        top:20px;
        right:15px;
        position:absolute;
        cursor:pointer;
    }
}




@media (max-width:320px){


    .ResponsiveDownload{
        display: block;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        
        /* padding: 0px 100px 10px 5px; */
        /* top:0; */
        right:70px;
      
    
    }
    .UsLabel{

        display: none;
    
    }


    .logoSlash_nav_Landing{

        width:100%;
    }

    .navoptions_Landing{

        display: none;
    
    }

    .logoSlash_Landing{

        width:80%;
        margin-left:0px;
        padding-top: 10px;

    }

    .togglerNav-div_Landing{
        width:100%;
    }

    
    .cross_Landing{

        position : absolute;
        right: 9px;
        height:20px;
        width:20px;
        /* padding: 5px; */
        /* margin-right: 25px; */
        /* display: flex; */
        /* border:1px solid black */

    }
    /* .subNavStyle_Landing{
        font-weight:600;
        font-size:12px;
        padding:5px 0px 5px 0px;
    } */
    
     .subNavStyleHeader_Landing{
        padding:0px 0px 0px 0px;
        font-weight:900;
        font-size:14px;
        text-decoration: none;
    }

    .nav_logo_Landing{
        width: 23%;
        margin: 0px;
        padding-top: 5px;
        /* border:1px solid black */
    }
    /* .nav_bar_content_Landing{
        width: 65%;
        padding-top: 0px; */
        /* border:1px solid black */
    /* } */
    .nav_button_Landing{
        width: 12%;
        padding: 1px;
        position: absolute;
        top: 10px;
        right: 10px;
        /* border:1px solid black */
    }


}