.status_div{
    width: 35px;
    min-height: 172px;
    flex-grow: 0;
    margin: 0px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    /* background-color: #fed019; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.status_div_mobile{

    width: 100%;
    min-height: 35px;
    flex-grow: 0;
    margin: 0px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    /* background-color: #fed019; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}

.mainDiv_second_col{
    padding: 18px;
    width: 100%;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    border: solid 1px #eee;
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;

}

.mainDiv_second_col_mobile{
    padding: 16px;
    width: 100%;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    border: solid 1px #eee;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.details_div_style{
    padding: 0px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
    height: 73px;
}

.detail_header_text_style{
    flex-grow: 0;
    /* margin: 4px 0px 4px 6px; */
    /* margin: 0px 0px 0px 6px; */
    padding: 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.category_imageStyle{
    height: 80px;
    width: 80px;
}

.detail_desc_text_style{
    flex-grow: 0;
    /* margin: 0px 0px 0px 6px; */
    padding: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.detail_highlighted_text{
    margin: 2px 0px 13px 4px;
    padding: 3px 0px 0px 0px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* color: #5ba85a; */
}

.details_price_pin_text{
    flex-grow: 0;
    margin: 0px 0px 0px 18px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    text-align: right;
    color: #8c52ff;
}

.booking_date_text{
    flex-grow: 0;
    margin: 0px 10px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* color: #8c52ff; */
}

.booking_time_text{
    flex-grow: 0;
    margin: 0px 0px 0px 6px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.cancel_booking_style{
    width: 124px;
    height: 35px;
    margin: 0 0 0 16px;
    /* padding: 10px 16px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: solid 1px #eee;
    background-color: rgba(229, 228, 228, 0.4);
    cursor: pointer;
}

.cancel_button_text{
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ff5d5d;
}

.booking_again_style{
    width: 125px;
    height: 35px;
    flex-grow: 0;
    margin: 0 0 0 16px;
    /* padding: 8px 16px 9px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #8c52ff;
}

.booking_again_text{
    margin: 0px 8px;
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.booking_cancel_modal_main_div{
    padding: 20px 16px;
    border-radius: 10px;
    background-color: #fff;
}

.booking_cancel_modal_first_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.booking_cancel_modal_second_div{
    padding: 15px 20px 20px;
    border-radius: 10px;
    background-color: #fff;
}

.booking_cancel_modal_headerTextStyle{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.rating_starContainer{
    border-radius: 3px;
    /* border-style: solid;
    border-width: 0.5px;
    border-color: #b8b8b8; */
    margin: 0 1px;
}

.booking_card_buttonStyle{
    /* border-radius: 10; */
    padding:0px;
    display: flex;
    justify-content:center;
    align-items:center;
    text-align: center;
    /* width:138; */
    height:45px;
    margin-top:28px;
    cursor: pointer;
}

.subHeaderTextStyle{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #747474;
}


@media screen and (max-width: 768px){

   
}

@media screen and (max-width: 425px){
    .detail_desc_text_style{
        /* margin: 4px 0px 5px 6px; */
        font-size: 10px;
    }

    .detail_highlighted_text{
        margin: 0 0px 0 6px;
        padding: 0 0;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        /* color: #5ba85a; */
    }

    .details_div_style{
        padding: 0px 12px;
        max-width: 160px;
    }
   
    .mainDiv_second_col{
        padding: 12px 8px;   
    } 

    .mainDiv_second_col_mobile{
        padding: 16px;
    
    }

    .detail_header_text_style{
        
        font-size: 12px;
    
    }

    .details_price_pin_text{
        font-size: 14px;
    }

    .booking_date_text{
        font-size: 10px;
        margin: 0px 6px;
    }

    .booking_time_text{
        margin: 0px 0px 0px 4px;
        font-size: 10px;
    }

    .category_imageStyle{
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 375px){
    .detail_desc_text_style{
        /* margin: 4px 0px 5px 6px; */
        font-size: 10px;
    }

    .details_div_style{
        padding: 0px 12px;
        max-width: 155px;
    }
   
    .mainDiv_second_col{
        padding: 12px 8px;   
    } 

    .mainDiv_second_col_mobile{
        padding: 16px;
    
    }

    .detail_header_text_style{
        
        font-size: 12px;
    
    }
}

@media screen and (max-width: 320px){

    .detail_desc_text_style{
        /* margin: 4px 0px 5px 6px; */
        font-size: 10px;
    }

    .details_div_style{
        padding: 0px 6px;
        max-width: 135px;
    }
   
    .mainDiv_second_col{
        padding: 12px 8px;   
    } 

    .mainDiv_second_col_mobile{
        padding: 16px;
    
    }

    .category_imageStyle{
        width: 60px;
        height: 60px;
    }

    .detail_header_text_style{
        
        font-size: 12px;
    
    }

    .details_price_pin_text{
        font-size: 12px;
    }
}