.navbar_navbar_main_mobile_style{
    position: fixed;
    bottom: 0;
    z-index: 100;
    /* padding: 20px 0px; */
    /* background-color: #ffffff; */
    flex-grow: 0;
    padding: 17px 15px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

}

.navbar_main_new_mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
}

.navItem_mobile_style{
    padding: 0px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar_navlink_mobile_style{
    text-decoration: none;
    font-family: Inter;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: #9f9f9f;
}

.navbar_navlink_mobile_style_active{
    text-decoration: none;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: #8c52ff;
}

@media screen and (max-width: 380px) {
    .navbar_navbar_main_mobile_style{
        padding: 17px 10px;
    }
    
}

@media screen and (max-width: 320px) {
    .navbar_navbar_main_mobile_style{
        padding: 17px 0px;
    }
    
}