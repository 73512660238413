.mainContainersv2{
    background-color: #fff;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    /* padding-bottom:20px; */
    /* width: 100%; */
}
.ProDetailsView_OnBanner{
    position:absolute;
    bottom:20px;
    width:82%;
    z-index: 10;
}
.imgStylesv2_Pro{
    opacity: 0.5;
    transition: opacity 500ms 0ms ease-in-out;
    width:100%;
    /* padding-left: 0px;
    padding-right: 0px; */
    height:350px;
}
.CarousalContainer_Sv2_Pro{
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height:450px; */
}
.proNameStylesv2_Pro{
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.addressTextsv2_Pro{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    text-align: left;
    color: #fff;
}
.distanceTextsv2_Pro{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding-left: 5px;
}
.sectionHeader_Text{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #747474;  
}
.cardsDiscRating_ProPage{
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    padding: 7px 10px;
    border-radius: 7px;
    border: solid 1px #dedede;
}
.cardsDiscRating_ProPage_Text{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* color: #000; */
    padding-left: 5px;
}
.serviceNameText_Web{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.descText_Servicecard_Web{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.08px;
  text-align: left;
  color: #9f9f9f;
}
.listPrice_ServiceCard_Web{
    font-family: 'Inter';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9f9f9f;
  text-decoration:line-through;
  text-decoration-color:#9f9f9f ;
}
.offerPrice_ServiceCard_Web{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5ba85a;  
}
.serviceDiscount_ServiceCard_Web{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
}
.durationText_ServiceCard_Web{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.08px;
    text-align: right;
    color: #9f9f9f;
}
.aboutSection_MainText{
    font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.aboutSection_SubText{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.timeText_Pro{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5ba85a;
}
.addressPro_Text{
    flex-grow: 0;
  font-family:'Inter';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.crossIcon_ViewPro{
    border-radius: 50px;
    width: 26px;
    height: 26px;
    flex-grow: 0;
    /* padding:4px; */
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.couponText_Latest{
    object-fit: contain;
  font-family: 'Inter';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff4d65;
}
.couponPer_DiscountText{
    object-fit: contain;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.08px;
    text-align: left;
    color: #fff;  
}
.openWithMap_Text{
    flex-grow: 0;
    margin: 0 0 0 4px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ff5d5d;
}
.openWithMaps_View{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    position: absolute;
    bottom: 20px;
    right:20px;
    border-radius: 8px;
    border: solid 1px rgba(255, 93, 93, 0.4);
    background-color: #fff;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
}
.openWithMap_Icon{
    width:14px;
    height:14px;
}

.redirectWebBanner{
    display: flex;
}

.redirectMobileBanner{
    display: none;
}

.modal-content{
    border:0;
    border-radius: 25px;
}

@media screen and (max-width:1240px){
    .ProDetailsView_OnBanner{
        bottom:20px;
        /* left:110px; */
    }
}
@media screen and (max-width:1024px){
    .ProDetailsView_OnBanner{
        bottom:20px;
        /* left:90px; */
    }
}

@media screen and (max-width:995px){
    .redirectWebBanner{
        display: none;
    }

    .redirectMobileBanner{
        display: flex;
    }
}

@media screen and (max-width:768px){
    .ProDetailsView_OnBanner{
        bottom:20px;
        /* left:50px; */
    }
    .serviceNameText_Web{
        font-size: 16px;
    }
    .descText_Servicecard_Web{
      font-size: 14px;
    }
    .listPrice_ServiceCard_Web{
      font-size: 14px;
    }
    .offerPrice_ServiceCard_Web{
        font-size: 14px;
    }
    .serviceDiscount_ServiceCard_Web{
        font-size: 14px;
    }
    .durationText_ServiceCard_Web{
        font-size: 14px;
    }
    .imgStylesv2_Pro{
        width:100%;
        height:350px;
    }
}
@media screen and (min-width:0px) and (max-width:760px){
    .openWithMap_Text{
        flex-grow: 0;
        margin: 0 0 0 4px;
        font-family: 'Inter';
        font-size: 10px;
    }
    .openWithMaps_View{
        bottom: 10px;
        right:10px;
    }
    .openWithMap_Icon{
        width:11px;
        height:11px;
    }
    .ProDetailsView_OnBanner{
        bottom:20px;
        /* left:0px; */
    }
    .proNameStylesv2_Pro{
        font-size: 20px;
    }
    .aboutSection_MainText{
      font-size: 14px;
    }
    .aboutSection_SubText{
        font-size: 12px;
    }
    .timeText_Pro{
        font-size: 12px;
    }
    .addressPro_Text{
        font-size: 12px;
    }
    .imgStylesv2_Pro{
        width:100%;
        height:250px;
    }
}