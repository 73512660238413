.loading_spinner_buttonStyle{
    border-radius:10px;
    /* padding-vertical:18, */
    width:auto;
    /* height:56px; */
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
}
.loading_spinner_textStyle{
    text-align: center;
    font-size: 16px;
    font-weight: "bold";
    font-style: "normal";
    letter-spacing: 0;
    margin: 0 5px;

}