.serviceCard_Sv2 {
    width: 100%;
    height: 160px;
    /* flex-grow: 0; */
    /* margin: 20px 0; */
    padding: 8px;
    border-radius: 10px;
    border: solid 1px #eee;
    background-color: #fff;
    /* justify-content: space-between; */
    display:flex;
    align-items: center;
    flex-direction: row;
}
.wishlistBg{
    width: 26px;
    height: 26px;
    flex-grow: 0;
    display:flex;
    align-items: center;
    /* margin: 0 0 29px 21px; */
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
}
.wishlistBg_Web{
    width: 26px;
    height: 26px;
    flex-grow: 0;
    display:flex;
    align-items: center;
    /* margin: 0 0 29px 21px; */
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
}
.serviceRatingWeb_View{
    width: 160px;
    height: 40px;
    /* flex-grow: 0; */
    display:flex;
    /* padding:5px; */
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 1px #ececec;
    background-color: #fff;
}
.addToCartButton{
    width: 238px;
    height: 35px;
    flex-grow: 0;
    /* margin: 10px 0 0 0; */
    padding: 10px;
    border-radius: 8px;  
    background-color:#8c52ff !important;
    color:#fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}

.ratingText_SCard{
    flex-grow: 0;
  /* margin: 2px 1px 1px 0; */
  font-family: 'Inter';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.ratingComponent {
    width: 62px;
    height: 35px;
    flex-grow: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    /* margin: 11px 14px 0 8px; */
    /* padding: 8px 13px 9px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 1px #ececec;
    background-color: #fff;
  }
.ADD-TO-CART {
    font-family: 'Inter';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  }
@media screen and (max-width:768px){
    .addToCartButton{
        width: 160px;
    }
    .ratingComponent {
        width: 50px;
        height: 35px;
    }
}
.serviceNameText {
    flex-grow: 0;
    width:150px;
    flex-wrap: wrap;
    /* margin: 0 0 2px 16px; */
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.descServiceCard{
  flex-grow: 0;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.listPrice_ServiceCard{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b8b8b8;
  text-decoration:line-through;
  text-decoration-color:#9f9f9f ;

}
.offerPrice_ServiceCard{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5ba85a;  
}
.serviceDiscount_ServiceCard{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
}
.durationService_Card{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #747474;

}
.addToCartButton {
    width: 138px;
    /* height: 35px; */
    flex-grow: 0;
    /* margin: 10px 0 0 0; */
    padding: 10px;
    border-radius: 8px;
    background-color: #8c52ff !important;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ADD-TO-CART {
    flex-grow: 0;
    opacity: 0.9;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
@media screen and (max-width:360px){
    .serviceCard_Sv2 {
        width: 100%;
        height: 160px;
        padding: 8px;
        border-radius: 10px;
        border: solid 1px #eee;
        background-color: #fff;
        display:flex;
        align-items: center;
        flex-direction: row;
    }
    .addToCartButton {
        width: 110px;
    }
    .serviceNameText {
        font-size: 14px;
    }
    .descServiceCard{
      font-size: 10px;
    }
    .listPrice_ServiceCard{
      font-size: 12px;
    }
    .offerPrice_ServiceCard{
        font-size: 12px;
    }
    .serviceDiscount_ServiceCard{
        font-size: 10px;
    }
    .durationService_Card{
      font-size: 10px;
    }
}