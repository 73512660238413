.CouponButton_DivStyle{
    /* border-radius:10px; */
    padding:16px;
    background: linear-gradient(to right,  #461c99, #8c52ff, #ff5d5d);
    margin:0px 0px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    cursor: pointer;
}

.CouponButton_TextStyle{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    color: #ffffff;
    padding:0px 8px;
  }

  @media screen and (max-width: 425px){

    .CouponButton_TextStyle{
        font-size: 12px;
      }

  }