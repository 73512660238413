.mainDiv{
    flex-direction: row;
    display: flex;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
}
.privacy{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.subtextsetting{
  opacity: 0.7;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;

}
.deleteText{
  opacity: 0.7;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.subtextOption{
  opacity: 0.7;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;

}
.crossdivSetting{
  /* flexDirection:'row',justifyContent:'start',marginLeft:20,ma */
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  margin-left: 20px;
  margin-top: 30px;

  
}
.deleteText {
  /* fontFamily: "Inter",
  fontSize: 14,
  fontWeight: "600",
  fontStyle: "normal",
  letterSpacing: 0,
  color: "#212529", */

  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  color: #212529;
}
.deleteSubtext{
  /* fontFamily: "Inter",
  fontSize: 12,
  fontWeight: "normal",
  fontStyle: "normal",
  letterSpacing: 0,
  color: "#646970", */
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  color:#646970;
padding-bottom: 10px;
padding-top: 10px;
padding-right: 10px;

}