.mainHedder {
    flex-grow: 0;
    font-family: Inter;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* text-align: left; */
    color: #1c1b1f;
    margin-left: 67px;
    margin-top: 30px;
}

.test {
    width: 151px;
    height: 19px;
    margin: 44px 85px 10px 152px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #461c99;
}

.line {
    width: 180px;
    height: 4px;
    /* margin: 11.5px 138px 43.5px; */
    margin-left: 130px;
    opacity: 0.6;
    background-color: #8c52ff;
}

.subNavStyle {
    color: #261640 !important;
    padding: 5px;
    border-radius: 3px;
    text-decoration: none !important;
    font-weight: 600;
    font-size: 18px;
    font-family: Mulish;

}

.termsdiv {
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: -53px;

}

.termText {
    /* flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', */
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    /* cursor: pointer; */
}
.maindivline{
    /* width: '100%', height: 2, backgroundColor: '#d4d0f6' */
    width: auto;
    height: 2px;
    background-color:#d4d0f6 ;
}
@media screen and (max-width:1440px){
   
  
    .line {
        width: 180px;
        height: 4px;
        /* margin: 11.5px 138px 43.5px; */
        margin-left: 130px;
        opacity: 0.6;
        background-color: #8c52ff;
    }
    

.maindivline{
    /* width: '100%', height: 2, backgroundColor: '#d4d0f6' */
    width: auto;
    height: 2px;
    margin-top: -4px;
    background-color:#d4d0f6 ;
}

}
@media screen and (max-width:1024px){
   
  
    .line {
        width: 180px;
        height: 4px;
        /* margin: 11.5px 138px 43.5px; */
        margin-left: 130px;
        opacity: 0.6;
        background-color: #8c52ff;
    }
    

.maindivline{
    /* width: '100%', height: 2, backgroundColor: '#d4d0f6' */
    width: auto;
    height: 2px;
    margin-top: -4px;
    background-color:#d4d0f6 ;
}

}
@media screen and (max-width:768px){
   
  
    .line {
        width: 180px;
        height: 4px;
        /* margin: 11.5px 138px 43.5px; */
        margin-left: 130px;
        opacity: 0.6;
        background-color: #8c52ff;
    }
    

.maindivline{
    /* width: '100%', height: 2, backgroundColor: '#d4d0f6' */
    width: auto;
    height: 2px;
    margin-top: -4px;
    background-color:#d4d0f6 ;
}

}
@media screen and (max-width:425px){
    .termsdiv {
        flex-direction: row;
        justify-content: flex-start;
        display: flex;
        margin-bottom: -53px;
    
    }
    .termText {
        /* flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', */
        flex-direction: column; 
        justify-content: flex-start;
        display: flex;
    }
    .line {
    width: 150px;
    height:2px;
    margin: 11.5px 18px 3.5px;
    margin-left: 12px;
    opacity: 0.6;
    background-color: #8c52ff;
}
.test {
    /* width: 151px; */
    /* height: 19px; */
    margin: 20px 5px 0px 22px;
    font-size: 16px;

}
.mainHedder {
    font-size: 20px;
    margin-left: 10px;
}
.maindivline{
    /* width: '100%', height: 2, backgroundColor: '#d4d0f6' */
    width: auto;
    height: 2px;
    margin-top: -6px;
    background-color:#d4d0f6 ;
}

}
@media screen and (max-width:375px){
    .termsdiv {
        flex-direction: row;
        justify-content: flex-start;
        display: flex;
        margin-bottom: -53px;
    
    }
    .termText {
        /* flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', */
        flex-direction: column; 
        justify-content: flex-start;
        display: flex;
    }
    .line {
    width: 130px;
    height:3px;
    margin: 11.5px 18px 3.5px;
    margin-left: 10px;
    opacity: 0.6;
    background-color: #8c52ff;
}
.test {
    /* width: 151px; */
    /* height: 19px; */
    margin: 20px 5px 0px 22px;
    font-size: 12px;

}
.mainHedder {
    font-size: 20px;
    margin-left: 10px;
}
.maindivline{
    /* width: '100%', height: 2, backgroundColor: '#d4d0f6' */
    width: auto;
    height: 2px;
    margin-top: -6px;
    background-color:#d4d0f6 ;
}

}
@media screen and (max-width:325px){
    .termsdiv {
        flex-direction: row;
        justify-content: flex-start;
        display: flex;
        margin-bottom: -53px;
    
    }
    .termText {
        /* flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', */
        flex-direction: column; 
        justify-content: flex-start;
        display: flex;
    }
    .line {
    width: 130px;
    height:3px;
    margin: 11.5px 18px 3.5px;
    margin-left: 12px;
    opacity: 0.6;
    background-color: #8c52ff;
}
.test {
    /* width: 151px; */
    /* height: 19px; */
    margin: 20px 5px 0px 22px;
    font-size: 12px;

}
.mainHedder {
    font-size: 20px;
    margin-left: 10px;
}
.maindivline{
    /* width: '100%', height: 2, backgroundColor: '#d4d0f6' */
    width: auto;
    height: 2px;
    margin-top: -6px;
    background-color:#d4d0f6 ;
}

}