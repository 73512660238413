.blogText{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 0.3px;
    color: #fff;
}
.blogText2{
    /* margin: 4px 382px 66px 0; */
    object-fit: contain;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.08px;
    text-align: left;
    color: #fff;

}
.corosol_div{
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 10px;
    /* padding-bottom:20px; */
    /* width: 100%; */
}
.CarousalContainer_Sv2_Blog{
    padding-left: 0px;
    padding-right: 0px;
    /* height:450px; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
.imgStylesv2_Blog{
    width:100%;
    opacity: 0.5;
    transition: opacity 500ms 0ms ease-in-out;
    /* padding-left: 0px;
    padding-right: 0px; */
    height:350px;
}
.trendImage{
    
    display: flex;
    width: 100%;
    height: 273px;
    flex-direction: column;

}


.b_optionsdiv{

    display: flex;
    width: 15%;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2%;
    border-radius: 10px;
    background-color: #fff;
    border: solid  1px #e8e8e8;
    font-family: Inter;
    font-weight: 600;
    padding-left: 4px;
    padding-right: 4px;
    height: 600px;
}

@media screen and (min-width:0px) and (max-width:760px){
    .imgStylesv2_Blog{
        width:100%;
        opacity: 0.5;
        transition: opacity 500ms 0ms ease-in-out;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
}



@media screen and (min-width:1024px) and (max-width:1300px){

    .b_optionsdiv{
    
        width:22%
    }
    
    .trendImage{
    
        
        height: 191px;
       
    }
    
        }



@media screen and (min-width:768px) and (max-width:1023px){

.b_optionsdiv{

    width:22%
}

.trendImage{

    
    height: 137px;

}

    
}


