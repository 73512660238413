.mybooking_main_div_style{
    margin-top: 0px;
}

.mybooking_navbar_style{
    border-bottom:1px solid #b8b8b8;
    margin: 44px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* padding-bottom: 11px; */
}

.mybooking_navbarItem_style{
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mybooking_navbarItem_active_style{
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #8c52ff;
    border-bottom:2px solid #8c52ff;
}

ul.nav a:hover { color: #8c52ff !important; }

.mybooking_header_text_style{
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1b1f;
}

.mybooking_navItem_active_style{
    /* margin: 0px 0px 11px 0px; */
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    padding: 11px;
    /* border-bottom: 2px solid #8c52ff; */
}

.mybooking_navItem_inactive_style{
    /* margin: 0px 0px 11px 0px; */
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
    padding: 11px;
}

.mybooking_header_div_style{
    display: flex;
}

@media screen and (max-width: 1024px){

}

@media screen and (max-width: 768px){

    .mybooking_navbarItem_style{
        width: 180px;
    }
    
    .mybooking_navbarItem_active_style{
        width: 180px;
    }
}

@media (min-width: 425px) and (max-width: 500px){

    .mybooking_main_div_style{
        margin-top: 0px;
    }

    .mybooking_header_div_style{
        display: none;
    }

    .mybooking_navbar_style{
        border-bottom:1px solid #b8b8b8;
        margin: 0 0 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        /* padding-bottom: 11px; */
    }

    .mybooking_navItem_active_style{
        margin: 0px 0px 11px 0px;
        font-size: 14px;
        color: #8c52ff;
    }

    .mybooking_navItem_inactive_style{
        margin: 0px 0px 11px 0px;
        font-size: 14px;
        color: #747474;
    }

    .mybooking_header_text_style{
        font-size: 20px;
        color: #1c1b1f;
    }

    .mybooking_navbarItem_style{
        width: 130px;
    }
    
    .mybooking_navbarItem_active_style{
        width: 130px;
    }
}

@media screen and (max-width: 425px){

    .mybooking_main_div_style{
        margin-top: 0px;
    }

    .mybooking_header_div_style{
        display: none;
    }

    .mybooking_navbar_style{
        border-bottom:1px solid #b8b8b8;
        margin: 0 0 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        /* padding-bottom: 11px; */
    }

    .mybooking_navItem_active_style{
        margin: 0px 0px 11px 0px;
        font-size: 14px;
        color: #8c52ff;
    }

    .mybooking_navItem_inactive_style{
        margin: 0px 0px 11px 0px;
        font-size: 14px;
        color: #747474;
    }

    .mybooking_header_text_style{
        font-size: 20px;
        color: #1c1b1f;
    }

    .mybooking_navbarItem_style{
        width: 120px;
    }
    
    .mybooking_navbarItem_active_style{
        width: 120px;
    }
}

@media screen and (max-width: 385px){

    .mybooking_navbarItem_style{
        width: 110px;
    }
    
    .mybooking_navbarItem_active_style{
        width: 110px;
    }
}

@media screen and (max-width: 375px){

    .mybooking_navbarItem_style{
        width: 105px;
    }
    
    .mybooking_navbarItem_active_style{
        width: 105px;
    }
}

@media screen and (max-width: 320px){

    .mybooking_navItem_active_style{
        margin: 0px 25px 11px 0px;
        font-size: 12px;
        color: #8c52ff;
    }

    .mybooking_navItem_inactive_style{
        margin: 0px 25px 11px 0px;
        font-size: 12px;
        color: #747474;
    }

    .mybooking_navbarItem_active_style{
        width: 96px;
    }

    .mybooking_navbarItem_style{
        width: 96px;
    }
}