.proUnderCat_sv2{
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 10px;
    /* padding-bottom:20px; */
    /* width: 100%; */
}
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border:none !important;
}
.imageCatStyle_puc{
    width: 46px;
    height: 46px;
    flex-grow: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    /* margin: 0 16px 0 0; */
    /* padding: 10px 13.6px 11px 13px; */
    border-radius: 10px;
    border: 1px solid #fff;
    /* background-color: #ffb884; */
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
}
.catdiv_sv2::-webkit-scrollbar {
    display: none;
  }
  
.searchDropDowntextStyle_puc{
    flex-grow: 0;
    margin: 0 0 2px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.searchDropDownSubtextStyle_puc{
    flex-grow: 0;
  /* margin: 2px 50px 0 0; */
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.no_pro_style{
    font-family: 'Inter';
    font-size: 16px;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;

}
.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border:none !important;
}
.proCardWidth_sv2{
    display:inline-block;
    margin-top:20px;
    margin-bottom:20px;
    margin-left:0px;
    margin-right:30px;
    justify-content: center;
}
.searchBar_style_sv2{
    /* padding-right:80; */
    width:826px;
    margin-top:40px;
    position:relative;
    margin-bottom:20px;
    /* margin-top:40px; */
}
.imgStylesv2_puc{
    width:100%;
    opacity:0.5;
    transition: opacity 500ms 0ms ease-in-out;
    /* padding-left: 0px;
    padding-right: 0px; */
    height:350px;
}
.CarousalContainer_Sv2_puc{
    padding-left: 0px;
    padding-right: 0px;
    /* height:450px; */
    display: flex;
    background: linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%);
    align-items: center;
    justify-content: center;
}
.altTextSv2{
    position:absolute;
    bottom:20px;
    left:90px;
    right:90px;
    justify-content: space-between;
}
.proNameStylesv2{
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.addressTextsv2{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    text-align: left;
    color: #fff;
}
.textStyle_sv2{
    position:absolute;
    bottom:20px;
    left:90px;
    right:90px;
    justify-content: space-between;
}
.serviceText_sv2{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 0.3px;
    text-align: left;
    color: #fff;
}
.serviceTextDesc_sv2{
    /* margin: 4px 382px 66px 0; */
    object-fit: contain;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
    font-family: 'Inter';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.08px;
    text-align: left;
    color: #fff;

}
.catmain_sv2{
    width: 40px;
    height: 40px;
    flex-grow: 0;
    /* margin: 0 12px 0 0; */
    /* padding: 11px; */
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: rgb(103, 52, 204) ;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 6px;
}
.catImg_sv2{
    width: 22px;
    height: 22px;
    flex-grow: 0;
    object-fit: contain;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    /* border: solid 1px #fff; */
    /* filter:red; */

}
.AllServices_sv2{
    /* flex-grow: 0; */
    /* margin: 12px 0 13px 12px; */
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-left: 6px;
    color: #000;

}
.catdiv_sv2{
    display:flex;
    flex-direction:row;
    align-content: center;
    white-space:nowrap;
    overflow-x: scroll;
    justify-content: space-between;
    /* margin-bottom: 40px; */
}
.subdiv_sv2{
    padding: 30px 129px 25px 129px;
}
.filterBox-sv2{
    width: 104px;
    height: 38px;
    flex-grow: 0;
    display: flex;
    /* flex-direction: row; */
    justify-content:space-between;
    align-items: center;
    /* gap: 10px; */
    padding: 10px;
    border-radius: 7px;
    border-width: 1px;
    border-style: solid;
    /* border: solid 1px #dedede; */
    margin-right: 44px;
  
}

.ratingsBox-sv2{
    width: 90px;
    height: 38px;
    flex-grow: 0;
    display: flex;
    /* flex-direction: row; */
    justify-content:space-between;
    align-items: center;
    /* gap: 10px; */
    padding: 10px;
    border-radius: 7px;
    border-width:1px;
    border-style: solid;
    /* border: solid 1px #dedede; */
    margin-right: 44px;
  
}
.discountsBox-sv2{
    width: 108px;
    height: 38px;
    flex-grow: 0;
    display: flex;
    /* flex-direction: row; */
    justify-content:space-between;
    align-items: center;
    /* gap: 10px; */
    padding: 10px;
    border-radius: 7px;
    border-width:1px;
    border-style: solid;
    /* border: solid 1px #dedede; */
    margin-right: 44px;
  
}
.filtersText_sv2{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.img_sv2{
    width: 17px;
    height: 17px;
    opacity: 0.7;
    /* border: solid 1px #fff; */
}
.subcat_div_sv2{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    width: 108px;
    height: 108px;
    border-radius: 54px;
    /* margin-right: 15px; */
}
.sub-catimg_sv2{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50px;
}
.sub-row-sv2{
    display:flex;
    flex-direction:row;
    align-content: center;
    white-space:nowrap;
    overflow-x: scroll;
    padding-bottom: 20px;
    padding-top: 10px;
    /* padding-left: 5px; */
    /* padding-right: 10px; */
    /* justify-content: space-between; */
}
.sub-row-sv2::-webkit-scrollbar{
    display:none;
}
.sub_name_sv2{
    flex-grow: 0;
    /* margin: 2px 0 0; */
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    display: flex;
    /* letter-spacing: normal; */
    text-align: center;
    /* color: #7db4ff; */
    /* margin-top: 10px; */
}
.proCards_style_sv2{
    display: flex;
    justify-Content: 'space-between'; 
    align-items: 'center';
}
.filters_body_style{
    width: 30px;
    height: 30px;
    flex-grow: 0;
    border-radius: 15px;
    /* margin: 0 0 28px 329px; */
    padding: 6px 5.3px 6px 6px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.filtersText_sv2_text{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;

}
.hr_line_sv2{
    width: 416px;
    height: 1px;
    /* margin: 19.5px 0 22.5px 1px; */
    /* transform: rotate(-270deg); */
    background-color: var(--brownish-grey-40);
}
@media screen and (max-width:1440px){
    .serviceText_sv2{
        flex-grow: 0;
        font-family: 'Inter';
        font-size:60px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
    }
    .serviceTextDesc_sv2{
        /* margin: 4px 382px 66px 0; */
        object-fit: contain;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
        font-family: 'Inter';
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.08px;
        text-align: left;
        color: #fff;
    
    }
    .searchBar_style_sv2{
        /* padding-right:80; */
        width:826px;
        margin-top:40px;
        margin-bottom: 20px;
        position:'relative';
    }
    .subdiv_sv2{
        padding: 30px 129px 25px 129px;
    }
    .filterBox-sv2{
        width: 104px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .ratingsBox-sv2{
        width: 90px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .discountsBox-sv2{
        width: 108px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .filtersText_sv2{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        /* text-align: left; */
    }    
    
    .img_sv2{
        width: 17px;
        height: 17px;
        opacity: 0.7;
        /* border: solid 1px #fff; */
    }
    .imgStylesv2_puc{
        width:100%;
        opacity:0.5;
        transition: opacity 500ms 0ms ease-in-out;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:350px;
    }
   
}
@media screen and (max-width:1240px){
    .serviceText_sv2{
        flex-grow: 0;
        font-family: 'Inter';
        font-size:50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
    }
    .serviceTextDesc_sv2{
        /* margin: 4px 382px 66px 0; */
        object-fit: contain;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
        font-family: 'Inter';
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.08px;
        text-align: left;
        color: #fff;
    
    }
    .subdiv_sv2{
        padding: 30px 110px 25px 110px;
    }
    .searchBar_style_sv2{
        /* padding-right:80; */
        width:726px;
        margin-bottom: 20px;
        margin-top:40px;
        position:'relative';
    }
    .filterBox-sv2{
        width: 104px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .ratingsBox-sv2{
        width: 90px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .discountsBox-sv2{
        width: 108px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .filtersText_sv2{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        /* text-align: left; */
    }  
    .img_sv2{
        width: 17px;
        height: 17px;
        opacity: 0.7;
        /* border: solid 1px #fff; */
    }
    .imgStylesv2_puc{
        width:100%;
        opacity:0.5;
        transition: opacity 500ms 0ms ease-in-out;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:350px;
    }
}
@media screen and (max-width:1024px){
    .serviceText_sv2{
        flex-grow: 0;
        font-family: 'Inter';
        font-size:48px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
    }
    .serviceTextDesc_sv2{
        /* margin: 4px 382px 66px 0; */
        object-fit: contain;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
        font-family: 'Inter';
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.08px;
        text-align: left;
        color: #fff;
    
    }
    .subdiv_sv2{
        padding-left:100px;
        padding-right:100px;
    }
    .searchBar_style_sv2{
        /* padding-right:80; */
        width:526px;
        margin-bottom: 20px;
        margin-top:40px;
        position:'relative';
    }
    .filterBox-sv2{
        width: 104px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .ratingsBox-sv2{
        width: 90px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .discountsBox-sv2{
        width: 108px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .img_sv2{
        width: 17px;
        height: 17px;
        opacity: 0.7;
        /* border: solid 1px #fff; */
    }
    .filtersText_sv2{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        /* text-align: left; */
    }   
}
@media screen and (max-width:768px){
    .serviceText_sv2{
        flex-grow: 0;
        font-family: 'Inter';
        font-size:32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
    }
    .serviceTextDesc_sv2{
        /* margin: 4px 382px 66px 0; */
        object-fit: contain;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
        font-family: 'Inter';
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.08px;
        text-align: left;
        color: #fff;
    
    }
    .imgStylesv2_puc{
        width:100%;
        opacity:0.5;
        transition: opacity 500ms 0ms ease-in-out;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:350px;
    }
    .subdiv_sv2{
        padding-left:90px;
        padding-right:90px;
    }
    .searchBar_style_sv2{
        width:360px;
        margin-bottom: 20px;
        margin-top:40px;
        position:'relative';
    }
    .catdiv_sv2{
        display:flex;
        flex-direction:row;
        align-items: center;
        /* white-space:nowrap; */
        overflow-x: scroll;
        justify-content: space-between;
        /* margin-bottom: 40px; */
    }
    .filterBox-sv2{
        width: 104px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        /* border-width:1px; */
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .ratingsBox-sv2{
        width: 90px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .discountsBox-sv2{
        width: 108px;
        height: 38px;
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border-width:1px;
        border-style: solid;
        /* border: solid 1px #dedede; */
        margin-right: 44px;
      
    }
    .filtersText_sv2{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        /* text-align: left; */
    }   
    .img_sv2{
        width: 17px;
        height: 17px;
        opacity: 0.7;
        /* border: solid 1px #fff; */
    }
}
 