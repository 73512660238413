.proUnderCat_mobile_sv2{
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 10px;
    /* padding-bottom:20px; */
    /* width: 100%; */
}
.serviceText_mobile_sv2{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 0.3px;
    text-align: left;
    /* color: #fff; */
}
.no_pro_style_mobile{
    font-family: 'Inter';
    font-size: 10px;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.catCard_mobile{
    height:200px;
    width:200px;
    overflow:scroll;
    /* padding-left:10;
    padding-right:10;
    padding-top:16; */
    background-color:white;
    box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: none;
}
.catmain_sv2_mobile{
    width: 24px;
    height: 24px;
    flex-grow: 0;
    /* margin: 0 12px 0 0; */
    /* padding: 11px; */
    border-radius: 15px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background-color: rgb(103, 52, 204) ;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 6px;
}
.serviceTextDesc_mobile_sv2{
    /* margin: 4px 382px 66px 0; */
    object-fit: contain;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.08px;
    text-align: left;
    /* color: #fff; */

}

.subdiv_mobile_sv2{
    padding: 28px 16px 28px 16px;
}
.cat_img_style_mobile{
    width: 12px;
    height:12px;
    /* height: 24px;
    flex-grow: 0; 
    border-radius: 12px;
    margin: 0 8px 0 0;
    padding: 4.5px 4.1px 4.5px 4.1px; 
    border: solid 0.5px #eee; 
    background-color: #fff; */
}
.catname_mobile_sv2{
    margin: 0 8px;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.CarousalContainer_Sv2_mobile{
    padding-left: 0px;
    padding-right: 0px;
    /* height:450px; */
    display: flex;
    background: linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%);
    align-items: center;
    justify-content: center;
}
.dropdown_mobile_sv2{
    width: 24px;
    height: 24px;
}
.filters_body_style_mobile{
    width: 20px;
    height: 20px;
    flex-grow: 0;
    border-radius: 10px;
    /* margin: 0 0 28px 329px; */
    padding: 6px 5.3px 6px 6px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cat_modal_img_mobile{
    height: 12px;
    width: 12px;
    /* flex-grow: 0;
    border-radius: 12px;
    padding: 4.5px 4.1px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15); */
    /* margin-right:8px;   */

}
.catName_modal_style{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.filterBox-sv2_mobile{
    /* width: 93px; */
    /* height: 38px; */
    flex-grow: 0;
    display: flex;
    /* flex-direction: row; */
    justify-content:space-between;
    align-items: center;
    /* gap: 10px; */
    padding: 7px;
    border-radius: 7px;
    border:solid;
    /* border: solid 1px #dedede; */
    margin-right: 25px;
}
.ratingsBox-sv2_mobile{
    /* width: 90px; */
    /* height: 38px; */
    flex-grow: 0;
    display: flex;
    /* flex-direction: row; */
    justify-content:space-between;
    align-items: center;
    /* gap: 10px; */
    padding: 10px;
    border-radius: 7px;
    border:solid;
    /* border: solid 1px #dedede; */
    margin-right: 25px;
}
.discountsBox-sv2_mobile{
    /* width: 100px; */
    /* height: 38px; */
    flex-grow: 0;
    display: flex;
    /* flex-direction: row; */
    justify-content:space-between;
    align-items: center;
    /* gap: 10px; */
    padding: 10px;
    border-radius: 7px;
    /* border: solid 1px #dedede; */
    border:solid;
    margin-right: 25px;
}
.filtersText_sv2_mobile{
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.sub-row-sv2_mobile{
    display:flex;
    flex-direction:row;
    align-content: center;
    white-space:nowrap;
    overflow-x: scroll;
    padding-bottom: 20px;
    padding-top: 10px;
    /* padding-left: 5px; */
    /* padding-right: 10px; */
    /* justify-content: space-between; */
}
.sub-row-sv2_mobile::-webkit-scrollbar{
    display: none;
}
.subcat_div_sv2_mobile{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    /* margin-right: 15px; */
}
.sub-catimg_sv2_mobile{
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 35px;
}
.sub_name_sv2{
    flex-grow: 0;
    /* margin: 2px 0 0; */
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    display: flex;
    /* letter-spacing: normal; */
    text-align: center;
    /* color: #7db4ff; */
    /* margin-top: 10px; */
}
@media screen and (max-width:425px){
    .serviceText_mobile_sv2{
        flex-grow: 0;
        font-family: 'Inter';
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
    }
    .serviceTextDesc_mobile_sv2{
        /* margin: 4px 382px 66px 0; */
        object-fit: contain;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.08px;
        text-align: left;
        color: #fff;
    
    }
    .imgStylesv2{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .subdiv_mobile_sv2{
        padding: 28px 16px 28px 16px;
    }
    .searchBar_style_sv2{
        width:360px;
        margin-bottom: 28px;
    }
    .catdiv_sv2{
        display:flex;
        flex-direction:row;
        align-items: center;
        /* white-space:nowrap; */
        overflow-x: scroll;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .filterBox-sv2{
        width: 104px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .ratingsBox-sv2{
        width: 90px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .discountsBox-sv2{
        width: 108px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .filtersText_sv2{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        /* text-align: left; */
    }   
    .img_sv2{
        width: 17px;
        height: 17px;
        opacity: 0.7;
        /* border: solid 1px #fff; */
    }
}
@media screen and (max-width:375px){
    .serviceText_mobile_sv2{
        flex-grow: 0;
        font-family: 'Inter';
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
    }
    .serviceTextDesc_mobile_sv2{
        /* margin: 4px 382px 66px 0; */
        object-fit: contain;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.08px;
        text-align: left;
        color: #fff;
    
    }
    .imgStylesv2{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .subdiv_mobile_sv2{
        padding: 28px 16px 28px 16px;
    }
    .searchBar_style_sv2{
        width:360px;
        margin-bottom: 28px;
    }
    .catdiv_sv2{
        display:flex;
        flex-direction:row;
        align-items: center;
        /* white-space:nowrap; */
        overflow-x: scroll;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .filterBox-sv2{
        width: 104px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .ratingsBox-sv2{
        width: 90px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .discountsBox-sv2{
        width: 108px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .filtersText_sv2{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        /* text-align: left; */
    }   
    .img_sv2{
        width: 17px;
        height: 17px;
        opacity: 0.7;
        /* border: solid 1px #fff; */
    }
}

@media screen and (max-width:320px){
    .serviceText_mobile_sv2{
        flex-grow: 0;
        font-family: 'Inter';
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: 0.3px;
        text-align: left;
        color: #fff;
    }
    .serviceTextDesc_mobile_sv2{
        /* margin: 4px 382px 66px 0; */
        object-fit: contain;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.43);
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.08px;
        text-align: left;
        color: #fff;
    
    }
    .imgStylesv2{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .subdiv_mobile_sv2{
        padding: 28px 16px 28px 16px;
    }
    .searchBar_style_sv2{
        width:360px;
        margin-bottom: 28px;
    }
    .catdiv_sv2{
        display:flex;
        flex-direction:row;
        align-items: center;
        /* white-space:nowrap; */
        overflow-x: scroll;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .filterBox-sv2{
        width: 104px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .ratingsBox-sv2{
        width: 90px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .discountsBox-sv2{
        width: 108px;
        /* height: 38px; */
        flex-grow: 0;
        display: flex;
        /* flex-direction: row; */
        justify-content:space-between;
        align-items: center;
        /* gap: 10px; */
        padding: 10px;
        border-radius: 7px;
        border: solid 1px #dedede;
        margin-right: 25px;
      
    }
    .filtersText_sv2{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        /* text-align: left; */
    }   
    .img_sv2{
        width: 17px;
        height: 17px;
        opacity: 0.7;
        /* border: solid 1px #fff; */
    }
}
 