.cardView_ProMap{
    flex-grow: 0;
    padding: 18px;
    border-radius: 15px;
    box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    height: 190px;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
}
.cardImg_ProMap{
    width: 300px;
    height: 160px;
    flex-grow: 0;
    border-radius: 15px;
}
.wishlistBg_ProMap{
    width: 26px;
    height: 26px;
    flex-grow: 0;
    display:flex;
    align-items: center;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
}
.proName_ProMap{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.proAddress_ProMap{
    flex-grow: 0;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.distanceText_ProMap{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #747474;
}
.serviceCount_Btn_Map{
    width:138px;
    height: 35px;
    flex-grow: 0;
    opacity: 0.65;
    border-radius: 18px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: #8c52ff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.serviceCount_Btn_Text{
    flex-grow: 0;
  opacity: 0.9;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.box_ViewMap{
    position:absolute;
    bottom: 20px;
    left: 20px;
    right:20px;
}
/* Mobile class names */
.card_ProMap_Mobile{
    position: relative;
    width:100%;
    height: 150px;
    flex-grow: 0;
    /* padding: 20px 20px 16px; */
    border-radius: 10px;
    border: solid 1px #b8b8b8;
}
.cardImg_ProMap_Mobile{
    width:100%;
    height: 150px;
    border-radius: 10px;
}
.box_ViewMapMobile{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    left:0px;
    right:0px;
    bottom:0px;
    position: absolute;
    padding: 25px 20px 20px;
    box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;  
}
.serviceCount_Btn_Map_Mob{
    width: 138px;
  height: 35px;
  flex-grow: 0;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width:1240px){
    .proName_ProMap{
      font-size: 20px;
    }
    .proAddress_ProMap{
      font-size: 12px;
    }
    .distanceText_ProMap{
        font-size: 12px;
    }
    .serviceCount_Btn_Text{
      font-size: 12px;
    }
}
@media screen and (max-width:1024px){
    .cardImg_ProMap{
        width: 230px;
    }
    .proName_ProMap{
      font-size: 20px;
    }
    .proAddress_ProMap{
      font-size: 12px;
    }
    .distanceText_ProMap{
        font-size: 12px;
    }
    .serviceCount_Btn_Map{
        width:120px;
    }
    .serviceCount_Btn_Text{
      font-size: 12px;
    }
}
@media screen and (max-width:768px){
    .cardImg_ProMap{
        width: 150px;
        height: 150px;
    }
    .cardView_ProMap{
        height: 170px;
        padding: 10px;
    }
    .proName_ProMap{
      font-size: 16px;
    }
    .proAddress_ProMap{
      font-size: 10px;
    }
    .distanceText_ProMap{
        font-size: 10px;
    }
    .serviceCount_Btn_Map{
        width:110px;
    }
    .serviceCount_Btn_Text{
      font-size: 10px;
    }
}
@media screen and (min-width:0px) and (max-width:760px){
    .proName_ProMap{
        flex-grow: 0;
        margin: 0 0 4px 0;
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    .proAddress_ProMap{
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
        font-family: 'Inter';
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    .distanceText_ProMap{
        flex-grow: 0;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
        font-family: 'Inter';
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;      
    }
    .serviceCount_Btn_Text{
        flex-grow: 0;
        opacity: 0.9;
        font-family: 'Inter';
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }
}