.addNew_Address_Profile{
    font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8c52ff;
}
.editBackView_Address{
    width: 26px;
    height: 26px;
    flex-grow: 0;
    /* margin: 46px 18px 0 0; */
    padding: 4px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addNew_Address_ProfileIcon{
    width: 26px;
    height: 26px;
    flex-grow: 0;
    /* margin: 0 0 45px 18px; */
    /* padding: 3px 5px 7px 4px; */
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;  
}
.subText_SaveAddress{
    flex-grow: 0;
    margin: 9px 0 0 0;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.are_you_wantText{
    flex-grow: 0;
    /* margin: 1px 24px 16px 0; */
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.yes_Btn_SaveAdd{
    width: 100%;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 25px; */
    /* margin: 16px 20px 0 0; */
    /* padding: 10px 26px; */
    border-radius: 6px;
    border: solid 1px #eee;
    background-color: #f5f4f4;
    /* background-color: #8c52ff; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}
.no_Btn_SaveAdd{
    width: 100%;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 57px; */
    /* margin: 16px 0 0 20px; */
    /* padding: 10px 44px; */
    border-radius: 6px;
    border: solid 1px #eee;
    background-color: #8c52ff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.yes_Text_SaveAdd{
    flex-grow: 0;
    font-family: "Inter";
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8c52ff;
}
.no_Text_SaveAdd{
    flex-grow: 0;
    font-family: "Inter";
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
@media screen and (min-width:0px) and (max-width:760px){
    .addNew_Address_Profile{
      font-size: 14px;
    }
    .subText_SaveAddress{
        font-size: 10px;
    }
    .are_you_wantText{
        font-size: 14px;
    }
    .yes_Text_SaveAdd{
        font-size: 12px;
    }
    .no_Text_SaveAdd{
        font-size: 12px;
    }
}
/* .input[type=radio]:checked {
    border-color: #00AD51;
    background-color: #00AD51;
    color: #fff;
  } */
  