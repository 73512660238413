.edit_profile_mobile{
    padding-left: 20px;
    padding-top: 90px;
    padding-right:20px;
    padding-bottom:30px;
    border-radius: 15px;
    background-color: #fff;
    margin-top:20px;
}
.update_mobile_desc_mobile{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #747474;
    margin-top:8px;
    margin-bottom: 30px;
    }
.content_profile_mobile{
    display: flex;
    flex-direction: column;
    /* justify-content:center; */
    align-items: center;
    /* margin-bottom: 40px; */
}
.profile_image_style_mobile{
    width:110px;
    height:110px;
}
.mobile_num_web{

    flex:2;
    position: relative;
    margin-left: 5px;
}
.country_code_mobile{
    flex:1
}
@media screen and (max-width: 425px){
    .mobile_num_web{

        flex:2.3;
        position: relative;
        margin-left: 5px;
    }
    .country_code_mobile{
        flex:0.7
    }
}
@media screen and (max-width: 320px){
    .mobile_num_web{

        flex:2.1;
        position: relative;
        margin-left: 5px;
    }
    .country_code_mobile{
        flex:0.9
    }
}