.categoryCardText{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
.categoryCardStyle{
    width: 110px;
    height: 100px;
    /* flex-grow: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:5px;
    cursor:pointer;
    /* gap: 4px; */
    /* margin: 0 36px 36px; */
    /* padding: 20px 28px 8px; */
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 2.5px #fff;
    /* background-color: #ff848e; */
}
.categoryImg_Style{
    height: 40px; 
    width: 40px;
    margin-bottom:10px;
}
@media (min-width:425px) and (max-width:480px){
    .categoryCardStyle{
        width: 90px;
        height: 90px;
        flex-grow: 0;
        /* margin: 0 20px 6px 0; */
        /* padding: 16.8px 19.4px 16.3px 20.2px; */
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
        border: solid 2px #fff;
        /* background-color: #ffd167; */
    }
    .categoryImg_Style{
        height: 45px; 
        width: 45px;
        margin-bottom:auto;
        margin-top: auto;
    }
    .categoryCardText{
        font-size: 10px;
        color:#000;
    }
}
@media screen and (max-width:425px){
    .categoryCardStyle{
        width: 90px;
        height: 90px;
        flex-grow: 0;
        /* margin: 0 20px 6px 0; */
        /* padding: 16.8px 19.4px 16.3px 20.2px; */
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
        border: solid 2px #fff;
        /* background-color: #ffd167; */
    }
    .categoryImg_Style{
        height: 45px; 
        width: 45px;
        margin-bottom:auto;
        margin-top: auto;
    }
    .categoryCardText{
        font-size: 10px;
        color:#000;
    }
}
@media screen and (max-width:410px){
    .categoryCardStyle{
        width:83.5px;
        height: 74px;
        flex-grow: 0;
        /* margin: 0 20px 6px 0; */
        /* padding: 16.8px 19.4px 16.3px 20.2px; */
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
        border: solid 2px #fff;
        /* background-color: #ffd167; */
    }
    .categoryImg_Style{
        height: 40px; 
        width: 40px;
        margin-bottom:auto;
        margin-top: auto;
    }
    .categoryCardText{
        font-size: 10px;
        color:#000;
    }
}
@media screen and (max-width:380px){
    .categoryCardStyle{
        width: 78.5px;
        height: 74px;
        flex-grow: 0;
        /* margin: 0 20px 6px 0; */
        /* padding: 16.8px 19.4px 16.3px 20.2px; */
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
        border: solid 2px #fff;
        /* background-color: #ffd167; */
    }
    .categoryImg_Style{
        height: 40px; 
        width: 40px;
        margin-bottom:auto;
        margin-top: auto;
    }
    .categoryCardText{
        font-size: 10px;
        color:#000;
    }
}
@media screen and (max-width:360px){
    .categoryCardStyle{
        width: 76.5px;
        height: 72px;
        flex-grow: 0;
        /* margin: 0 20px 6px 0; */
        /* padding: 16.8px 19.4px 16.3px 20.2px; */
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
        border: solid 2px #fff;
        /* background-color: #ffd167; */
    }
    .categoryImg_Style{
        height: 40px; 
        width: 40px;
        margin-bottom:auto;
        margin-top: auto;
    }
    .categoryCardText{
        font-size: 10px;
        color:#000;
    }
}
@media screen and (max-width:351px){
    .categoryCardStyle{
        width: 75px;
        height: 71px;
        flex-grow: 0;
    }
    .categoryImg_Style{
        height: 40px; 
        width: 40px;
        margin-bottom:auto;
        margin-top: auto;
    }
    .categoryCardText{
        font-size: 10px;
        color:#000;
    }
}
@media screen and (max-width:350px){
    .categoryCardStyle{
        width: 71px;
        height: 68px;
        flex-grow: 0;
    }
    .categoryImg_Style{
        height: 38px; 
        width: 38px;
        margin-bottom:auto;
        margin-top: auto;
    }
    .categoryCardText{
        font-size: 10px;
        color:#000;
    }
}
@media screen and (max-width:330px){
    .categoryCardStyle{
        width: 69px;
        height: 68px;
        flex-grow: 0;
    }
}