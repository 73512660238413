.profileName{
    flex-grow: 0;
    margin: 7px 77px 4px 8px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color:#8c52ff
}
.profileContainer{
  padding: 30px 129px 25px 129px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.profileOptions{
    width: 30%;
  height: 427px;
  margin: 43.5px 28px 35px 0px;
  padding: 7px 6px 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
  border: solid 1px #eee;
  background-color: #fff;
}
.optionDiv{
  /* flexDirection:'row',display:'flex',justifyContent:'flex-start',padding:15,borderRadius:8 */
flex-direction: row;
display: flex;
justify-content: flex-start;
padding-top: 20px;
padding-left: 10px;
padding-bottom: 10px;
padding-right: 10px;
border-radius: 8px;
cursor: pointer;
}
.optionText{
  width: 309px;
  flex-grow: 0;
  margin: 2px 0 1px 14px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}
.logoutDiv{
  /* flexDirection:'row',display:'flex',justifyContent:'flex-start',padding:15,borderRadius:8 */
flex-direction: row;
display: flex;
justify-content: flex-start;
padding-top: 10px;
padding-left: 10px;
padding-bottom: 15px;
padding-right: 10px;
border-radius: 8px;
cursor: pointer;
}
.backgroudDiv{
  /* flexDirection:'row',display:'flex',justifyContent:'flex-start',padding:15,borderRadius:8 */
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;

}
.proactionDIv{
  width: 1366px;
  height: 1131px;
  flex-grow: 0;
  padding: 0 0 68px;
  background-color: #f1f1f1;
}
.profileActionDiv{
  width: 60%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;
border-radius: 15px;
box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
border: solid 1px #eee;
background-color: #fff;
}

.profileActionDiv_1{
  width: 60%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 0px;
border-radius: 15px;
/* box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1); */
border: solid 1px #eee;

}

.yes_Logout_Btn{
  width: 100%;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #8c52ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.cancel_Logout_Btn{
  width: 100%;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #f5f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yes_Text_Logout{
  flex-grow: 0;
  font-family: "Inter";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.cancel_Text_Logout{
  flex-grow: 0;
  font-family: "Inter";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ea4335;
}
@media screen and (max-width:1440px) {
  .profileOptions{
    width: 25%;
  margin: 43.5px 28px 35px 0px;
  padding: 7px 6px 10px;
  
}
.profileActionDiv{
  width: 75%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;
}
}
@media screen and (max-width:1240px) {
  .profileOptions{
    width: 55%;
  margin: 43.5px 28px 35px 0px;
  padding: 7px 6px 10px;
  
}
}
@media screen and (max-width:1024px) {
  .profileOptions{
    width: 30%;
  margin: 43.5px 28px 35px 0px;
  padding: 7px 6px 10px;
  
}
.profileContainer{
  padding: 30px 50px 25px 50px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.profileActionDiv{
  width: 60%;
/* height: 427px; */
margin: 43.5px 0px 35px 0px;
padding: 7px 6px 10px;
}
}
@media screen and (max-width:786px) {

.profileOptions{
  width: 40%;
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;

}
.profileActionDiv{
  width: 60%;
/* height: 427px; */
margin: 43.5px 28px 35px 0px;
padding: 7px 6px 10px;
}
}