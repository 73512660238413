.ReferAndEarn_MainView{
    border-radius: 20px;
    padding:20px;
    box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
    background: linear-gradient(87deg, #461c99 -5%, #8c52ff 39%, #ff5d5d 138%);
}
.yourRef_CodeText{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.refeCode_BgColor{
    height: 48px;
    width:350px;
    padding:10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: solid 1px #1c1b1f;
    background-color: #fff;
}
.referCode_BgColor_WoLogin{
    height: 48px;
    width:200px;
    padding:10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: solid 1px #1c1b1f;
    background-color: #fff;
}
.inviteSectionRefer_Home{
    width: 135px;
    height: 48px;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    border: solid 1px #fff;
}
.inviteText_Home{
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.belowText_View_REHome{
    margin-left: 22px;
}
.referalCode_Text_Home{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c1b1f;
}
.loginTextRefer_Earn{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #461c99;
}
.inviteFrnds_Image{
    width:400px;
}
.referEarn_Img_Home_Mobile{
    width:100%;
}
.textDiv_HomeRefer{
    margin-left: 20px;
}
.invite_Pro_mobile{
    padding:8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 0;
    border-radius: 8px;
    border: solid 1px #fff;
    background-color: rgba(255, 255, 255, 0.6);
}
.referAFriend_Home_Mob{
    flex-grow: 0;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.inviteAFriend_Home_Mob{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.inviteText_Box_Mob{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 9px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #461c99;
}
.referEarn_Img_Home{
    width:300px;
    height:150px;
}
@media screen and (max-width:1240px){
    .inviteFrnds_Image{
        width:80%;
    }
    .refeCode_BgColor{
        height: 48px;
        width:300px;
    }
    .inviteSectionRefer_Home{
        width: 122px;
        height: 48px;
    }
}
@media screen and (max-width:1024px){
    .inviteFrnds_Image{
        width:60%;
    }
    .refeCode_BgColor{
        height: 48px;
        width:250px;
    }
    .inviteSectionRefer_Home{
        width: 100px;
        height: 48px;
    }
}
@media screen and (max-width:768px){
    .belowText_View_REHome{
        margin-left: 12px;
    }
    .inviteFrnds_Image{
        width:40%;
    }
    .refeCode_BgColor{
        height: 40px;
        width:180px;
    }
    .inviteSectionRefer_Home{
        width: 100px;
        height: 40px;
    }
    .referEarn_Img_Home{
        width:200px;
        height:100px;
    }
    .textDiv_HomeRefer{
        margin-left: 0px;
    }
}
@media screen and (min-width:0px) and (max-width:760px){
    .ReferAndEarn_MainView{
        border-radius: 15px;
        padding:0px;
        height:auto;
        box-shadow: 0 2px 8px 0 rgba(103, 52, 204, 0.15);
        background: linear-gradient(87deg, #461c99 -5%, #8c52ff 39%, #ff5d5d 138%);
    }
}