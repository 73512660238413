
.p_secondpart{
    
    margin-left:16px;
    display:flex;
    flex-direction:column;
    width:80%;
}
.p_serviceName{
    font-family: Inter;
    font-weight:600;
    letter-spacing:normal;
    font-size:14px;
    width:100%;
    margin-bottom:0px;
    color: #000;
    line-height: normal;
    font-stretch: normal;

}
.p_hidedetLbl{

    font-weight: 500;
    color: #8c52ff;
    font-size: 14px;
    text-decoration-line: underline;
    text-align: right;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;


}


.p_merchantName{

    font-family: Inter;
    font-weight:normal;
    letter-spacing:normal;
    font-size:12px;
    width:100%;
    margin-bottom:0px;
    color: #000;
    line-height: normal;
    font-stretch: normal;
}                 

.p_serviceImage{

    width:80px;
    height: 80px;
    border-radius:8px;
}

.p_viewOrHideDiv{

    margin-top: 20px;
}

.p_date{

    flex-direction: row;
    margin-top: -5px;

}

.p_time{

    flex-direction: row;
    margin-top: -5px;
}

.p_date_icon{

   width: 15px;
   height:15px;

}

.p_time_icon{

    width:15px;
    height:15px;
}



.p_thirdPart{

    width:40%;
    text-align: right;  
   
}

.p_date_text{

   font-size: 12px;
   width: 130;
   color: #747474
}
.p_time_text{

   font-size: 12px;
   width: 150;
   color: #747474;
   margin-bottom: 4px;
}

.p_paidvalue{
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #5ba85a;
}

.p_Debitfrmlbl{

    font-size: 14px;
    font-weight: 500;
    width: 100px;
    font-family: Inter;
}

.p_paymentmode{

    color:#9f9f9f;
    font-size:14px;
}

.p_csuccess{

    width: 14px;
    height: 14px;

}

.p_csuccesslbl{

    color: #5ba85a;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    margin-left: 4px;

}

.p_sucdiv{

    margin-top: 0px;
}

.enquiryLbl{

    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    font-weight: bold;
    color:#8c52ff;
    background-color: #E5E4E2;
    border-width: 0.5px;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    bottom: 0px;
    right: 0px;
   
}

.p_refundlbl{

    margin-bottom: 14px;
    font-weight:600 ;
    color: #5ba85a;
    font-size: 14px;


   
}


@media screen and (min-width:385px) and (max-width:425px){

    .p_secondpart{
        width:47%;
        margin-left:24px;
    }
    .p_serviceName{
        text-overflow:ellipsis;
        overflow: hidden;
        white-space:nowrap;
    }
    .p_merchantName{
        text-overflow:ellipsis;
        overflow: hidden;
        white-space:nowrap;
     }

     .p_serviceImage{

        width:68px;
        height: 68px;
    }

    .p_viewOrHideDiv{

        margin-top: 16px;
    }
    .p_date{

        margin-top: -3px;
    
    }
    .p_time{    
        margin-top: -6px;
    }

    .p_thirdPart{

        width:40%;
        text-align: right;  
        margin-top: -6px;
       
    }
    /* .p_time_icon{

        margin-right: 0px;
    } */
    .p_Debitfrmlbl{

        width:94px
    }
    

}



@media screen and (min-width:375px) and (max-width:384px){


    /* .p_time_icon{
        margin-right: 0px;
    } */


    .p_viewOrHideDiv{

        margin-top: 10px;
        /* width: 81px; */
        /* font-size: 11px; */
    }
    .p_secondpart{
    
        margin-left:24px;
        width: 133px;
    }
    .p_serviceName{
        text-overflow:ellipsis;
        overflow: hidden;
        white-space:nowrap;
    }
    .p_merchantName{
        text-overflow:ellipsis;
        overflow: hidden;
        white-space:nowrap;
     }

    .p_viewOrHideDiv {
        width: 80px;
    }
    .p_thirdPart{

        width:25%;
        text-align: right;  
        /* margin-top: -6px; */
       
    }
    .p_serviceImage{

        width:70px;
        height: 70px;
    }
    .p_Debitfrmlbl{

        width:94px
    }
    
}




@media screen and (min-width:320px) and (max-width:340px){

    .p_serviceImage{

        width:55px;
        height: 55px;
    }

    .p_Debitfrmlbl{

        font-size:11px;
        width:72px;
    }

    .p_serviceName{
        text-overflow:ellipsis;
        overflow: hidden;
        white-space:nowrap;
        font-size: 11px;
    }

    .p_merchantName{
        text-overflow:ellipsis;
        overflow: hidden;
        white-space:nowrap;
        font-size: 9px;
     }

     .p_date_icon{

        width: 10px;
        height:10px;
     
     }
     
     .p_time_icon{
     
         width:10px;
         height:10px;
         /* margin-right:4px; */
     }

     .p_viewOrHideDiv{

        margin-top: 0px;
        /* width: 81px; */
        /* font-size: 11px; */
    }

    .p_date_text{

        font-size: 9px;
    }
    .p_time_text{

        font-size: 9px;
        
    }
    .p_secondpart{
    
       
        width: 105px;
    }

    .p_hidedetLbl{

        font-size: 11px;

    }
    .p_time{

        margin-top: -10px;
    }
    /* .p_viewOrHideDiv{

        margin-top: 11px;
    } */
    .p_thirdPart{

     margin-top: -5px;       
    }
    
    .p_paidvalue{
        font-size: 11px;
    }
    
    .p_paymentmode{

        font-size:11px;
    }

    .p_csuccess{

        width: 10px;
        height: 10px;
    
    }
    
    .p_csuccesslbl{
    
        font-size: 11px;
       
    
    }

    .p_sucdiv{

        margin-top: -5px;
    }

    .enquiryLbl{

        font-size: 10px ;
        padding:6px;
    }

    .p_refundlbl{

      
        font-size: 11px;
    
    
       
    }
    
}

/* @media screen and (max-width:320px){
    
} */




