.HandS_Container_Home1{
    padding: 30px 129px 25px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.imgWidth1 {
    /* width: 20, height: 20, marginRight: 25  */
    width: 18px;
    height: 18px;
    margin-right: 25px;
}
.Form-group-button1{
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    width:485px;
    margin-top: 30px;
}
.codeHSM{
    height: 38px;
    width: 100px;
    margin-left: 0px;
}
.buttonstyl1{
    font-family: 'Inter';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;;
    height: 40px;
    flex-grow: 0;
    padding: 8px 280.5px 7px 270.7px;
    border-radius: 6px;
    background-color:#8c52ff;
    cursor: pointer;
    
}
.s1{
    font-family: Inter;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}
.menu1{
    position: absolute;
    justify-content: flex-end;
    right: 49px;
    margin-top: -10px;
    width: 184px;

   /* top:20px; */
   /* padding-top: 20px; */
}
.faqSub1{
    font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9f9f9f;
}
.Form-label-text1{
    padding-left: 10px;
    padding-right: 10px;
    font-family: Mulish;
    font-weight: 500;
    font-size: 16px;
    color: #1a0c2e;
    text-align: initial;

}
.formHeadder2{
  margin: 4px 81px 21px 12px;
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8c52ff;
}
.mainContainersv2{
    background-color: #fff;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom:20px;
    /* width: 100%; */
}
.mail1{
    flex-grow: 0;
    margin: 10px 14px 4px 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: left; */
  color: #fff;
}

.search1{
    flex-grow: 0;
    margin: 1px 24px 4px 4px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.call1{
    flex-grow: 0;
    margin: 19px 14px 4px 4px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
}
.textContect1{
 
    flex-grow: 0;
    margin: 15px 6px 10px 20px;
    font-family: Inter;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
}
.headder1{
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.subheadder1{
    /* width: 275px;
    height: 17px;
    margin: 4px 162px 13.5px 138px; */
    margin-top: 5px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
  }
  .line1{
    width: 1090px;
  height: 1px;
  margin-bottom: 30px;
  margin-top: 10px;
  opacity: 0.6;
  background-color: #b8b8b8;
  }
.imgStylesv3{
    width:100%;
    /* padding-left: 0px;
    padding-right: 0px; */
    height:450px;
}
.CarousalContainer_Sv3{
    padding-left: 0px;
    padding-right: 0px;
    /* height:450px; */
}
.altTextSv3{
    position:absolute;
    bottom:150px;
    left:90px;
    right:90px;
    z-index: 10;
    justify-content: space-between;
}
.proNameStylesv3{
    font-family: 'Inter';
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.addressTextsv3{
    font-family: Inter;
    font-size: 14px;
    font-weight:normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    text-align: left;
    color: #fff;
    
}
.imgMain{
    /* width: 406, height: 274, borderTopLeftRadius: 10 */
    width: 406px;
    height: 272px;
    border-top-left-radius: 10px;
}
.distanceTextsv2{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding-left: 5px;
}
.wantToReachOut1{
  flex-grow: 0;
  border-radius: 15px;
  box-shadow: 2px 2px 15px 0 rgba(166, 171, 189, 0.5), -1px -1px 13px 0 #fafbff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.contact_View1{
    background-color:#8c52ff;
    border-radius: 10px;
}
.contact_Option1{
    /* flexDirection: 'column', display: 'flex', alignItems: 'center' */
    flex-direction:row;
    display: flex;
    /* align-items: flex-end; */
    justify-content: space-between;
    
}
.imgWidth{
    /* width: 20, height: 20, marginRight: 25  */
    width: 20px;
    height: 20px;
    margin-right:25px ;
}
.socialMedia1{
    flex-direction: row;
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 20px;
}
.contactFiled{
    flex-direction: column;
    display: flex;
    margin-top: 30px;
}
.userOptions{
    flex-direction: row;
    display: flex;
    margin-left:0px ;
}
.fromLeft{
    margin-left: 0px;
}


@media screen and (max-width:1600px){
    .HandS_Container_Home{
        padding: 30px 129px 25px 129px;
    } 
    .buttonstyl1{
        padding: 8px 280.5px 7px 270.7px;
    }
    .Form-group-button{
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        width:650px;
        margin-top: 30px;
    }
}
@media screen and (max-width:1440px){
    .HandS_Container_Home{
        padding: 30px 129px 25px 129px;
    } 
    .Form-group-button{
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        width:650px;
        margin-top: 30px;
    }
}
@media screen and (max-width:1240px){
    .HandS_Container_Home{
        padding: 30px 129px 25px 129px;
    } 
    .buttonstyl1{
        padding: 8px 155.5px 7px 155.7px;
    }
    .formHeadder{
        margin: 44px 81px 21px 82px;
        font-size: 20px;
      }
      .Form-group-button{
        display: flex;
        justify-content: center;
        padding-left: 100px;
        padding-right: 10px;
        padding-top: 5px;
        width:100px;
        margin-top: 30px;
        margin-left: 100px;
    }
}
@media screen and (max-width:1024px){
    .HandS_Container_Home{
        padding: 30px 129px 25px 129px;
    } 
    .buttonstyl1{
        padding: 8px 155.5px 7px 155.7px;
    }
    .formHeadder{
        margin: 44px 51px 21px 42px;
        font-size: 20px;
      }
      .Form-group-button{
        display: flex;
        justify-content: center;
        padding-left: 100px;
        padding-right: 10px;
        padding-top: 5px;
        width:200px;
        margin-top: 30px;
        margin-left: 60px;
    }
    .imgMain{
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 315px;
        height: 272px;
        border-top-left-radius: 10px;
    }
    .fromLeft{
        margin-left: 0px;
    }
    .userOptions{
        flex-direction: row;
        display: flex;
        margin-left:40px ;
    }
}
@media screen and (max-width:768px){
    .HandS_Container_Home{
        padding: 16px 50px 16px 50px;
    }
   
    .formHeadder2{
        margin: 44px 51px 21px 42px;
        font-size: 20px;
      }
      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 100px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 60px;
    }
    .imgMain1{
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 315px;
        height: 272px;
        border-top-left-radius: 10px;
    }
    .fromLeft1{
        margin-left: 0px;
    }
    .userOptions{
        flex-direction: row;
        display: flex;
        margin-left: 5px;
        margin-bottom: 15px;
        /* justify-content: center; */
    }
    .subjectDiv1:hover {
        background-color: #DFC5FE;
        border-radius: 6px;
        margin-left: 3px;
        margin-right: 3px;
        margin-top: 2px;
        margin-bottom: 2px;
        
    }
    .subjectText1 {
        margin: 1px 30px 1px 20px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #8c52ff
    }
    .optionText1{
        font-size: 12px;
        margin-left: 10px;
    }
    .menu{
        position: absolute;
        justify-content: flex-end;
        right: 50px;
       /* top:20px; */
       padding-top: 20px;
    }
    .altTextSv3{
        position:absolute;
        bottom:120px;
        left:60px;
        right:90px;
        justify-content: space-between;
    }
    .proNameStylesv3{

        font-size: 30px;
     
    }
    .addressTextsv3{

        font-size: 14px;
    
        
    }
    .fromLeft{
        margin-left: 0px;
    }
    .faq{
        font-size: 16px;
    }
    .imgStylesvMobile{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:450px;
    }
    .buttonstyl1{
        padding: 8px 290.5px 7px 285.7px;
        margin-left: -25px;
    }
    .formHeadder2{
        margin: 0 18px 16px 10px;
        font-family: Inter;
        font-size: 16px;
     
      }
      .socialMedia1{
        flex-direction: row;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 16px;
        padding-bottom: 20px;
    }
    .mail1{
        margin: 10px 14px 4px 0px;
      font-family: Inter;
      font-size: 12px;
  
    }
    
    .search1{

        margin: 10px 14px 4px 4px;
        font-size: 12px;
       
    }
}




@media screen and (max-width:425px){
    .imgStylesvMobile{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .HandS_Container_Home{
        padding: 16px 20px 16px 20px;
    }
    .buttonstyl1{
        padding: 8px 135.5px 7px 155.7px;
        margin-left: 10px;
    }
    .formHeadder2{
        margin: 0 18px 16px 10px;
        font-family: Inter;
        font-size: 16px;
     
      }
      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 130px;
        padding-right: 10px;
        padding-top: 5px;
        width:100px;
        margin-top: 30px;
        margin-left: 60px;
    }
    .imgMain1{
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 365px;
        height: 272px;
        border-top-left-radius: 10px;
    }
    .fromLeft1{
        margin-left: 0px;
    }
    .userOptions{
        flex-direction: row;
        display: flex;
        margin-left: 5px;
        margin-bottom: 15px;
        /* justify-content: center; */
    }
    .optionText{
        font-size: 12px;
    }
    .menu1{
        position: absolute;
        justify-content: flex-end;
        right: 29px;
       /* top:20px; */
       /* padding-top: 20px; */
    }
    .altTextSv3{
        position:absolute;
        bottom:50px;
        left:30px;
        right:90px;
        justify-content: space-between;
    }
    .proNameStylesv3{

        font-size: 30px;
     
    }
    .addressTextsv3{

        font-size: 14px;
    
        
    }   
    .imgStylesvMobile{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .textContect1{
 
        flex-grow: 0;
        margin: 10px 6px 10px 10px;
        font-size: 16px;
       
        text-align: left;
    
    }
    .call1{
        flex-grow: 0;
        margin: 15px 15px 4px 5px;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
    }
    .fromLeft{
        margin-left: 0px;
    }
    .faq{
        font-size: 16px;
    }
    .textContect{
        font-size: 14px;
        padding-left: 10px;
    }
    .socialMedia1{
        flex-direction: row;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 16px;
        padding-bottom: 20px;
    }
    .mail1{
        margin: 10px 14px 4px 0px;
      font-family: Inter;
      font-size: 12px;
  
    }
    
    .search1{

        margin: 10px 14px 4px 4px;
        font-size: 12px;
       
    }
    .optionText1{
        font-size: 12px;
        margin-left: 5px;
        font-weight: 500;
    }

}
@media screen and (max-width:375px){
    .textContect{
        font-size: 14px;
        padding-left: 10px;
    }
    .HandS_Container_Home{
        padding: 16px 10px 16px 10px;
    }
     .buttonstyl1{
        padding: 8px 130.5px 7px 130.7px;
        margin-left: -5px;
    }
    .formHeadder2{
        margin: 0 18px 16px 10px;
        font-family: Inter;
        font-size: 16px;
     
      }
      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 130px;
        padding-right: 10px;
        padding-top: 5px;
        width:100px;
        margin-top: 30px;
        margin-left: 50px;
    }
    .imgMain1{
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 365px;
        height: 272px;
        border-top-left-radius: 10px;
    }
    .fromLeft1{
        margin-left: 0px;
    }
    .userOptions{
        flex-direction: row;
        display: flex;
        margin-left: 5px;
        margin-bottom: 15px;
     
        /* justify-content: center; */
    }
    .optionText{
        font-size: 12px;
    }
    .menu{
        position: absolute;
        justify-content: flex-end;
        right: 50px;
       /* top:20px; */
       padding-top: 20px;
    }
    .altTextSv3{
        position:absolute;
        bottom:50px;
        left:30px;
        right:90px;
        justify-content: space-between;
    }
    .proNameStylesv3{

        font-size: 30px;
     
    }
    .addressTextsv3{

        font-size: 14px;
    
        
    }   .imgStylesvMobile{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .textContect1{
 
        flex-grow: 0;
        margin: 10px 6px 10px 10px;
     
        font-size: 16px;
       
        text-align: left;
    
    }
    .call1{
        flex-grow: 0;
        margin: 19px 14px 4px 4px;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
    }
    .fromLeft{
        margin-left: 0px;
    }
    .faq{
        font-size: 16px;
    }
    .socialMedia1{
        flex-direction: row;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 16px;
        padding-bottom: 20px;
    }
    .contact_Option1{
        margin-right: 0px;
    }
    .search1{

        margin: 10px 14px 4px 4px;
        font-size: 12px;
       
    }

}
@media screen and (max-width:360px){
    .buttonstyl1{
        padding: 8px 125.5px 7px 125.7px;
        margin-left: -5px;
    }
    .formHeadder2{
        margin: 44px 51px 21px 42px;
        font-size: 20px;
      }
      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 133px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }
}
@media screen and (max-width:355px){
    .buttonstyl1{
        padding: 8px 122px 7px 122px;
        margin-left: 0px;
    }

      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 128px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }

}
@media screen and (max-width:350px){
    .buttonstyl1{
        padding: 8px 120px 7px 120px;
        margin-left: -5px;
    }

      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 128px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }

}
@media screen and (max-width:345px){
    .buttonstyl1{
        padding: 8px 116px 7px 116px;
        margin-left: 0px;
    }

      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 123px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }

}
@media screen and (max-width:340px){
    .buttonstyl1{
        padding: 8px 114px 7px 114px;
        margin-left: 0px;
    }

      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 121px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }

}
@media screen and (max-width:330px){
    .buttonstyl1{
        padding: 8px 110px 7px 110px;
        margin-left: 0px;
    }

      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 116px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }

}
@media screen and (max-width:325px){
    .headder1{
 
        font-size: 14px;
    
      }
      .subheadder1{
     
          margin-top: 5px;
          font-family: Inter;
          font-size: 12px;
        
        }



    .HandS_Container_Home{
        padding: 16px 10px 16px 10px;
    }
     .buttonstyl1{
        padding: 8px 108.5px 7px 108.7px;
        margin-left: -5px;
    }
    .formHeadder2{
        margin: 44px 51px 21px 42px;
        font-size: 20px;
      }
      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 115px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }
    .imgMain1{
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 365px;
        height: 272px;
        border-top-left-radius: 10px;
    }
    .fromLeft1{
        margin-left: 0px;
    }
    .userOptions{
        flex-direction: row;
        display: flex;
        margin-left: 5px;
        margin-bottom: 15px;
     
        /* justify-content: center; */
    }
    .optionText{
        font-size: 12px;
    }
    .menu{
        position: absolute;
        justify-content: flex-end;
        right: 50px;
       /* top:20px; */
       padding-top: 20px;
    }
    .altTextSv3{
        position:absolute;
        bottom:50px;
        left:30px;
        right:90px;
        justify-content: space-between;
    }
    .proNameStylesv3{

        font-size: 25px;
     
    }
    .addressTextsv3{

        font-size: 14px;
    
        
    }   .imgStylesvMobile{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .textContect1{
 
        flex-grow: 0;
        margin: 10px 6px 10px 10px;
     
        font-size: 12px;
       
        text-align: left;
    
    }
    .call1{
        flex-grow: 0;
        margin: 9px 15px 5px 5px;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
    }
    .fromLeft{
        margin-left: 0px;
    }
    .faq{
        font-size: 16px;
    }
    .imgWidth1 {
        /* width: 20, height: 20, marginRight: 25  */
        width: 20px;
        height: 20px;
        margin-right:5px;
    }
    .socialMedia1{
        flex-direction: row;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 16px;
        padding-bottom: 20px;
    }
    .mail1{
        margin: 10px 14px 4px 0px;
      font-family: Inter;
      font-size: 12px;
  
    }
    
    .search1{

        margin: 10px 14px 4px 4px;
        font-size: 12px;
       
    }
    .optionText1{
        font-size: 12px;
        margin-left: 5px;
        font-weight: 500;
    }
    .formHeadder2{
        margin: 0 18px 16px 10px;
        font-family: Inter;
        font-size: 16px;
     
      }
}
@media screen and (max-width:320px){
    .headder1{
 
        font-size: 14px;
    
      }
      .subheadder1{
     
          margin-top: 5px;
          font-family: Inter;
          font-size: 12px;
        
        }



    .HandS_Container_Home{
        padding: 16px 10px 16px 10px;
    }
     .buttonstyl1{
        padding: 8px 104px 7px 102px;
        margin-left: -5px;
    }
    .formHeadder2{
        margin: 44px 51px 21px 42px;
        font-size: 20px;
      }
      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 113px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }
    .imgMain1{
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 365px;
        height: 272px;
        border-top-left-radius: 10px;
    }
    .fromLeft1{
        margin-left: 0px;
    }
    .userOptions{
        flex-direction: row;
        display: flex;
        margin-left: 5px;
        margin-bottom: 15px;
     
        /* justify-content: center; */
    }
    .optionText{
        font-size: 12px;
    }
    .menu{
        position: absolute;
        justify-content: flex-end;
        right: 50px;
       /* top:20px; */
       padding-top: 20px;
    }
    .altTextSv3{
        position:absolute;
        bottom:50px;
        left:30px;
        right:90px;
        justify-content: space-between;
    }
    .proNameStylesv3{

        font-size: 25px;
     
    }
    .addressTextsv3{

        font-size: 14px;
    
        
    }   .imgStylesvMobile{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .textContect1{
 
        flex-grow: 0;
        margin: 10px 6px 10px 10px;
     
        font-size: 12px;
       
        text-align: left;
    
    }
    .call1{
        flex-grow: 0;
        margin: 9px 15px 5px 5px;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
    }
    .fromLeft{
        margin-left: 0px;
    }
    .faq{
        font-size: 16px;
    }
    .imgWidth1 {
        /* width: 20, height: 20, marginRight: 25  */
        width: 20px;
        height: 20px;
        margin-right:5px;
    }
    .socialMedia1{
        flex-direction: row;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 16px;
        padding-bottom: 20px;
    }
    .mail1{
        margin: 10px 14px 4px 0px;
      font-family: Inter;
      font-size: 12px;
  
    }
    
    .search1{

        margin: 10px 14px 4px 4px;
        font-size: 12px;
       
    }
    .optionText1{
        font-size: 12px;
        margin-left: 5px;
        font-weight: 500;
    }
    .formHeadder2{
        margin: 0 18px 16px 10px;
        font-family: Inter;
        font-size: 16px;
     
      }
}
@media screen and (max-width:300px){
    .headder1{
 
        font-size: 14px;
    
      }
      .subheadder1{
     
          margin-top: 5px;
          font-family: Inter;
          font-size: 12px;
        
        }



    .HandS_Container_Home{
        padding: 16px 10px 16px 10px;
    }
     .buttonstyl1{
        padding: 8px 95px 7px 95px;
        margin-left: -5px;
    }
    .formHeadder2{
        margin: 44px 51px 21px 42px;
        font-size: 20px;
      }
      .Form-group-button1{
        display: flex;
        justify-content: center;
        padding-left: 103px;
        padding-right: 10px;
        padding-top: 5px;
        /* width:100px; */
        margin-top: 30px;
        margin-left: 40px;
    }
    .imgMain1{
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 365px;
        height: 272px;
        border-top-left-radius: 10px;
    }
    .fromLeft1{
        margin-left: 0px;
    }
    .userOptions{
        flex-direction: row;
        display: flex;
        margin-left: 5px;
        margin-bottom: 15px;
     
        /* justify-content: center; */
    }
    .optionText{
        font-size: 12px;
    }
    .menu{
        position: absolute;
        justify-content: flex-end;
        right: 50px;
       /* top:20px; */
       padding-top: 20px;
    }
    .altTextSv3{
        position:absolute;
        bottom:50px;
        left:30px;
        right:90px;
        justify-content: space-between;
    }
    .proNameStylesv3{

        font-size: 20px;
     
    }
    .addressTextsv3{

        font-size: 14px;
    
        
    }   .imgStylesvMobile{
        width:100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height:250px;
    }
    .textContect1{
 
        flex-grow: 0;
        margin: 10px 6px 10px 10px;
     
        font-size: 12px;
       
        text-align: left;
    
    }
    .call1{
        flex-grow: 0;
        margin: 9px 15px 4px 5px;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
    }
    .fromLeft{
        margin-left: 0px;
    }
    .faq{
        font-size: 16px;
    }
    .imgWidth1 {
        /* width: 20, height: 20, marginRight: 25  */
        width: 20px;
        height: 20px;
        margin-right:5px;
    }
    .socialMedia1{
        flex-direction: row;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 16px;
        padding-bottom: 20px;
    }
    .mail1{
        margin: 10px 14px 4px 0px;
      font-family: Inter;
      font-size: 12px;
  
    }
    
    .search1{

        margin: 10px 14px 4px 4px;
        font-size: 12px;
       
    }
    .optionText1{
        font-size: 10px;
        margin-left: 5px;
        font-weight: 500;
    }
    .formHeadder2{
        margin: 0 18px 16px 10px;
        font-family: Inter;
        font-size: 16px;
     
      }
}