.nearbyProCardImg{
    width: 239px;
    height: 144px;
    /* margin: 44px 45px 16px 20px; */
    /* padding: 16px 0 0; */
    border-radius: 10px;
}
.nearbyProCard{
    width: 239px;
    height: 250px;
    margin-left: auto;
    margin-right:auto;
    /* margin: 44px 45px 16px 20px; */
    /* padding: 16px 0 0; */
    border-radius: 10px;
    border: none;
    cursor:pointer;
}
.nearByProCardNameText{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;  
    /* margin-top: 10px; */
}
.distanceTextPro{
    opacity: 0.5;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    padding-top: 2px;
}
.ratingAndReviews {
    flex-grow: 0;
    opacity: 0.5;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    /* margin-left: 6; */
  }
  .wishlistProCardBg{
    position: absolute;
    right:10px;
    top:10px;
    width: 28px;
    height: 28px;
    flex-grow: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;  
  }
  .nearByProAdd_Overlay {
    position: absolute;
    right: 0px;
    top: 114px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* bottom: 100px; */
    left: 0px;
    background-color: rgba(0,0,0,0.4);
  }
  .nearByPro_addressText{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    /* font-stretch: normal; */
    font-style: normal;
    /* line-height: normal; */
    letter-spacing: 0;
    text-align: left;
    color: #fff;
  }
  .locationIcon_nearByPro{
    width:18px;
    height: 18px;
    margin-top: 2px;
  }
  .starIcon_nearByPros{
    width:16px;
    height:16px;
    margin-right: 5px;
  }
  @media screen and (min-width:0px) and (max-width:760px){
    .nearByProCardNameText{
        font-size: 14px;
    }
    .ratingAndReviews {
        font-size: 10px;
        margin-top: 1.5px;
    }
    .distanceTextPro{
        font-size: 10px;
        padding-top: 2.5px;
    }
    .nearByPro_addressText{
        font-size: 12px;
    }
    .locationIcon_nearByPro{
        width:17px;
        height: 17px;
        margin-top: 1px;
    }
    .starIcon_nearByPros{
        width:14px;
        height:14px;
    }
    .nearByProAdd_Overlay {
      top: 116px;
    }
  }