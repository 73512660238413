
  .areyouNew_CardSv2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 191px;
    border-radius: 15px;
    border: solid 2px #fff;
    background-color:rgba(216,197,252,0.6);
  }
  .areyouCard_RowSv2{
    margin-top: -130px;
    display:flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
  }
  .areCard_ColSv2{
    margin-left:auto;
    margin-right: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .areproCardText_sv2{
      margin-top: 10px;
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;
  }
  .proNew_SubtextSv2{
    opacity: 0.6;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1b1f;
  }
  
  .howItWorks_A{
   
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    width:153%;
    z-index: 100;
  }


  @media screen and (max-width:768px){
    .areyouNew_CardSv2{
      width: 170px;
      height: 170px;
    }
    .areyouCard_RowSv2{
      margin-top: -105px;
    }
    .areproCardText_sv2{
      font-size: 14px;
    }
    .proNew_SubtextSv2{
      font-size: 12px;
    }
  }

  @media screen and (max-width:375px){


    .howItWorks_A{
   
      width:174%;
    }


  }

  @media screen and (max-width:320px){


    .howItWorks_A{
   
      width:215%;
    }


  }
  /* Mobile View CSS Class Names */
  
  
  