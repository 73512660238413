.HandS_Container_Home {
    padding: 30px 129px 25px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.userOptions1 {
    flex-direction: row;
    display: flex;
    margin-left: 40px;
}
.subjectDiv {
    /* backgroundColor:'#ffffff',cursor:'pointer'}} */
    background-color: #ffff;
    cursor: pointer;
    background-color: #fff;
    border-radius: 10px;

}

.subjectDiv:hover {
    background-color: #DFC5FE;
    border-radius: 6px;
    margin-left: 3px;
    margin-right: 3px;
}
.subjectDiv1:hover {
    background-color: #DFC5FE;
    border-radius: 6px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 2px;
    margin-bottom: 2px;
    
}
.subjectText {
    margin: 9px 149px 14px 17px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff
}
.subjectText1 {
    margin: 1px 30px 1px 20px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff
}
.codeHS {
    height: 38px;
    width: 100px;
    margin-left: 10px;
}

.Form-group-button {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    width: 500px;
    margin-top: 30px;
}

.buttonstyl {
    font-family: Mulish;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    ;
    height: 40px;
    flex-grow: 0;
    padding: 8px 285.5px 7px 275.7px;
    border-radius: 10px;
    background-color: #8c52ff;
    cursor: pointer;

}

.faq {
    font-family: Inter;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.menu {
    position: absolute;
    justify-content: flex-end;
    right: 129px;
    /* top:20px; */
    padding-top: 20px;
    cursor: pointer;
}

.reason {
    width: 250px;
}

.faqSub {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
}

.Form-label-text1 {
    padding-left: 10px;
    padding-right: 10px;
    font-family: Mulish;
    font-weight: 500;
    font-size: 16px;
    color: #1a0c2e;
    text-align: initial;

}

.formHeadder1 {
    margin: 44px 81px 21px 55px;
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
}

.mainContainersv2 {
    background-color: #fff;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
    /* width: 100%; */
}

.mail {
    flex-grow: 0;
    margin: 11px 0 4px 14px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.search {
    flex-grow: 0;
    margin: 14px 27px 16px 14px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.call {
    flex-grow: 0;
    margin: 19px 31px 14px 14px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.textContect {

    flex-grow: 0;
    margin: 20px 6px 18px 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.headder {
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.subheadder {
    /* width: 275px;
    height: 17px;
    margin: 4px 162px 13.5px 138px; */
    margin-top: 5px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9f9f9f;
}

.line {
    width: 1090px;
    height: 1px;
    margin-bottom: 30px;
    margin-top: 10px;
    opacity: 0.6;
    background-color: #b8b8b8;
}

.imgStylesv2_HS {
    width: 100%;
    opacity: 0.5;
    transition: opacity 500ms 0ms ease-in-out;
    /* padding-left: 0px;
    padding-right: 0px; */
    height: 350px;
}

.CarousalContainer_Sv2_HS {
    padding-left: 0px;
    padding-right: 0px;
    /* height:450px; */
}

.altTextSv2 {
    position: absolute;
    bottom: 100px;
    left: 140px;
    right: 90px;
    z-index: 10;
    justify-content: space-between;
}

.proNameStylesv2 {
    font-family: 'Inter';
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.addressTextsv2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    text-align: left;
    color: #fff;

}

.imgMain {
    /* width: 406, height: 274, borderTopLeftRadius: 10 */
    width: 406px;
    height: 274px;
    border-top-left-radius: 10px;
}

.distanceTextsv2 {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding-left: 5px;
}

.wantToReachOut {
    flex-grow: 0;
    border-radius: 15px;
    box-shadow: 2px 2px 15px 0 rgba(166, 171, 189, 0.5), -1px -1px 13px 0 #fafbff;
    background-color: #fff;
    display: flex;
    flex-direction: row;
}

.contact_View {
    background-color: #8c52ff;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.contact_Option {
    /* flexDirection: 'column', display: 'flex', alignItems: 'center' */
    flex-direction: column;
    display: flex;
    align-items: center;

}

.imgWidth {
    /* width: 20, height: 20, marginRight: 25  */
    width: 20px;
    height: 20px;
    margin-right: 25px;
}

.socialMedia {
    flex-direction: row;
    display: flex;
    margin-top: 50px;
    margin-bottom: 40px;
}

.contactFiled1 {
    flex-direction: column;
    display: flex;
    margin-top: 50px;
    padding-left: 30px;
}

.userOptions1 {
    flex-direction: row;
    display: flex;
    margin-left: 50px;
}

.fromLeft {
    margin-left: 40px;
}

/* @media screen and (max-width:1600px) {
    .HandS_Container_Home {
        padding: 30px 129px 50px 129px;
    }

    .buttonstyl {
        padding: 8px 280.5px 7px 270.7px;
    }
} */

@media screen and (max-width:1600px) {
    .HandS_Container_Home {
        padding: 30px 129px 25px 129px;
    }
    .buttonstyl {
        padding: 8px 280.5px 7px 270.7px;
    }
    .Form-group-button {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        width: 650px;
        margin-top: 30px;
    }
    .userOptions1 {
        flex-direction: row;
        display: flex;
        margin-left: 10px;
    }
    .formHeadder1 {
        margin: 44px 81px 21px 15px;
        font-family: Inter;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #8c52ff;
    }
}

@media screen and (max-width:1440px) {
    .HandS_Container_Home {
        padding: 30px 129px 25px 129px;
    }
    .userOptions1 {
        flex-direction: row;
        display: flex;
        margin-left: 10px;
    }
    .formHeadder1 {
        margin: 44px 81px 21px 15px;
        font-family: Inter;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #8c52ff;
    }
    .Form-group-button {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        width: 650px;
        margin-top: 30px;
    }
}

@media screen and (max-width:1240px) {
    .HandS_Container_Home {
        padding: 30px 129px 25px 129px;
    }
    .contactFiled1 {
        flex-direction: column;
        display: flex;
        margin-top: 50px;
        padding-left: 10px;
    }

    .buttonstyl {
        padding: 8px 235.5px 7px 235.7px;
        margin-left: 160px;
    }

    .formHeadder1 {
        margin: 44px 81px 21px 12px;
        font-size: 20px;
    }

    .Form-group-button {
        display: flex;
        justify-content: center;
        padding-left: 190px;
        padding-right: 10px;
        padding-top: 5px;
        /* width: 100px; */
        margin-top: 30px;
        margin-left: 170px;
    }
    .fromLeft{
        margin-left: 20px;
    }
}

@media screen and (max-width:1024px) {
    .reason {
        width: 160px;
    }

    .HandS_Container_Home {
        padding: 30px 129px 25px 129px;
    }

    .buttonstyl {
        padding: 8px 175.5px 7px 185.7px;
        margin-left: 40px;
    }

    .formHeadder1 {
        margin: 44px 51px 21px 42px;
        font-size: 20px;
    }

    .Form-group-button {
        display: flex;
        justify-content: center;
        padding-left: 130px;
        padding-right: 10px;
        padding-top: 5px;
        width: 200px;
        margin-top: 30px;
        margin-left: 30px;
    }

    .imgMain {
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 315px;
        height: 272px;
        border-top-left-radius: 10px;
    }

    .fromLeft {
        margin-left: 30px;
    }

    .userOptions1 {
        flex-direction: row;
        display: flex;
        margin-left: 40px;
    }

    .contactFiled1 {
        flex-direction: column;
        display: flex;
        margin-top: 50px;
        padding-left: 0px;
    }

    .altTextSv2 {
        position: absolute;
        bottom: 100px;
        left: 120px;
        right: 90px;
        z-index: 10;
        justify-content: space-between;
    }
}

@media screen and (max-width:768px) {
    .HandS_Container_Home {
        padding: 16px 50px 16px 50px;
    }

    .buttonstyl {
        padding: 8px 135.5px 7px 135.7px;
        margin-left: -60px;
    }

    .formHeadder1 {
        margin: 44px 11px 21px 12px;
        font-size: 20px;
    }

    .Form-group-button {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        width: 100px;
        margin-top: 30px;
        margin-left: 10px;
    }

    .imgMain {
        /* width: 406, height: 274, borderTopLeftRadius: 10 */
        width: 315px;
        height: 272px;
        border-top-left-radius: 10px;
    }

    .fromLeft {
        margin-left: 30px;
    }

    .userOptions1 {
        flex-direction: row;
        display: flex;
        margin-left: 15px;
    }

    .optionText {
        font-size: 12px;
        cursor: pointer;
    }

    .menu {
        position: absolute;
        justify-content: flex-end;
        right: 50px;
        /* top:20px; */
        padding-top: 20px;
    }

    .imgStylesv2_HS {
        width: 100%;
        /* padding-left: 0px;
        padding-right: 0px; */
        height: 350px;
    }
}

@media screen and (min-width:0px) and (max-width:760px) {
    .imgStylesv2_HS {
        width: 100%;
        height: 250px;
    }
}