.coupon_discount_main_div{
    padding: 20px 20px;
}

.coupon_discount_header_div{
    margin: 65.1px 0px 20px 0;
}

.coupon_discount_header_text{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c1b1f;
}

.coupon_discount_text_div{
    display:flex ;
    flex-direction: row;
    width: 100%;
}

.coupon_discount_input_text_div{
    width: 100%;
}

.input_type_text{
    width: 100%;
    /* margin: 28px 0 27.7px; */
    padding: 17px 20px 18.1px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    border: solid 0.5px var(--brownish-grey-40);
    background-color: #fff;
}

.coupon_discount_input_button_div{
    position: absolute;
    right: 100px;
    padding: 17px 0px 18.1px;
    z-index: 100;
}

.applyText{
    /* margin: 3.2px 0 2.1px 432px; */
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #b8b8b8;
}

@media screen and (max-width: 425px){
    .coupon_discount_input_button_div{
        position: absolute;
        right: 50px;
        padding: 17px 0px 18.1px;
        z-index: 100;
    }

    .coupon_discount_header_div{
        margin: 45.1px 0px 20px 0;
    }
}