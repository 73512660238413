.mainBanner_View{
  width:100%;
  height: 250px;
  border-radius: 15px;
}
.textDiv_OnCityImage{
  flex-direction: column;
  display: flex;
  padding-top: 40px;
  padding-left: 60px;
}
.cityWlc_mainText {
  font-family: 'Inter';
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.cityWlc_subText{
  width:639px;
  margin: 12px 32px 0 0;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
@media screen and (max-width:768px){
  .textDiv_OnCityImage{
    flex-direction: column;
    display: flex;
    padding-top: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width:0px) and (max-width:760px){
  .textDiv_OnCityImage{
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
  }
  .cityWlc_mainText {
    font-size: 16px;
  }
  .cityWlc_subText{
    width:auto;
    margin: 12px 0 0 0;
    font-size: 10px;
  }
  .mainBanner_View{
    height: 150px;
  }
}