.navbar_main_new{
    /* position:fixed; */
    display:flex;
    justify-content: space-between;
    flex-direction:row;
    /* flex-wrap:wrap; */
    width:100%;
    z-index:1000;
    background-color: transparent;
    overflow: hidden;
}

.image_wishlist{

    height: 20px;;
    width:20px;
    /* border: 1px solid black; */
    margin-top:10px;
    /* background-color:black */
}




.blog_navlink{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
}
.blog_navlink_af{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
    margin-left: 28px;
    text-decoration: none;
}

.Are_you_pro_navlink_af{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
    margin-left: 28px;
    text-decoration: none;
}

.help_navlink_af{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
    margin-left: 28px;
    text-decoration: none;

}
.offers_navlink_af{
    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
    margin-left: 28px;
    text-decoration: none;

}
.bookings_navlink{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
    
}

.blog_navlink_active{
    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 11px;
    margin-left: 28px;

}



.wishlist_navlink{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
}

.wishlist_navlink_active{
    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 11px;
}

.checkout_navlink{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
}

.checkout_navlink_active{
    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 11px;
}

.profile_navlink{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
}

.profile_navlink_active{
    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 11px;
}


.Are_you_pro_navlink{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
}



.Are_you_pro_navlink_active{

    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom:2px solid #8c52ff;
    padding: 11px;
    margin-left: 28px;

}


.help_navlink{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;

}

.help_navlink_active{

    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom:2px solid #8c52ff;
    padding: 11px;
    margin-left: 28px;


}

.offers_navlink{
    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
}


.offers_navlink_active{
    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom:2px solid #8c52ff;
    padding: 11px;
    margin-left: 28px;



}

.bookings_navlink_af{

    color: black;
    font-size:14px;
    font-family: Inter;
    padding: 11px;
    margin-left: 28px;
    text-decoration: none;
}

.bookings_navlink_active{
    font-family: Inter;
    font-size:14px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    text-decoration: none;
    border-bottom:2px solid #8c52ff;
    padding: 11px;
    margin-left: 28px;


}

.navbar_navbarItem_active_style{
    /* width: 180px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8c52ff;
    text-decoration: none;
    /* border-bottom:2px solid #8c52ff; */
}

.navbar_navbarItem_style{
    /* width: 180px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.afterLogin_div{

    flex-direction:'row';
    margin-top:18;  
    justify-content:'space-between';
    
} 
.afterLogin_div2{
  /* display: 'flex'; */
    flex-direction: row;
    margin-right:6%;
    justify-content:'space-between';
    margin-top:30px;
}

.beforeLogin_div{

    display: block;

}

.wishlist_lbl{
    display: block;

}
.checkout_lbl{
    display: block;
}
.profile_lbl{
    display: block;

}

.profile_logo{

    display: none;
}


@media screen and (max-width:2560px){

   
}



@media screen and (max-width:1440px){

    .afterLogin_div{

        flex-direction:'row';
        margin-top:18;  
        justify-content:'space-between';

        margin-left:'-30%';
    }

   
}

@media screen and (max-width:1320px){

    .afterLogin_div{

        flex-direction:'row';
        margin-top:18;  
        justify-content:'space-between';
        margin-left:'-30%';
    }


   
}

@media screen and (max-width:1290px){

    .afterLogin_div{


        flex-direction:'row';
        margin-top:18;  
        justify-content:'space-between';
        margin-left:'-30%';
    }
   
}

@media screen and (max-width:1240px){

    .afterLogin_div{


  flex-direction:'row';
        margin-top:18;  
        justify-content:'space-between';
        margin-left:'-30%';
    }

  
}


@media screen and (max-width:1024px){

    .Are_you_pro_navlink_active{
        margin-left: 0px;

    }

    .bookings_navlink_active {

        margin-left: 0px;
    }
    .help_navlink_active{
        margin-left: 0px;

    }
    .offers_navlink_active{
        margin-left: 0px;
    }
    .blog_navlink_active{
        margin-left: 0px;
    }




    .profile_navlink_active{
        
        padding: 0px;
    }
    .wishlist_navlink_active{
        padding: 0px;
    }
    .checkout_navlink_active{

        padding:0px;
    }

    .profile_navlink{

        padding: 0px;
    }


    .checkout_navlink{

      
        padding: 0px;
    }

    .wishlist_navlink{

        padding: 0px;
    }


    .blog_navlink_af{

        margin-left: 0px;
    }
    
    .Are_you_pro_navlink_af{
    
        margin-left: 0px;
    }
    
    .help_navlink_af{
    
        margin-left: 0px;
    
    }
    .offers_navlink_af{
       
        margin-left: 0px;
    
    }

    .bookings_navlink_af{

        margin-left: 0px;
    }
    


    .logoSlash_nav_Landing_new{

        margin-right:'-5%';
    }

 

}


@media screen and (max-width:990px){

    .wishlist_lbl{
        display: none;
    }
    .beforeLogin_div{

        display: block;
    
    }

    .checkout_lbl{
        display: none;
    }
    .profile_lbl{
        display: none;
    
    }
    /* .profile_logo{

        display: block;
    } */

    /* .afterLogin_div{

        display: none;
        
    }
    .afterLogin_div2{

        display: none;
        
    } */
    
    /* .beforeLogin_div{
    
        display: none;
    
    } */

 
    

}

@media screen and (max-width:768px){

    /* .beforeLogin_div{

        display: block;
    


    } */
    .afterLogin_div2{
        /* display: 'flex'; */
          flex-direction: row;
          margin-right:6%;
          justify-content:'space-between';
          margin-top:21px;
      }
    .blog_navlink{  

        color: black;
        font-size:14px;
        margin-left:0px;
        margin-right: 10px;
    }

    .Are_you_pro_navlink{

        color: black;
        font-size:14px;
        margin-right: 10px;

    }
    .help_navlink{

        color: black;
        font-size:14px;
        margin-right: 10px;
    }

    .offers_navlink{

        color: black;
        font-size:14px;
        margin-right: 10px;

    }

    .bookings_navlink{

        color: black;
        font-size:14px;
        margin-right: 10px;

    
    }
    .image_wishlist{

        height: 20px;;
        width:20px;
        /* border: 1px solid black; */
        margin-top:10px;
        /* background-color:black; */
        margin-right: 20px;
    }
    
    /* .profile_logo{

        display: block;
    } */
    /* .afterLogin_div{

        display: none;
        
    } */

    /* .afterLogin_div2{

        display: none;
        
    } */
    .wishlist_lbl{
        display: none;
    }
    
    .checkout_lbl{
        display: none;
    }
    .profile_lbl{
        display: none;
    
    }
    
    
}