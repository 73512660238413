.question{
    height: 60px;
    /* margin: 26.5px 138px 8px 139px; */
    padding: 21px 20px;
    border-radius: 15px;
    border: solid 1px var(--slash-accent-color);
    background-color: rgba(224, 209, 255, 0.7);
    flex-direction: row;
    display: flex;
  }
  .arrow{
    flex-direction: row;
    display: flex;

  }
  .showMore{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #461c99;
    flex-direction: row;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
.subNavStyle1{
    font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  color: #461c99;

}
.mybooking_main_div_style2{
    margin-top: 100px;
}

.mybooking_navbar_style2{
    border-bottom:1px solid #b8b8b8;
    margin: 15px 0;
   /* overflow: auto; */
    
}

.mybooking_navbarItem_style2{
    /* width: 190px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.mybooking_navbarItem_active_style2{
    /* width: 190px; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #8c52ff;
    border-bottom:2px solid #8c52ff;
}

ul.nav a:hover { color: #8c52ff !important; }

.mybooking_header_text_style2{
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c1b1f;
}

.mybooking_navItem_active_style2{
    /* margin: 0px 0px 11px 0px; */
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8c52ff;
    padding: 11px;
    /* border-bottom: 2px solid #8c52ff; */
}

.mybooking_navItem_inactive_style2{
    /* margin: 0px 0px 11px 0px; */
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-decoration: none;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
    padding: 11px;
}
@media screen and (max-width: 1440px){
    .mybooking_navbar_style2{
        border-bottom:1px solid #b8b8b8;
        margin: 15px 0;
       overflow: auto;
        
    }
    .mybooking_navbar_style2::-webkit-scrollbar {
        display: none;
      }
    .mybooking_navbarItem_style2{
        width: 190px;
        
    }
    
    .mybooking_navbarItem_active_style2{
        width: 190px;
       
    }
  

}
@media screen and (max-width: 1240px){
    .mybooking_navbar_style2{
        border-bottom:1px solid #b8b8b8;
        margin: 10px 0;
       overflow: auto;
        
    }
    .mybooking_navbarItem_style2{
        width: 158px
        
    }
    .mybooking_navbarItem_active_style2{
        width: 170px;
        padding: 0px;
       
    }
    .mybooking_navItem_inactive_style2{
        /* font-size: 14px; */
        padding: 0px;
    }
    .mybooking_navItem_active_style2{
        font-size: 14px;
    }


}
@media screen and (max-width: 1024px){
    .mybooking_navbar_style2{
        border-bottom:1px solid #b8b8b8;
        margin: 8px 0;
       overflow: auto;
        
    }
    .mybooking_navbarItem_style2{
        width: 125px;
        
    }
    
    .mybooking_navbarItem_active_style2{
        width: 125px;
        padding:0px
       
    }
    .mybooking_navItem_inactive_style2{
        font-size: 12px;
        padding:0px
    }
    .mybooking_navItem_active_style2{
        font-size: 12px;
        padding:0px
    }

}


@media screen and (max-width: 768px){

    .mybooking_navbar_style2{
        border-bottom:1px solid #b8b8b8;
        margin: 8px 0;
       overflow: auto;
        
    }
    
    .mybooking_navbarItem_style2{
        width: 110px;
        
    }
    
    .mybooking_navbarItem_active_style2{
        width: 110px;
        padding:0px
       
    }
    .mybooking_navItem_inactive_style2{
        font-size: 10px;
        padding:0px
    }
    .mybooking_navItem_active_style2{
        font-size: 10px;
        padding:0px
    }
}

@media screen and (max-width: 425px){

    .mybooking_main_div_style2{
        margin-top: 0px;
    }

    .mybooking_navItem_active_style2{
        margin: 0px 0px 11px 0px;
        font-size: 16px;
        color: #8c52ff;
    }

    .mybooking_navItem_inactive_style2{
        margin: 0px 0px 11px 0px;
        font-size: 16px;
        color: #747474;
    }

    .mybooking_header_text_style2{
        font-size: 24px;
        color: #1c1b1f;
    }

    .mybooking_navbarItem_style2{
        width: 131px;
    }
    
    .mybooking_navbarItem_active_style2{
        width: 131px;
    }
}

@media screen and (max-width: 375px){

    .mybooking_navbarItem_style2{
        width: 115px;
    }
    
    .mybooking_navbarItem_active_style2{
        width: 115px;
    }
}

@media screen and (max-width: 320px){

    .mybooking_navItem_active_style2{
        margin: 0px 25px 11px 0px;
        font-size: 14px;
        color: #8c52ff;
    }

    .mybooking_navItem_inactive_style2{
        margin: 0px 25px 11px 0px;
        font-size: 14px;
        color: #747474;
    }
}