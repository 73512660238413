.CrossContainer{
    width: 26px;
    height: 26px;
    flex-grow: 0;
    /* margin: 0 0 16px 0; */
    margin: 0px;
    /* padding: 4px; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
    border-radius: 20px;
}