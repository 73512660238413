.mainDiv_slotSelection{
    padding: 44px 44px;
}

.serviceDetailsDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.serviceNamePriceDiv{
    width: 300.2px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 38px;
    padding: 12px 20px 11px 20px;
    border-radius: 50px;
    border: solid 1px #eee;
    background-color: #fff;
}

.serivePriceStyle{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8c52ff;
}

.serviceNameTextStyle{
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.demandImageStyle{
    width: 26px;
    height: 26px;
    flex-grow: 0;
    margin: 0 6px 0 0;
    padding: 5.5px 8.6px 5.5px 8.6px;
    border-radius: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.demandHourText{
    width: auto;
    height: 24px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1px 0 1px 6px;
    font-family: Inter;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.demandHourContainer{
    height: 38px;
    flex-grow: 0;
    margin: 0px;
    padding: 6px 9px 6px 6px;
    border-radius: 58px;
    border: solid 1px #eee;
    background-color: #fff;
    display: flex;
    flex-direction: row;
}

.dateListMainDiv{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 28px;
    overflow: scroll;
    width: 100%;
    min-height: 90px;
}

.dateListMainDiv {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.dateListMainDiv::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.selectedDateDiv{
    width: 43px;
    height: 67px;
    flex-grow: 0;
    margin: 0 23px 0 23px;
    padding: 13px 10px 8px 11px;
    border-radius: 59px;
    background-color: #8c52ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.unSelectedDateDiv{
    /* width: 43px; */
    /* height: 67px; */
    flex-grow: 0;
    margin: 0 23px 0 23px;
    padding: 13px 10px 8px 11px;
    border-radius: 59px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.unDateListTextStyle{
    flex-grow: 0;
    margin: 0 2px 5px 1px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.dateListTextStyle{
    flex-grow: 0;
    margin: 0 2px 5px 1px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.timeListMainDiv{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-height: 250px;
}

.timeListContainer{
    width: auto;
    height: 50px;
    border-radius: 10px;
    box-shadow: "0px 4px rgba(103, 52, 204, 0.1)";
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-right:20px;
    margin-bottom:20px;
    padding:9px
}

.timeDisplayTextStyle{
    flex-grow: 0;
    margin: 0 0 5px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #747474;
}

.selectedTimeDisplayTextStyle{
    flex-grow: 0;
    margin: 0 0 5px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.demandPriceStyle{
    flex-grow: 0;
    margin: 5px 31px 0 22px;
    padding: 2px 17px 0 16px;
    border-radius: 58px;
    background-color: #5ba85a;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hrSeperator{
    width: 100%;
    height: 1.5px;
    flex-grow: 0;
    margin: 27.8px 0.5px 27.8px 1px;
    background-color: var(--brownish-grey-40);
}

.demandDescStyle{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    margin-bottom: 28px;
}

.demandPriceText{
    flex-grow: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

@media screen and (max-width: 768px){
    .mainDiv_slotSelection{
        padding: 24px 24px;
    }

    .demandHourText{
        margin: 1px 0 1px 6px;
        font-size: 10px;
    }

    .timeDisplayTextStyle{
        font-size: 10px;
    }

    .selectedTimeDisplayTextStyle{
        font-size: 10px;
    }

    .demandPriceText{
        font-size: 10px;
    }

    .demandPriceStyle{
        flex-grow: 0;
        margin: 5px 0px;
        padding: 2px 16px 2px 16px;
        border-radius: 58px;
        background-color: #5ba85a;
    }

    .demandHourContainer{
        margin: 0 18px 0 0;
    }
}

@media screen and (max-width: 425px){

    .mainDiv_slotSelection{
        padding: 24px 16px;
    }

    .timeListContainer{
        margin-right:8px;
        margin-bottom:20px;
        padding:8px
    }

    .timeDisplayTextStyle{
        font-size: 8px;
    }

    .selectedTimeDisplayTextStyle{
        font-size: 8px;
    }

    .demandPriceText{
        font-size: 8px;
    }

    .demandHourText{
        margin: 0px 0px;
        font-size: 8px;
    }

    .demandHourContainer{
        margin: 0 2px 0 0;
        padding: 3px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .unSelectedDateDiv{
        /* width: auto;
        height: 87px; */
        display: flex;
        justify-content: center;
        margin: 0 5px;
        padding: 13px 10px 8px 11px;
    }

    .selectedDateDiv{
        width: auto;
        height: 87px;
        margin: 0 5px;
        padding: 13px 10px 8px 11px;
    }

    .serviceNamePriceDiv{
        width: 100%;
        margin: 0 18px;
        padding: 12px 12px 11px 12px;
    }

}

@media screen and (max-width: 375px){

    .mainDiv_slotSelection{
        padding: 24px 12px;
    }

    .demandHourContainer{
        margin: 0 2px 0 0;
        padding: 3px;
        justify-content: flex-start;
        align-items: flex-start;
    }
}