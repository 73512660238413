.footerSv2_MainText{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: initial;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.footerSv2_downloadText{
    font-family: 'Inter';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: initial;
  letter-spacing: normal;
  text-align: right;
  color: #fff;

}
.footerSv2_subText{
    font-family: 'Inter';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: initial;
  letter-spacing: normal;
  text-align: left;
  color: #fff;

}
.footerSv2_googlePlay{
    width:140px;
    padding-right: 10px;
    /* padding-top:10px; */
}
.footerSv2_appStore{
    width:140px;
    padding-left: 10px;
    /* padding-top:5px; */
}
.inputStyle_sv2{
    width: 141px;
    height: 40px;
    flex-grow: 0;
    border-radius: 10px;
    border: solid 1px #fff;
    background-color: #461c99;
    display:flex;
    align-items: center;
    justify-content: center;
}
.inputTextStyle_sv2{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;  
}
@media screen and (max-width:1024px){
    .footerSv2_googlePlay{
        width:120px;
    }
    .footerSv2_appStore{
        width:120px;
    }
    .footerSv2_subText{
        font-size: 10px;
    }
}
@media screen and (max-width:768px){
    .footerSv2_downloadText{
      font-size: 16px;
    }
    .footerSv2_subText{
      font-size: 10px;
    }
    .footerSv2_MainText{
        font-size: 14px;
    }
    .footerSv2_googlePlay{
        width:100px;
    }
    .footerSv2_appStore{
        width:100px;
    }
    .inputStyle_sv2{
        width: 120px;
    }
}

/* Mobile class Names */
.footerSv2_MainText_Mobile{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;  
}
.footerSv2_downloadText_Mobile{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

}
.footerSv2_subText_Mobile{
    flex-grow: 0;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
@media screen and (min-width:0px) and (max-width:760px){
    .footerSv2_googlePlay{
        width:120px;
        padding-top:10px;
        padding-right: 10px;
    }
    .footerSv2_appStore{
        width:120px;
        padding-top:10px;
        padding-left: 10px;
    }
}
@media screen and (max-width:375px){
    .footerSv2_subText_Mobile{
        font-size: 10px;
    }
}