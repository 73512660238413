@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.reward_main_div{
    /* width: 753px; */
    height: 129px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    /* margin: 43.5px 138px 20px 28px; */
    padding: 10px;
    opacity: 0.6;
    border-radius: 15px;
    background-color: #8c52ff;
}

.reward_placeholder_div {
    /* width: 830px; */
    /* height: 508px; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    grid-gap: 5rem;
    gap: 10px;
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: center;
    margin: 20px 0 0 0;
    /* margin: 20px 138px 0 28px; */
    padding: 36px;
    border-radius: 15px;
    background-color: #fff;
  }

  .reward_header_text{
    flex-grow: 0;
    margin: 0 0 0 20px;
    font-family: Inter;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .reward_desc_text{
    flex-grow: 0;
    margin: 0 0 0 20px;
    opacity: 0.8;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .reward_text_image_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 26px;
  }

  .reward_text_div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .reward_scratchCard_div_modal {
    width: 200px;
    height: 200px;
    flex-grow: 0;
    margin: 0 0 0 0;
    /* opacity: 0.6; */
    border-radius: 15px;
    border: solid 5px #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .reward_scratchCard_div_used {
    width: 200px;
    height: 200px;
    flex-grow: 0;
    margin: 0 0 20px 0;
    /* opacity: 0.6; */
    /* border: solid 5px #fff; */
    border-radius: 12px;
    border: solid 1px #b8b8b8;
    background-color: #fff;
    padding: 16px 10px 0;
  }

  .reward_scratchCard_div_locked {
    width: 200px;
    height: 200px;
    flex-grow: 0;
    margin: 0 0 20px 0;
    /* opacity: 0.6; */
    /* border: solid 5px #fff; */
    border-radius: 18px;
    border: solid 3px #fff;
    box-shadow: 2px 2px 15px 0 rgba(166, 171, 189, 0.5), -1px -1px 13px 0 #fafbff;
    /* background-color: #fff; */
    padding: 5px;
  }

  .reward_scratchCard_div_unlocked {
    width: 200px;
    height: 200px;
    flex-grow: 0;
    margin: 0 0 20px 0;
    /* opacity: 0.6; */
    /* border: solid 5px #fff; */
    border-radius: 12px;
    border: solid 3px #fff;
    box-shadow: 2px 2px 15px 0 rgba(166, 171, 189, 0.5), -1px -1px 13px 0 #fafbff;
    /* background-color: #fff; */
    padding: 5px;
  }

  .reward_scratchCard_image_used{
    width: auto;
    height: auto;
    opacity: 1;
  }

  .reward_scratchCard_image_locked{
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .reward_scratchCard_image_unlocked{
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .reward_scratchCard_image_reverse{
    width: 0px;
    height: 0px;

  }

  .reward_scratchCard_image_change{
    width: 0px;
    height: 0px;
    transition: ease-in 0.5s;
    -webkit-transform:scaleY(-1);
    -moz-transform:scaleY(-1);
    -ms-transform:scaleY(-1);
    -o-transform:scaleY(-1);
    transform:scaleY(-1);
  }

  .reward_scratchCard_image_change_reverse{
    width: 100%;
    height: 100%;
    transition: ease-in 1.6s;
    -webkit-transform:scaleY(1);
    -moz-transform:scaleY(1);
    -ms-transform:scaleY(1);
    -o-transform:scaleY(1);
    transform:scaleY(1);
  }

  .Rectangle-6713 {
    /* width: 184px;
    height: 67px; */
    /* margin: 86px 20px 46px 0;
    padding: 12px 44px 12px 45px; */
    box-shadow: 0 2px 4px 0 rgba(103, 52, 204, 0.1);
    background-color: #fff;
    position: relative;
    bottom:100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 0 12px 0;
  }


  .Frame-1000005269 {
    width: 79px;
    height: 24px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin: 4px 8px 0;
    padding: 0 21px;
    border-radius: 16px;
    background-color: #8c52ff;
  }

  .reward_referral-pending {
    /* width: 95px;
    height: 15px; */
    margin: 0 0 4px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #747474;
  }

  .reward_remind {
    /* width: 37px;
    height: 24px; */
    flex-grow: 0;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.4;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

.reward_referal_recieved_image_div{
  /* margin: 36px 36px; */
  height: 140px;
  padding: 26px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reward_referal_recieved_div{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reward_referal_recieved_date {
  /* width: 63px;
  height: 12px; */
  flex-grow: 0;
  margin: 4px 32.2px 0 34px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
}

.reward_modal_body{
  background-color: #747474;
  /* background-color: transparent; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 20px;
}

.reward_modal_main_div{
  /* background-color: #fff; */
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.reward_modal_main_div_explode{
  /* background-color: #fff; */
  width: 200px;
  height: 200px;
  border-radius: 10px;
  animation: tilt-shaking 0.10s 1s;
}

.reward_modal_main_div_wrapper{
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rewards_congratulations_text{
  /* width: 167px;
  height: 24px; */
  margin: 20px 0 4px 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Reward-will-be-soon-reflected-in-your-account {
  /* width: 256px;
  height: 15px;
  margin: 4px 48px 0 2px; */
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.reward_tab_me_text{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 50px;
}

.reward_coupon_reveal_amount_div_reverse{
  position: absolute;
  bottom:225px;
  width: auto;
  transition: ease-out 1.6s;
  -webkit-transform:scaleY(1);
  -moz-transform:scaleY(1);
  -ms-transform:scaleY(1);
  -o-transform:scaleY(1);
  transform:scaleY(1);
}

.reward_coupon_reveal_amount_div{
  position: absolute;
  bottom:225px;
  width: 0px;
}

.reward_coupon_reveal_amount_text {
  font-family: Inter;
  font-size: 0px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8c52ff;
}

.reward_coupon_reveal_amount_text_reverse{
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8c52ff;
}

.reward_reveal_amount{
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  position: absolute;
}

.reward_reveal_amount_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom:30px;
}
/* .reward_modal_main_div_explode:hover {
  animation: tilt-shaking 0.20s 1s;
} */