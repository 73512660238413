.Container_Class{
    padding: 30px 129px 25px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    background-color: #ffffff;
}

.Colored_Container_Class{
    padding: 55px 129px 0px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    background-color: rgba(210, 199, 225, 0.51);
}
.Carousel_Container_Class{
    padding: 55px 129px 25px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    background-color: #ffffff;
}

.bgColoredContainer{
    padding: 30px 129px 25px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    background-color: #f5f4ff;
}
.bgColoredContainer1{
    padding: 48px 129px 45px 129px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    background-color: #a5d028;
}
.Container_Class_Navbar{
    padding: 0px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    background-color: #ffffff;
    
}
.helpAndSupport_Container{
    padding: 60px 0px 60px 0px;
    margin: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-items: center;
    background-color:#442b6a;
    display:flex;
    flex-direction:column;
    cursor: pointer;
}
@media screen and (max-width:1600px){
    .bgColoredContainer{
        padding: 30px 129px 50px 129px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #f5f4ff;
    }
    .bgColoredContainer1{
        padding: 30px 129px 45px 129px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #a5d028;
    }

    .Container_Class{
        padding: 30px 129px 50px 129px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
}

@media screen and (max-width:1440px){
    .Carousel_Container_Class{
        padding: 55px 129px 25px 129px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .Container_Class{
        padding: 30px 129px 25px 129px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .bgColoredContainer1{
        padding: 40px 129px 35px 129px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #a5d028;
    }
}
@media screen and (max-width:1240px){
    .Carousel_Container_Class{
        padding: 55px 110px 25px 110px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }

    .Container_Class{
        padding: 30px 110px 25px 110px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }

    .Colored_Container_Class{
        padding: 55px 110px 0px 110px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: rgba(210, 199, 225, 0.51);
    }

    .bgColoredContainer{
        padding: 40px 110px 35px 110px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #f5f4ff;
    }
    .bgColoredContainer1{
        padding: 30px 110px 25px 110px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #a5d028;
    }
    
}
@media screen and (max-width:1024px){
    .Container_Class{
        padding: 30px 90px 25px 90px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .Carousel_Container_Class{
        padding: 55px 90px 25px 90px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .bgColoredContainer{
        padding: 30px 90px 25px 90px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #f5f4ff;
    }
    .bgColoredContainer1{
        padding: 30px 90px 25px 90px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #a5d028;
    }
    .Colored_Container_Class{
        padding: 55px 90px 0px 90px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: rgba(210, 199, 225, 0.51);
    }
}
@media screen and (max-width:768px){
    .Container_Class{
        padding: 16px 50px 16px 50px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .Carousel_Container_Class{
        padding: 55px 50px 25px 50px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .bgColoredContainer{
        padding: 30px 50px 25px 50px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #f5f4ff;
    }
    .bgColoredContainer1{
        padding: 30px 50px 25px 50px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #a5d028;
    }
    .Colored_Container_Class{
        padding: 55px 50px 0px 50px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: rgba(210, 199, 225, 0.51);
    }
}
@media screen and (min-width:0px) and (max-width:760px){
    .Container_Class{
        padding: 16px 15px 16px 15px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .Carousel_Container_Class{
        padding: 16px 15px 16px 15px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #ffffff;
    }
    .bgColoredContainer{
        padding: 16px 15px 16px 15px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #f5f4ff;
    }
    .bgColoredContainer1{
        padding: 16px 15px 16px 15px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: #a5d028;
    }
    .Colored_Container_Class{
        padding: 16px 15px 16px 15px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        justify-items: center;
        background-color: rgba(210, 199, 225, 0.51);
    }
}


